import React from "react";
import { Checkbox } from "antd";

const App: React.FC<{
  data: {
    name: string;
    Name: string;
    bgColor?: string;
    color?: string;
  };
  checked?: boolean;
  onChange: (Name: string, checked: boolean) => void;
}> = ({ data, onChange, checked }) => {
  return (
    <Checkbox
      onChange={(e) =>
        onChange(data?.Name ? data?.Name : data?.name, e.target.checked)
      }
      checked={checked}
    >
      <span
        style={{
          backgroundColor: data.bgColor,
          color: data.color,
          ...(data.bgColor && {
            padding: "5px",
            borderRadius: "12px",
          }),
        }}
        className="text-[#344054] text-[14px] font-medium capitalize"
      >
        {data?.Name ? data?.Name : data?.name ? data?.name : "No user coming"}
      </span>
    </Checkbox>
  );
};

export default App;
