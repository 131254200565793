import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Down from "../../assets/images/down.png";
import Avatar from "../../assets/images/avatar.png";
import Delete from "../../assets/images/deleted.png";
import { Col, Modal, Row } from "antd";
import { Grid } from "@mui/material";
import LabelInput from "../../components/labelInput.tsx";
import TeamSelectDropdown from "./select-dropdown.tsx";
import FilledButton from "../../components/filledButton.tsx";
import axiosClient from "../../utils/axios.ts";
import { useAuthContext } from "../../context/auth-context.tsx";
import moment from "moment";
import { toast } from "react-toastify";

// const data = [
//   {
//     id: 16,
//     name: "Olivia Rhye",
//     partnerType: "Super Partner",
//     country: "United States",
//     commissionModel: "Partners - 20%",
//     superPartner: "Partners - 5%",
//     miniPartners: [
//       {
//         id: 1,
//         name: "Alice Johnson",
//         partnerType: "Mini Partner",
//         country: "United States",
//         commissionModel: "Partners - 10%",
//       },
//       {
//         id: 2,
//         name: "Bob Smith",
//         partnerType: "Mini Partner",
//         country: "United States",
//         commissionModel: "Partners - 12%",
//       },
//     ],
//   },
//   {
//     id: 17,
//     name: "Olivia Rhye",
//     partnerType: "Super Partner",
//     country: "United States",
//     commissionModel: "Partners - 20%",
//     superPartner: "Partners - 5%",
//     miniPartners: [
//       {
//         id: 1,
//         name: "Alice Johnson",
//         partnerType: "Mini Partner",
//         country: "United States",
//         commissionModel: "Partners - 10%",
//       },
//     ],
//   },
// ];

export default function CollapseTable({ tableData }) {
  //   console.log("Super Partner Data: ", tableData);
  const { isAuthenticated } = useAuthContext();
  // export default function CollapseTable() {
  const [mainData, setMainData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [ModalOpen, setModalOpen] = React.useState(false);
  const [selectedItem, setSelectedIteam] = React.useState(null);
  const [allCommissionModels, setAllCommissionModels] = React.useState([]);
  const [partnerModels, setPartnerModels] = React.useState<
    { value: any; label: any }[]
  >([]);
  const [superPartnerModels, setSuperPartnerModels] = React.useState<
    { value: any; label: any }[]
  >([]);
  const [selectedPartnerModel, setSelectedPartnerModel] = React.useState(null);
  const [selectedSuperPartnerModel, setSelectedSuperPartnerModel] =
    React.useState(null);

  const getFormattedSuperPartners = async () => {
    await axiosClient()
      .get("users/super-partners/formatted")
      .then((res) => {
        // console.log(res.data);
        setMainData(res?.data);
      })
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    getFormattedSuperPartners();
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      axiosClient()
        .get("commission-model")
        .then((res) => {
          setAllCommissionModels(res?.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    const partner_models = allCommissionModels
      .filter((model: any) => model?.user_type_id?.type_name === "AFFILIATES")
      .map((item: any) => ({
        value: item?.commission_model_id,
        label: item?.name,
      }));
    const super_partner_models = allCommissionModels
      .filter(
        (model: any) => model?.user_type_id?.type_name === "SUPER_PARTNER"
      )
      .map((item: any) => ({
        value: item?.commission_model_id,
        label: item?.name,
      }));
    setPartnerModels(partner_models);
    setSuperPartnerModels(super_partner_models);
  }, [allCommissionModels]);

  //   console.log({ superPartnerModels, partnerModels });

  const showModal = (item) => {
    // console.log("item: ", item);
    setSelectedIteam(item);
    setSelectedPartnerModel(item?.commission_model_id?.commission_model_id);
    setSelectedSuperPartnerModel(
      item?.super_commission_model_id?.commission_model_id
    );
    setIsModalOpen(true);
  };
  const showRemoveModal = (item) => {
    // console.log("item: ", item);
    setSelectedIteam(item);
    setModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setSelectedIteam(null);
    setSelectedPartnerModel(null);
    setSelectedSuperPartnerModel(null);
    setIsModalOpen(false);
    setModalOpen(false);
  };

  const handlePartnerChange = (model) => {
    console.log("event=>", model);
    setSelectedPartnerModel(model);
  };

  const handleSuperChange = (model) => {
    console.log("event=>", model);
    setSelectedSuperPartnerModel(model);
  };

  const submitHandler = () => {
    const userId = selectedItem?.user_id;
    const data = {
      commission_model_id: selectedPartnerModel,
      super_commission_model_id: selectedSuperPartnerModel,
    };

    // console.log("ID: > ", userId);
    // console.log("Data: >", data);

    axiosClient()
      .patch(`/users/${userId}`, data)
      .then((res) => {
        toast.success("User Updated Successfully");
        getFormattedSuperPartners();
        setSelectedIteam(null);
        setSelectedPartnerModel(null);
        setSelectedSuperPartnerModel(null);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.data.message);
      });
  };

  const removeHandler = () => {
    const userId = selectedItem?.user_id;
    // toast.error(`Removed ${selectedItem?.name} !`)
    axiosClient()
      .patch(`/users/${userId}`, {
        is_active: false,
      })
      .then((res) => {
        getFormattedSuperPartners();
        toast.success(`${selectedItem?.name} removed successfully!`);
        setSelectedIteam(null);
        setModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };

  //   const handleChange = (newValue) => {
  //     setSelectedTeam(newValue);
  //   };

  //   const options = [
  //     { value: "20% Partnership", label: "20% Partnership" },
  //     { value: "20% Partnership", label: "20% Partnership" },
  //     { value: "20% Partnership", label: "20% Partnership" },
  //   ];

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          className="bigTable"
        >
          <TableCell className="bg_1">
            <Checkbox />
          </TableCell>
          {/* <TableCell scope="row" className="bg_2">
            {row.id}
          </TableCell>
          <TableCell className="bg_3">
            <div className="profileSection">
              {" "}
              <span className="blueBg">OR</span> {row.name}
            </div>
          </TableCell>
          <TableCell className="bg_4">
            <span className="blue_Bg"> {row.partnerType} </span>
          </TableCell>
          <TableCell className="bg_5">{row.country}</TableCell>
          <TableCell className="bg_6">{row.commissionModel}</TableCell>
          <TableCell className="bg_7">{row.superPartner}</TableCell> */}
          <TableCell scope="row" className="bg_2">
            {row?.user_id}
          </TableCell>
          <TableCell className="bg_3">
            <div className="profileSection">
              <img
                src={
                  row?.profile_image
                    ? row?.profile_image
                    : "/images/profile.svg"
                }
                alt=""
                className="object-cover w-full h-full"
              />{" "}
              {row?.name}
            </div>
          </TableCell>
          <TableCell className="bg_4">
            <span className="blue_Bg">
              {" "}
              {row?.role === "SUPER_PARTNER"
                ? "Super Partner"
                : "Mini Partner"}{" "}
            </span>
          </TableCell>
          <TableCell className="bg_5">{row?.location}</TableCell>
          <TableCell className="bg_6">
            {row?.commission_model_id?.name}
          </TableCell>
          <TableCell className="bg_7">
            {row?.super_commission_model_id?.name}
          </TableCell>
          <TableCell className="bg_9">

          </TableCell>
          <TableCell className="bg_8" style={{
            textAlign: "end",
            paddingRight: "32px"
          }}>
            <span onClick={() => showModal(row)} style={{ cursor: "pointer" }}>
              View
            </span>
            <span
              onClick={() => showRemoveModal(row)}
              style={{ cursor: "pointer" }}
            >
              Remove
            </span>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <img src={Down} alt="" />
              ) : (
                <img src={Down} alt="" className="rotate" />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              borderBottom:"0 "
            }}
            colSpan={9}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table className="innerTable" aria-label="details">
                  <TableBody>
                    {row?.mini_partners?.map((miniPartner) => (
                      <TableRow key={miniPartner.id}>
                        <TableCell className="sm_1"></TableCell>
                        <TableCell className="sm_2">
                          {miniPartner?.user_id}
                        </TableCell>
                        <TableCell className="sm_3">
                          {/* <div className="profileSection">
                            {" "}
                            <span className="yellowBg">OR</span>{" "}
                            {miniPartner?.name}
                          </div> */}
                          <div className="profileSection">
                            <img
                              src={
                                miniPartner?.profile_image
                                  ? miniPartner?.profile_image
                                  : "/images/profile.svg"
                              }
                              alt=""
                              className="object-cover w-full h-full"
                            />{" "}
                            {miniPartner?.name}
                          </div>
                        </TableCell>
                        <TableCell className="sm_4">
                          <span className="green_Bg">
                            {/* {miniPartner?.role} */}
                            Mini Partner
                          </span>
                        </TableCell>
                        <TableCell className="sm_5">
                          {miniPartner?.location}
                        </TableCell>
                        <TableCell className="sm_6">
                          {miniPartner?.commission_model_id?.name}
                        </TableCell>
                        <TableCell className="sm_7"></TableCell>
                        <TableCell className="sm_8"></TableCell>
                        <TableCell className="sm_9"></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <TableContainer component={Paper} className="tableCollapse">
        <Table aria-label="collapsible table">
          <TableHead className="theadTable">
            <TableRow>
              <TableCell>
                <Checkbox />
              </TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Commission Model</TableCell>
              <TableCell>Super Partner %</TableCell>
              <TableCell></TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className="bigTableBody">
            {mainData.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>

        <div className="w-full bg-white  items-center px-4 flex justify-between gap-4" style={{ height: "64px" }}>
          <span className="text-[#344054] text-[14px] font-medium">
            Page 1 of 1
          </span>

          <div className="flex items-center gap-3 paginationBtn">
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            >
              Previous
            </button>
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            >
              Next
            </button>
          </div>
        </div>
      </TableContainer>

      <Modal
        className="modalViewPopup"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col className="headerView">
          <img
            src={
              selectedItem?.profile_image
                ? selectedItem?.profile_image
                : "/images/profile.svg"
            }
            alt=""
          />
          <Col>
            <h3>{selectedItem?.name}</h3>
            <h6>Id: {selectedItem?.user_id}</h6>
          </Col>
          <span className="bg_blue">Super Partner</span>
        </Col>
        <Grid container className="gridBox">
          <Grid xs={5.9}>
            <Col>
              <LabelInput title="Email" value={selectedItem?.email} disabled />
            </Col>
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Country"
                value={selectedItem?.location}
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Phone Number"
                value={selectedItem?.phone}
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Created At"
                value={
                  selectedItem?.created_at
                    ? moment(selectedItem?.created_at).format("MMMM Do YYYY")
                    : "NA"
                }
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={5.9}>
            <TeamSelectDropdown
              options={partnerModels}
              title="Commission Model"
              //   value={selectedItem?.commission_model_id?.name}
              value={partnerModels.find(
                (model) =>
                  model.value ===
                  (selectedPartnerModel
                    ? selectedPartnerModel
                    : selectedItem?.commission_model_id?.commission_model_id)
              )}
              onChange={handlePartnerChange}
            />
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <TeamSelectDropdown
              options={superPartnerModels}
              title="Super Partner % "
              //   value={selectedItem?.super_commission_model_id?.name}
              value={superPartnerModels?.find(
                (model) =>
                  model?.value ===
                  (selectedSuperPartnerModel
                    ? selectedSuperPartnerModel
                    : selectedItem?.super_commission_model_id
                      ?.commission_model_id)
              )}
              onChange={handleSuperChange}
            />
          </Grid>
          <Grid xs={12}>
            <Col>
              <LabelInput
                title="Last Login"
                value={
                  selectedItem?.token_created_at
                    ? moment(selectedItem?.token_created_at).format(
                      "MMMM Do YYYY"
                    )
                    : "NA"
                }
                disabled
              />
            </Col>
          </Grid>
          <Grid xs={12}>
            <Col>
              {/* Total commission Paid remianing */}
              <LabelInput title="Net Commission Paid" placeholder="" />
            </Col>
          </Grid>
        </Grid>
        <Col className="modalBtn">
          <FilledButton onClick={handleCancel}>Cancel</FilledButton>
          <FilledButton style={{ background: "#155EEF", color: "#fff" }} onClick={submitHandler}>Save Changes</FilledButton>
        </Col>
      </Modal>

      {/* remove modal start */}
      <Modal
        className="modalViewPopup newRemove"
        open={ModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col className="headerView">
          <img src={Delete} alt="" />
          <Col>
            <h5>Remove {selectedItem?.name}?</h5>
            <p className="pText">
              Are you sure you want to remove them? This action cannot be
              undone.
            </p>
          </Col>
        </Col>

        <Col className="removeBtn">
          <FilledButton onClick={handleCancel}>Cancel</FilledButton>
          <FilledButton onClick={removeHandler}>Remove</FilledButton>
        </Col>
      </Modal>
    </>
  );
}
