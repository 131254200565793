import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const BarChart: React.FC<any> = ({data}) => {
  // console.log("Data in graph: ",data);

  // const series = data ? data : [
  //   {
  //     name: 'Super Partner',
  //     data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  //   },
  //   {
  //     name: 'Mini Partner',
  //     data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  //   },
  // ];

   // Format the data to have 3 significant digits
   const series = data ? data.map((partner: any) => ({
    ...partner,
    data: partner.data.map((value: number) => +value.toPrecision(3))
  })) : [
    {
      name: 'Super Partner',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: 'Mini Partner',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];


  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 380,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 10, // Apply radius only to the top of the bars
        borderRadiusApplication: 'end', // Apply radius only to the end of the bars (top for vertical bars)
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: '400',
          colors: ['#475467']
        }
      },
    },
    grid: {
      borderColor: '#F2F4F7', // Set grid line color
      strokeDashArray: 0,  // Set to 0 for solid lines
      opacity: 0.5,        // Reduce opacity for the grid lines
    },
    yaxis: {
      title: {
        // text: 'Value',
      },
      tickAmount: 8,
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
          fontWeight:"400",
          colors: ['#344054'] // You can customize the color of the labels here
        }
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands"
        }
      }
    },
    colors: ['#7fc5e3', '#bbe49e'],
    legend: {
      position: 'top', // Position legend at the top
      horizontalAlign: 'center', // Align legend horizontally at the center
    },
  };

  return (
    <div id="chart" style={{width:"100%"}}>
      <Chart
      className="lineChart"
        options={options}
        series={series}
        type="bar"
        height={400}
      />
      <p className='month'>Month</p>
    </div>
  );
};

export default BarChart;
