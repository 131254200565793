import React from "react";
import Header from "../../layout/header/index.tsx";
import Main from "../../views/team-management/main.tsx";
const TeamManagement = () => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

export default TeamManagement;
