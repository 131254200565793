import { Button, ButtonProps } from 'antd';
import React from 'react';

interface FilledButtonProps extends ButtonProps {
  className?: string;
  children: React.ReactNode;
}

const FilledButton: React.FC<FilledButtonProps> = ({ className, children, ...rest }) => {
  return (
    <Button className={className} {...rest}>
      {children}
    </Button>
  );
}

export default FilledButton;
