// @ts-nocheck
"use client";
import React, { useState } from "react";
import { Table, Button, Typography, Spin, Col, Row, Modal } from "antd";
import type { TableProps } from "antd";
import moment from "moment";
import Avatar from "../../../../assets/images/user.png";
import Profile from "../../../../assets/images/avatar.png";
import Close from "../../../../assets/images/closeds.png";
import LabelInput from "../../../../components/labelInput.tsx";
import axiosClient from "../../../../utils/axios.ts";
import { toast } from "react-toastify";

interface DataType {
  key: React.Key;
  id: number;
  name: string;
  userType: string;
  revenueEvent: string;
  date: string;
  revenueAmount: string;
  company: string;
  view: any;
}

type TableRowSelection<T> = TableProps<T>["rowSelection"];

const TableApp: React.FC = ({
  setIsViewOpen,
  setSelectedObject,
  isShowRow,
  refresh,
  data,
  updateFilters,
}: any) => {
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const ButtonComponent = (item) => {
    return (
      <div className="flex items-center gap-4 justify-center ">
        <button
          onClick={() => {
            setModalContent(item);
            setIsModalVisible(true);
            // console.log("Data in Pop-up: ", item);
          }}
          className="font-semibold text-[#475467] hover:text-[#004EEB]"
        >
          View
        </button>
      </div>
    );
  };

  //To display Employee's tag "AE" or "SDR"
  // const userTypeComponent = (type) => {
  //   return (
  //     <div>
  //       {type === "affiliate" ? "Partner" : "Employee"}
  //       {type === "owner" || type === "sdr_owner" ? (
  //         <span className="employeeTypeBadge">
  //           {type === "owner" ? "AE" : "SDR"}
  //         </span>
  //       ) : (
  //         ""
  //       )}
  //     </div>
  //   );
  // };

  const getUserType = (role: string) => {
    if (role === "employee" || role === "owner" || role === "sdr_owner") {
      return "Employee";
    } else if (role === "affiliate") {
      return "Partner";
    } else if (role === "super") {
      return "Super Partner";
    }
  };

  const NameComponent = (item) => {
    return (
      // <div className="userText">
      <div className="flex items-center gap-3">
        <div className="w-[36px] h-[36px] rounded-[50px] overflow-hidden bg-gray-100">
          <img
            className="object-cover w-full h-full"
            src={item?.item?.profile_image || Avatar}
            alt=""
          />
        </div>
        <Text className="text-[#101828] text-[14px] font-medium">
          {item?.item?.name}
        </Text>
      </div>
    );
  };

  const capitalize = (str) =>
    str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  const formatTableData = (data) => {
    return data.map((item, index) => ({
      // key: item.id,
      id: item.user_id,
      name: <NameComponent item={item} />,
      // userType: item.user_type ? item.user_type==="affiliate" ? "Partner" : "Employee" : "",
      // userType: item.user_type ? userTypeComponent(item.user_type) : "",
      userType: item.user_role
        ? item.user_role === "EMPLOYEE"
          ? "Employee"
          : item.user_role === "AFFILIATES"
          ? "Partner"
          : item.user_role === "MINI_PARTNER"
          ? "Mini Partner"
          : item.user_role === "SUPER_PARTNER"
          ? "Super Partner"
          : ""
        : "",
      // ? item.user_role.charAt(0) + item.user_role.slice(1).toLowerCase() : "", //To capitalize, need to change for roles with 2 words.
      revenueEvent: item.revenue_event ? capitalize(item.revenue_event) : "",
      date: item.date ? moment(item.date).format("MMMM Do YYYY") : "",
      revenueAmount: item.revenue_amount
        ? `$${(+item.revenue_amount).toFixed(2)}`
        : "",
      reviewDate: item.review_date
        ? moment(item.review_date).format("MMMM Do YYYY")
        : "",
      company: item.company,
      view: <ButtonComponent item={item} />,
      key: index, //to make a unique key for selection
    }));
  };

  const formattedData = formatTableData(data);

  const totalItems = formattedData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = formattedData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 === 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 !== 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleApprove = () => {
    setIsLoading(true);

    // Simulating API call
    setTimeout(() => {
      setIsLoading(false);
      setSelectedRowKeys([]);
    }, 1000);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Revenue Event",
      dataIndex: "revenueEvent",
      key: "revenueEvent",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Revenue Amount",
      dataIndex: "revenueAmount",
      key: "revenueAmount",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "",
      dataIndex: "view",
      key: "view",
    },
  ];

  const updateTransaction = (
    ID: number,
    body: { field: string; value: boolean }
  ) => {
    axiosClient()
      .patch(`transactions/${ID}`, body)
      .then((res) => {
        // toast.success(res?.data.message);
        toast.success("Transaction Updated Successfully!");
        refresh();
        // updateFilters({});
      })
      .catch((error) => {
        console.error(error.message);
        toast.error(error.message);
      })
      .finally(() => setIsModalVisible(false));
  };

  const updateSuperTransaction = (body) => {
    return axiosClient()
      .patch(`transactions/super-partner`, body)
      .then((res) => {
        toast.success("Transaction Updated Successfully!");
        refresh();
        updateFilters({});
        return res.data;
      })
      .catch((error) => {
        console.error(error.message);
        toast.error(error.message);
        throw error;
      })
      .finally(() => setIsModalVisible(false));
  };

  const handleUnarchive = (item) => {
    // console.log("Item: ", item);
    let body = { field: "", value: false };
    if (item.user_type === "sdr_owner") {
      body.field = "is_archived_for_sdr_owner";
      updateTransaction(item.transaction_id, body);
    } else if (item.user_type === "owner") {
      body.field = "is_archived_for_owner";
      updateTransaction(item.transaction_id, body);
    } else if (item.user_type === "affiliate") {
      body.field = "is_archived";
      updateTransaction(item.transaction_id, body);
    } else if (item.user_type === "super") {
      body.field = "is_archived";
      body.transactionId = item?.transaction_id;
      body.superPartnerId = item?.user_id;

      updateSuperTransaction(body);
    }
  };

  const handleDelete = (item) => {
    let body = { field: "", value: true };
    if (item.user_type === "sdr_owner") {
      body.field = "is_deleted_for_sdr_owner";
      updateTransaction(item.transaction_id, body);
    } else if (item.user_type === "owner") {
      body.field = "is_deleted_for_owner";
      updateTransaction(item.transaction_id, body);
    } else if (item.user_type === "affiliate") {
      body.field = "is_deleted";
      updateTransaction(item.transaction_id, body);
    } else if (item.user_type === "super") {
      body.field = "is_deleted";
      body.transactionId = item?.transaction_id;
      body.superPartnerId = item?.user_id;

      updateSuperTransaction(body);
    }
  };

  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
      }}
      className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden"
    >
      {/* {selectedRowKeys?.length > 0 && (
        <div className="w-full bg-white h-20 items-center flex justify-end gap-4 px-4">
          <Text className="text-[#475467] text-[16px] font-semibold">
            {selectedRowKeys?.length} items selected
          </Text>
          <Button
            onClick={handleApprove}
            type="primary"
            disabled={isLoading}
            className="h-[44px] flex items-center bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
          >
            {isLoading ? <Spin className="text-white" /> : "Mark as Paid"}
          </Button>
        </div>
      )} */}
      <Table
        rowSelection={isShowRow ? false : rowSelection}
        columns={columns}
        dataSource={paginatedData}
         className="reviewTable new"
        style={{
          borderTop: "1px solid #EAECF0",
        }}
        pagination={false}
      />
      <div className="w-full bg-white items-center  px-4  flex justify-between gap-4" style={{height:"64px"}}>
        <Text className="text-[#344054] text-[14px] font-medium ">
          Page {currentPage} of {totalPages}
        </Text>

        <div className="flex items-center gap-3 paginationBtn">
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
      <Modal
        className="viewPopup modals"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="headerDesktop" style={{position:"relative"}}>
          <img
            src={modalContent?.item?.profile_image || Avatar}
            className="profilePic"
            alt=""
          />
       
          <div>
            <h3>{modalContent?.item?.name}</h3>
            <p>
              {/* {modalContent?.item?.user_type
                ? userTypeComponent(modalContent.item.user_type)
                : ""} */}
              {
                modalContent?.item?.user_role
                  ? modalContent.item.user_role.charAt(0) +
                    modalContent.item.user_role.slice(1).toLowerCase()
                  : "" //To capitalize, need to change for roles with 2 words.
              }
            </p>
          </div>
          <img src={Close} alt="" onClick={() => setIsModalVisible(false)} style={{ position: "absolute",
    right: "28px",
    cursor: "pointer"}} />
        </div>
        <div className="bodyModal">
          <Row
            style={{
              position: "relative",
              left: "5px",
            }}
          >
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Revenue Event"
                  placeholder=""
                  value={
                    modalContent?.item?.revenue_event
                      ? capitalize(modalContent?.item?.revenue_event)
                      : ""
                  }
                  readonly
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Revenue amount"
                  placeholder=""
                  value={
                    modalContent?.item?.revenue_amount
                      ? `$${(+modalContent?.item?.revenue_amount).toFixed(2)}`
                      : ""
                  }
                  readonly
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Reviewed Date"
                  placeholder=""
                  value={
                    modalContent?.item?.review_date
                      ? moment(modalContent?.item?.review_date).format(
                          "MMMM Do YYYY"
                        )
                      : ""
                  }
                />
              </Col>
            </Col>
            <Col md={12}>
              <Col>
                <LabelInput
                  title="Company Name"
                  placeholder=""
                  value={modalContent?.item?.company}
                  readonly
                />
              </Col>
            </Col>
            <Col md={24}>
              <Col>
                <LabelInput
                  title="Revenue Generation Date"
                  placeholder=""
                  value={
                    modalContent?.item?.date
                      ? moment(modalContent?.item?.date).format("MMMM Do YYYY")
                      : ""
                  }
                  style={{ width: "98%" }}
                />
              </Col>
            </Col>
          </Row>
        </div>
        <div className="footerModal">
          <Button onClick={() => handleDelete(modalContent?.item)}>
            Delete
          </Button>
          <Button onClick={() => handleUnarchive(modalContent?.item)} style={{background:"#000", color:"#fff"}}>
            Unarchive
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableApp;
