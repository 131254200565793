// @ts-nocheck

import React from "react";
import Main from "../../views/dashboard/main.tsx";
import Header from "../../layout/header/index.tsx";
const Dashboard = () => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

export default Dashboard;
