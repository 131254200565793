// @ts-nocheck
import React, { useState } from "react";
import { Drawer, Typography, Input, Button, Spin } from "antd";
import { Slider } from "../../components/index.ts";
import {
  FILTER_NAME,
  FILTER_TYPE,
  FILTER_STATUS1,
} from "../../constants/data.ts";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";
import Checkbox from "./checkbox.tsx";
const SidebarFilter = ({
  isViewOpen,
  setIsViewOpen,
  setPayoutData,
  sidebarData,
  setSelectedFFilters,
  setCheckboxValues,
  checkboxValues,
  setIsCheck,
  setPayouts,
  payouts,
  refresh,
  handleApply,
}) => {
  const { Text } = Typography;

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const [itemsToShow, setItemsToShow] = useState(10);
  const [searchName, setSearchName] = useState([...FILTER_NAME]);
  const [searchValue, setSearchValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState([0, 10000]);

  // const handleCheckboxChange = (name, value) => {
  //   setCheckboxValues((prevValues) => {
  //     const currentValues = prevValues[name] || [];

  //     if (currentValues.includes(value)) {
  //       // Remove value if it is already present
  //       return {
  //         ...prevValues,
  //         [name]: currentValues.filter((v) => v !== value),
  //       };
  //     } else {
  //       // Add value if it is not present
  //       return {
  //         ...prevValues,
  //         [name]: [...currentValues, value],
  //       };
  //     }
  //   });

  //   setSelectedFFilters((prevValues) => {
  //     const currentValues = prevValues[name] || [];
  //     if (currentValues.includes(value)) {
  //       // Remove value if it is already present
  //       return {
  //         ...prevValues,
  //         [name]: currentValues.filter((v) => v !== value),
  //       };
  //     } else {
  //       // Add value if it is not present
  //       return {
  //         ...prevValues,
  //         [name]: [...currentValues, value],
  //       };
  //     }
  //   });
  // };
  useState(() => {
    setSliderValue([0,10000]);
  },[payouts])

  const handleCheckboxChange = (name, value) => {
    if (name === "Commission") {
      setSliderValue(value);
    }
    setCheckboxValues((prevValues) => {
      if (name === "Commission") {
        // For Commission, we always want to replace the existing value
        return {
          ...prevValues,
          [name]: value,
        };
      } else {
        const currentValues = prevValues[name] || [];

        if (currentValues.includes(value)) {
          // Remove value if it is already present
          return {
            ...prevValues,
            [name]: currentValues.filter((v) => v !== value),
          };
        } else {
          // Add value if it is not present
          return {
            ...prevValues,
            [name]: [...currentValues, value],
          };
        }
      }
    });

    setSelectedFFilters((prevValues) => {
      if (name === "Commission") {
        // For Commission, we always want to replace the existing value
        return {
          ...prevValues,
          [name]: value,
        };
      } else {
        const currentValues = prevValues[name] || [];
        if (currentValues.includes(value)) {
          // Remove value if it is already present
          return {
            ...prevValues,
            [name]: currentValues.filter((v) => v !== value),
          };
        } else {
          // Add value if it is not present
          return {
            ...prevValues,
            [name]: [...currentValues, value],
          };
        }
      }
    });
  };

  const handleShowMore = () => {
    setItemsToShow((prevItemsToShow) => prevItemsToShow + 10);
  };

  const handleShowLess = () => {
    setItemsToShow((prevItemsToShow) => Math.max(10, prevItemsToShow - 10));
  };

  // const handleApply = () => {
  //   setIsLoading(true);

  //   const status = checkboxValues.Status || [];
  //   const statusJson = JSON.stringify(status);
  //   const encodedStatuses = encodeURIComponent(statusJson);

  //   const role = checkboxValues.Role?.toString()?.toUpperCase() || [];
  //   const roleJson = JSON.stringify(role);
  //   const encodedRoles = encodeURIComponent(roleJson);

  //   const commission = checkboxValues.Commission || [];

  //   const minCommission = checkboxValues.Commission
  //     ? checkboxValues.Commission[0]
  //     : "";
  //   const minCommissionJson = JSON.stringify(minCommission);
  //   const encodedMinCommission = encodeURIComponent(minCommissionJson);

  //   const maxCommission = checkboxValues.Commission
  //     ? checkboxValues.Commission[1]
  //     : "";
  //   const maxCommissionJson = JSON.stringify(maxCommission);
  //   const encodedMaxCommission = encodeURIComponent(maxCommissionJson);

  //   const queryParams = [];
  //   // Add parameters to the array only if they are not empty
  //   if (status.length) queryParams.push(`payment_status=${encodedStatuses}`);
  //   if (role.length) queryParams.push(`Role=${encodedRoles}`);
  //   if (commission.length)
  //     queryParams.push(`MinCommission=${encodedMinCommission}`);
  //   if (commission.length)
  //     queryParams.push(`MaxCommission=${encodedMaxCommission}`);
  //   const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
  //   axiosClient()
  //     .get(`users-commissions/formatted-data/${queryString}`)
  //     .then((res) => {
  //       // setPayoutData(res?.data);
  //       setPayouts(res?.data);
  //       setIsViewOpen(false);
  //       setIsLoading(false);
  //       setIsCheck(true);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       toast.error("Something went wrong!");
  //       setIsLoading(false);
  //       setIsCheck(false);
  //     });
  // };

  const HandleNameSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = FILTER_NAME;
    } else {
      filteredData = searchName?.filter((x) => {
        return (
          x?.label?.toLowerCase().charAt(0) === value?.toLowerCase().charAt(0)
        );
      });
    }
    setSearchName(filteredData);
  };

  const handleSearch = (value) => {
    let updatedData; // Initialize with the original data

    setSearchValue(value);
    if (!value) {
      setPayoutData(sidebarData);
    } else {
      updatedData = sidebarData?.filter(
        (sidebarData) =>
          sidebarData?.Name?.toLowerCase().charAt(0) ===
          value?.toLowerCase().charAt(0)
      );

      setPayoutData(updatedData);
    }
  };

  const getUniqueSDROwners = () => {
    const uniqueSDROwnerMap = {};

    sidebarData?.forEach((item) => {
      if (item.pipedrive_org_id) {
        uniqueSDROwnerMap[item.pipedrive_org_id] = item;
      }
    });

    return Object.values(uniqueSDROwnerMap);
  };

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setIsViewOpen(false)}
        visible={isViewOpen}
        bodyStyle={{ padding: "20px", marginBottom: "80px" }}
        className="custom-drawer"
      >
        <div className="w-full h-full relative ">
          <div className="flex justify-between items-center">
            <Text className="text-[#101828] text-[20px] font-semibold">
              Filters
            </Text>

            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
              alt=""
              className="w-[15px] cursor-pointer"
              onClick={() => setIsViewOpen(false)}
            />
          </div>
          <Text className="text-[#475467] text-[14px] font-normal">
            Apply filters to your payouts
          </Text>

          {/* Company Filter */}
          {/* <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                Company
              </Text>
            </div>

            <div className="w-full">
              <Input
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                prefix={<SearchIcon />}
                className="w-full h-[44px] mt-3 placeholder:text-black rounded-[8px]"
              />
            </div>
            <div className="grid gap-y-3 mt-4 mb-2 pl-2">
              {getUniqueSDROwners()
                ?.slice(0, itemsToShow)
                ?.map((item, i) => (
                  <Checkbox
                    key={i}
                    data={item?.pipedrive_org_id}
                    onChange={(value) =>
                      handleCheckboxChange("CompanyName", value)
                    }
                    checked={checkboxValues?.CompanyName?.includes(
                      item?.pipedrive_org_id?.Name
                    )}
                  />
                ))}
              <div>
                {itemsToShow < sidebarData?.length && (
                  <button
                    onClick={handleShowMore}
                    className="text-[#004EEB] font-semibold"
                  >
                    Show More
                  </button>
                )}
                {itemsToShow > 10 && (
                  <button
                    onClick={handleShowLess}
                    className="text-[#004EEB] font-semibold"
                  >
                    Show Less
                  </button>
                )}
              </div>
            </div>
          </div> */}

          {/* Name Filter */}

          {/* <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                Name
              </Text>
            </div>

            <div className="w-full">
              <Input
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                placeholder="Search"
                onChange={(e) => HandleNameSearch(e.target.value)}
                prefix={<SearchIcon />}
                className="w-full h-[44px] placeholder:text-black rounded-[8px] mt-3"
              />
            </div>
            <div className="grid gap-y-3 mt-4 pl-2">
              {sidebarData?.map((item, i) => (
                <Checkbox
                  key={i}
                  data={item?.user_id}
                  onChange={(value) => handleCheckboxChange(`UserName`, value)}
                  checked={checkboxValues?.UserName?.includes(
                    item?.user_id?.name
                  )}
                />
              ))}
            </div>
          </div> */}

          {/* Status Filter */}
          <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                Status
              </Text>
            </div>

            <div className="grid gap-y-3 mt-4 pl-2">
              {FILTER_STATUS1?.map((item, i) => (
                <Checkbox
                  key={i}
                  data={item}
                  // style={{color: item.color, backgroundColor: item.bgColor}}
                  onChange={(value) => handleCheckboxChange(`Status`, value)}
                  checked={checkboxValues?.Status?.includes(item?.Name)}
                />
              ))}
            </div>
          </div>

          {/* Type Filter */}
          <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                User Type
              </Text>
            </div>

            <div className="grid gap-y-3 mt-4 pl-2">
              {FILTER_TYPE?.map((item, i) => (
                <Checkbox
                  key={i}
                  data={item}
                  onChange={(value) => handleCheckboxChange(`Role`, value)}
                  checked={checkboxValues?.Role?.includes(item?.Name)}
                />
              ))}
            </div>
          </div>

          {/* Payout Amount Slider */}

          <div className="pb-6">
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px]  font-medium">
                Payout Amount
              </Text>
            </div>
            <div>
              <Slider
                value={sliderValue}
                onChange={(value) => handleCheckboxChange(`Commission`, value)}
              />
            </div>
          </div>

          {/* Apply and Cancel Buttons */}

          <div className="flex justify-end items-cente gap-4 py-4 border-t border-[#EAECF0] fixed w-[362px] z-[1200] right-4 bottom-0 mt-7">
            <div className="px-4 flex gap-4">
              <Button
                onClick={() => {
                  setIsViewOpen(false);
                  setCheckboxValues({});
                  setSelectedFFilters({});
                  setSliderValue([0, 10000]); // Reset slider to default values
                  refresh();
                }}
                
                icon=""
                className="w-[100px] h-[44px] bg-[#fff] border border-[#D0D5DD] rounded-[8px] font-semibold text-[14px] text-[#344054]"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon=""
             
                disabled={isLoading}
                onClick={handleApply}
                className="w-[90px] h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {isLoading ? <Spin /> : "Apply"}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarFilter;
