import axiosClient from "../utils/axios.ts";

class Activity {
  async getAllActivities() {
    try {
      const activity = await axiosClient().get(
        "pipedrive-organisations/recent-activities"
      );

      return activity.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const activityApi = new Activity();
