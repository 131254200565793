import { useState } from "react";
import { payoutApi } from "../services/payouts.ts";
import { useDispatch } from "react-redux";
import { addOpenPayouts } from "../redux/payouts.ts";
import { setReduxLoading } from "../redux/loading.ts";

const useOpenPayouts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const getPayouts = async () => {
    dispatch(setReduxLoading(true));
    try {
      const payouts = await payoutApi.getAllPayouts();
      dispatch(addOpenPayouts(payouts));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getPayouts,
  };
};

export default useOpenPayouts;
