import { Table, Typography } from 'antd'
import React, { useState } from 'react'
import moment from 'moment';
import "moment-timezone";


export default function PayoutTable(data) {
    const { Text } = Typography;
    const dummyColumns = [
        { title: 'Date', dataIndex: 'date', key: 'date' },
        { title: 'Company', dataIndex: 'company', key: 'company' },
        { title: 'Revenue Event', dataIndex: 'event', key: 'event' },
        { title: 'Revenue Amount', dataIndex: 'amount', key: 'amount' },
        { title: 'Commission Percent', dataIndex: 'percent', key: 'percent' },
        { title: 'Commission amount', dataIndex: 'c_amount', key: 'c_amount' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
    ];
    
        const allTransactions = data.data.transactions;
        // console.log("All Data on view page: ",data.data);
        // console.log("All Transactions: ",allTransactions);

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8;

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };

      
      const StatusBtn = ({ item }) => {
          return (
              <Text
              style={
              item === "Unpaid"
                ? { backgroundColor: "#FFFAEB", color: "#B54708" }
                : item === "Paid" ? { backgroundColor: "#ECFDF3", color: "#027A48" } : ""
            }
            className="text-[14px] font-normal w-auto rounded-[16px] p-1 px-3 capitalize"
            >
            {item}
          </Text>
        );
    };
    
    const mapDataToTableFormat = ([]) => {
        return allTransactions.map((item,index)=>({
            key: index,
            date: moment(item?.date).utc().format("DD/MM/YYYY"),
            company: item.company,
            event: item.revenueEvent,
            // amount: `$${item.revenueAmount.toFixed(2)}`,
            amount: `$${item.revenueAmount}`,
            percent: `${item.commissionPercent}%`,
            // c_amount: `$${item.commissionAmount.toFixed(2)}`
            c_amount: `$${item.commissionAmount}`,
            status: <StatusBtn item={item?.payment_status} />
        }))
    };
    
    const formattedData = mapDataToTableFormat(allTransactions);
    
    const totalItems = formattedData?.length;
    const totalPages = Math.ceil(totalItems / pageSize);

    const paginatedData = formattedData?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    )

    return (
        <>
        <div className='paymentHead'>
                <div>
                    <h3>{data.data && data.data.affiliateName}</h3>
                    <h4>{moment(data.data && data.data.month).format("MMMM YYYY")}</h4>
                </div>
             
            </div>
        <div className='tablePayment'>
            
            <Table dataSource={paginatedData} columns={dummyColumns} pagination={false} />
            <div className="w-full bg-white h-20 items-center  px-4  flex justify-between gap-4  payoutFooter" style={{height:"64px",borderRadius:"14px"}}>
                <h3 className="text-[#344054] text-[14px] font-medium ">
                    Page {currentPage} of {totalPages}
                </h3>

                <div className="flex items-center gap-3" >
                    <button
                        style={{
                            boxShadow: "0px 1px 2px 0px #1018280D",
                        }}
                        className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        Previous
                    </button>
                    <button
                        style={{
                            boxShadow: "0px 1px 2px 0px #1018280D",
                        }}
                        className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}
