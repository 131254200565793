// import React, { useEffect } from 'react';
// import { Select, Space, Image, Tag } from 'antd';
// import Avatar from "../../assets/images/avatar.png";
// import Check from "../../assets/images/check.png";
// import Fill from "../../assets/images/fill.png";
// import { useSelector } from 'react-redux';
// import { RootState } from '../../redux/store';

// const options = [
//   {
//     label: 'Phoenix Baker',
//     value: 'phoenix',
//     image: Avatar,
//   },
//   {
//     label: 'Olivia Rhye',
//     value: 'olivia',
//     image: Avatar,
//   },
//   {
//     label: 'Lana Steiner',
//     value: 'lana',
//     image: Avatar,
//   },
//   {
//     label: 'Demi Wilkinson',
//     value: 'demi',
//     image: Avatar,
//   },
//   {
//     label: 'Candice Wu',
//     value: 'candice',
//     image: Avatar,
//   },
// ];

// const PartnerDropdown: React.FC<any> = () => {
//   const [selectedItems, setSelectedItems] = React.useState<string[]>(['phoenix', 'olivia']);
//   const superPartners = useSelector((state: RootState) => state.teams.superPartners);
//   console.log("Super Partners in Dropdown: ", superPartners);

//   let superPartnerOptions = [];

//   function formatUserData(inputData) {
//     console.log("#1",inputData?.length);
//     return inputData.map(user => ({
//       label: user.name,
//       value: user.user_id,
//       image: user.profile_image
//     }));
//   }
  
//   useEffect(() => {
//     superPartnerOptions = formatUserData(superPartners);
//   },[superPartners]);

//   console.log("Options: ",superPartnerOptions);
//   const handleChange = (value: string[]) => {
//     setSelectedItems(value);
//     console.log(`selected ${value}`);
//   };

//   const handleOptionClick = (value: string) => {
//     const isSelected = selectedItems.includes(value);
//     const newSelectedItems = isSelected
//       ? selectedItems.filter(item => item !== value)
//       : [...selectedItems, value];

//     setSelectedItems(newSelectedItems);
//   };

//   return (
//     <>
//       <label>Super Partners</label>
//       <Select
//         className='multiSelect'
//         mode="multiple"
//         style={{ width: '100%' }}
//         placeholder="Search names..."
//         value={selectedItems}

//         onChange={handleChange}
//         optionLabelProp="label"
//         tagRender={({ label, value, closable, onClose }) => (
//           <Tag
//             closable={closable}
//             onClose={onClose}
//             style={{ display: 'flex', alignItems: 'center' }}
//           >
//             <Space>
//               <Image width={20} height={20} src={options.find(option => option.value === value)?.image} />
//               {label}
//             </Space>
//           </Tag>
//         )}
//       >
//         {options.map(option => {
//           const isSelected = selectedItems.includes(option.value);
//           return (
//             <Select.Option key={option.value} value={option.value} label={option.label}>
//               <Space onClick={() => handleOptionClick(option.value)}>
//                 <img src={isSelected ? Fill : Check} alt="" width={20} height={20} />
//                 {option.label}
//               </Space>
//             </Select.Option>
//           );
//         })}
//       </Select>
//     </>
//   );
// };

// export default PartnerDropdown;


// import React, { useEffect, useState } from 'react';
// import { Select, Space, Image } from 'antd';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../redux/store';

// interface SuperPartner {
//   user_id: number;
//   name: string;
//   email: string;
//   profile_image: string | null;
//   role: string;
// }

// interface PartnerDropdownProps {
//   linkedSuperPartners: SuperPartner[];
//   onChange: (selectedPartners: number[]) => void;
// }

// const PartnerDropdown: React.FC<PartnerDropdownProps> = ({ linkedSuperPartners, onChange }) => {
//   const [selectedItems, setSelectedItems] = useState<number[]>([]);
//   const superPartners = useSelector((state: RootState) => state.teams.superPartners);

//   useEffect(() => {
//     // Set initially selected items based on linkedSuperPartners
//     setSelectedItems(linkedSuperPartners?.map(partner => partner.user_id));
//   }, [linkedSuperPartners]);

//   const formatUserData = (inputData: SuperPartner[]) => {
//     return inputData?.map(user => ({
//       label: user.name,
//       value: user.user_id,
//       image: user.profile_image || '/images/profile.svg'
//     }));
//   };

//   const superPartnerOptions = formatUserData(superPartners);

//   const handleChange = (value: number[]) => {
//     setSelectedItems(value);
//     onChange(value);
//   };

//   return (
//     <>
//       <label>Super Partners</label>
//       <Select
//         mode="multiple"
//         style={{ width: '100%' }}
//         placeholder="Select super partners"
//         value={selectedItems}
//         onChange={handleChange}
//         optionLabelProp="label"
//         optionFilterProp="label"
//       >
//         {superPartnerOptions?.map(option => (
//           <Select.Option key={option.value} value={option.value} label={option.label}>
//             <Space>
//               <Image
//                 src={option?.image}
//                 alt={option?.label}
//                 width={24}
//                 height={24}
//                 style={{ borderRadius: '50%' }}
//                 preview={false}
//               />
//               {option.label}
//             </Space>
//           </Select.Option>
//         ))}
//       </Select>
//     </>
//   );
// };

// export default PartnerDropdown;

import React, { useEffect, useState } from 'react';
import { Select, Space, Image } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface SuperPartner {
  user_id: number;
  name: string;
  email: string;
  profile_image: string | null;
  role: string;
}

interface PartnerDropdownProps {
  linkedSuperPartners: SuperPartner[];
  onChange: (selectedPartners: number[]) => void;
}

const PartnerDropdown: React.FC<PartnerDropdownProps> = ({ linkedSuperPartners, onChange }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const superPartners = useSelector((state: RootState) => state.teams.superPartners);

  useEffect(() => {
    // Set initially selected items based on linkedSuperPartners
    setSelectedItems(linkedSuperPartners?.map(partner => partner.user_id));
  }, [linkedSuperPartners]);

  const formatUserData = (inputData: SuperPartner[]) => {
    return inputData?.map(user => ({
      label: user.name,
      value: user.user_id,
      image: user.profile_image || '/images/profile.svg'
    }));
  };

  const superPartnerOptions = formatUserData(superPartners);

  const handleChange = (value: number[]) => {
    // Ensure all linked super partners remain selected
    const newSelectedItems = Array.from(new Set([...linkedSuperPartners.map(p => p.user_id), ...value]));
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems);
  };

  const filterOption = (input: string, option: { label: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <label>Super Partners</label>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Select super partners"
        value={selectedItems}
        onChange={handleChange}
        optionLabelProp="label"
        optionFilterProp="label"
        filterOption={filterOption}
      >
        {superPartnerOptions?.map(option => (
          <Select.Option 
            key={option.value} 
            value={option.value} 
            label={option.label}
            disabled={linkedSuperPartners.some(p => p.user_id === option.value)}
          >
            <Space>
              <Image
                src={option?.image}
                alt={option?.label}
                width={24}
                height={24}
                style={{ borderRadius: '50%' }}
                preview={false}
              />
              {option.label}
            </Space>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default PartnerDropdown;