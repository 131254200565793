import axiosClient from "../utils/axios.ts";

class Payouts {
  async getAllPayouts() {
    try {
      const payouts = await axiosClient().get(
        // `https://glovendor-commission-portal-be-289e15bab0f6.herokuapp.com/users-commissions?MinimumCommission=0&MaximumCommission=25500&CompanyName=["[Sample] MoveEr"]&UserName=["Glovendor tech"]&Role= ["OWNER"]&payment_status=["paid"]`
        "users-commissions"
      );

      return payouts.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const payoutApi = new Payouts();
