// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FilledButton from "../../../../components/filledButton.tsx";
// import LabelInput from "../../../../components/labelInput.tsx";
import LabelInput from "../../../../components/commissionLabelInput.tsx";
import FilterIcon from "../../../../assets/images/filter.png";
import DateIcon from "../../../../assets/images/date.png";
import CloseIcon from "../../../../assets/images/close.png";
import DateRangePickerComponent from "./component/custom-rangepicker/index.tsx";
import SelectDropdown from "./component/select-dropdown/index.tsx";
import moment from "moment";
import BasicModal from "./component/transaction-modal/index.tsx";

export default function FilterComponent({
  data,
  onFilterChange,
  isEmployee,
  isAffiliate,
  isMini,
  isSuper,
  refresh,
  handleItemClick,
}) {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ref = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState({
    minAmount: "",
    maxAmount: "",
    companyName: "",
    affiliate: "",
    revenueType: "",
    dateRange: null,
  });

  const [appliedFilters, setAppliedFilters] = useState({});
  const initialDateRange = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(initialDateRange);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
      setDate(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [filterName]: value,
      };
      // If the value is not empty, add it to appliedFilters
      if (value !== "" && value !== null) {
        setAppliedFilters((prev) => ({
          ...prev,
          [filterName]: value,
        }));
      } else {
        // If the value is empty, remove it from appliedFilters
        const newAppliedFilters = { ...appliedFilters };
        delete newAppliedFilters[filterName];
        setAppliedFilters(newAppliedFilters);
      }
      return newFilters;
    });
  };

  const applyFilters = () => {
    const filteredEvents = data.filter((event) => {
      return (
        (!filters.minAmount || event.fee >= parseFloat(filters.minAmount)) &&
        (!filters.maxAmount || event.fee <= parseFloat(filters.maxAmount)) &&
        (!filters.companyName ||
          event.organizationName === filters.companyName) &&
        (!filters.affiliate || event.employeeName === filters.affiliate) &&
        (!filters.revenueType || event.feeType === filters.revenueType) &&
        (!filters.dateRange ||
          (new Date(event.date) >= new Date(filters.dateRange.startDate) &&
            new Date(event.date) <= new Date(filters.dateRange.endDate)))
      );
    });
    onFilterChange(filters);
    setShow(false);

    // Update openEvent when filters are applied
    if (filteredEvents.length > 0) {
      handleItemClick(filteredEvents[0]);
    }
  };

  const resetFilters = () => {
    const emptyFilters = {
      minAmount: "",
      maxAmount: "",
      companyName: "",
      affiliate: "",
      revenueType: "",
      dateRange: null,
    };
    setFilters(emptyFilters);
    setAppliedFilters({});
    onFilterChange(emptyFilters);

    // Reset to the first event in the original data
    if (data.length > 0) {
      handleItemClick(data[0]);
    }
  };

  const removeFilter = (key) => {
    const newAppliedFilters = { ...appliedFilters };
    delete newAppliedFilters[key];
    setAppliedFilters(newAppliedFilters);
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [key]: "" };
      onFilterChange(newFilters);
      return newFilters;
    });

    // Update openEvent when a filter is removed
    const filteredEvents = data.filter((event) => {
      return (
        (!newAppliedFilters.minAmount ||
          event.fee >= parseFloat(newAppliedFilters.minAmount)) &&
        (!newAppliedFilters.maxAmount ||
          event.fee <= parseFloat(newAppliedFilters.maxAmount)) &&
        (!newAppliedFilters.companyName ||
          event.organizationName === newAppliedFilters.companyName) &&
        (!newAppliedFilters.affiliate ||
          event.employeeName === newAppliedFilters.affiliate) &&
        (!newAppliedFilters.revenueType ||
          event.feeType === newAppliedFilters.revenueType) &&
        (!newAppliedFilters.dateRange ||
          (new Date(event.date) >=
            new Date(newAppliedFilters.dateRange.startDate) &&
            new Date(event.date) <=
              new Date(newAppliedFilters.dateRange.endDate)))
      );
    });

    if (filteredEvents.length > 0) {
      handleItemClick(filteredEvents[0]);
    }
  };

  const handleDateRangeApply = (range) => {
    const newFilters = {
      ...filters,
      dateRange: range,
    };
    setFilters(newFilters);
    setAppliedFilters((prev) => ({
      ...prev,
      dateRange: range,
    }));
    onFilterChange(newFilters);
    setDate(false);

    // Update openEvent when date range is applied
    const filteredEvents = data.filter((event) => {
      return (
        (!newFilters.minAmount ||
          event.fee >= parseFloat(newFilters.minAmount)) &&
        (!newFilters.maxAmount ||
          event.fee <= parseFloat(newFilters.maxAmount)) &&
        (!newFilters.companyName ||
          event.organizationName === newFilters.companyName) &&
        (!newFilters.affiliate ||
          event.employeeName === newFilters.affiliate) &&
        (!newFilters.revenueType || event.feeType === newFilters.revenueType) &&
        (!range ||
          (new Date(event.date) >= new Date(range.startDate) &&
            new Date(event.date) <= new Date(range.endDate)))
      );
    });

    if (filteredEvents.length > 0) {
      handleItemClick(filteredEvents[0]);
    }
  };

  const companyOptions = [
    ...new Set(data?.map((event) => event.organizationName)),
  ].map((name) => ({ value: name, label: name }));

  const affiliateOptions = [
    ...new Set(data?.map((event) => event.affiliateName)),
  ].map((name) => ({ value: name, label: name }));

  const employeeOptions = [
    ...new Set(data?.map((event) => event.employeeName)),
  ].map((name) => ({ value: name, label: name }));

  const superOptions = [
    ...new Set(data?.map((event) => event.superPartnerName)),
  ].map((name) => ({ value: name, label: name }));

  const miniOptions = [...new Set(data?.map((event) => event.partnerName))].map(
    (name) => ({ value: name, label: name })
  );

  const revenueTypeOptions = [
    ...new Set(data?.map((event) => event.feeType)),
  ].map((type) => ({ value: type, label: type }));

  return (
    <>
      <Box className="filterBox" style={{ position: "relative" }}>
        <Box className="filterOption">
          <FilledButton className="btnDate" onClick={() => setShow(true)}>
            <img src={FilterIcon} alt="" /> Filters
          </FilledButton>
          {show && (
            <Box className="filterSection" ref={ref}>
              <Typography variant="h1">Filters</Typography>
              <Box className="filterMenu">
                <Grid
                  container
                  sx={{ width: "352px !important", margin: "0 auto" }}
                >
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Min amount"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={filters.minAmount}
                        onChange={(e) =>
                          handleFilterChange("minAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={0.6}></Grid>
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Max amount"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={filters.maxAmount}
                        onChange={(e) =>
                          handleFilterChange("maxAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Company name"
                        options={companyOptions}
                        value={filters.companyName}
                        onChange={(value) =>
                          handleFilterChange("companyName", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title={
                          isAffiliate
                            ? "Partners"
                            : isMini
                            ? "Mini Partners"
                            : isSuper
                            ? "Super Partners"
                            : "Employees"
                        }
                        options={
                          isAffiliate
                            ? affiliateOptions
                            : isMini
                            ? miniOptions
                            : isSuper
                            ? superOptions
                            : employeeOptions
                        }
                        value={filters.affiliate}
                        onChange={(value) =>
                          handleFilterChange("affiliate", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Revenue Type"
                        options={revenueTypeOptions}
                        value={filters.revenueType}
                        onChange={(value) =>
                          handleFilterChange("revenueType", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                      width: "100%",
                    }}
                  >
                    <FilledButton className="cancelBtn" onClick={resetFilters}>
                      Reset
                    </FilledButton>
                    <FilledButton
                      style={{ background: "#155EEF", color: "#fff" }}
                      className="cancelBtn"
                      onClick={applyFilters}
                    >
                      Apply
                    </FilledButton>
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="filterOption" sx={{ position: "relative" }}>
          <FilledButton className="btnDate sz" onClick={() => setDate(true)}>
            <img src={DateIcon} alt="" /> Select Dates
          </FilledButton>
          {date && (
            <Box className="dateBox" ref={ref}>
              <DateRangePickerComponent
                dateRange={dateRange}
                setDateRange={setDateRange}
                onApply={handleDateRangeApply}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {Object.entries(appliedFilters).map(([key, value]) => (
            <FilledButton
              key={key}
              className="tagBtn"
              onClick={() => removeFilter(key)}
            >
              <img src={CloseIcon} alt="" />
              {key === "companyName" && `Company name: ${value}`}
              {key === "revenueType" && `Revenue Type: ${value}`}
              {key === "affiliate" && `Affiliate: ${value}`}
              {key === "dateRange" &&
                `Date: ${moment(value.startDate).format("l")} - ${moment(
                  value.endDate
                ).format("l")}`}
              {key === "minAmount" && `Min amount: ${value}`}
              {key === "maxAmount" && `Max amount: ${value}`}
            </FilledButton>
          ))}
        </Box>
        {(isAffiliate || isEmployee) && (
          <FilledButton className="addBtn-1" onClick={handleOpen}>
            + Manually Add Transaction
          </FilledButton>
        )}
      </Box>
      <BasicModal
        open={open}
        handleClose={handleClose}
        refresh={refresh}
        isAffiliate={isAffiliate}
      />
    </>
  );
}
