"use client";
import React, { useState } from "react";
import { Col, Input, Row, Spin, Typography } from "antd";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";
const PipedriveSection = ({
  setIsHit,
  isHit,
  setLoading,
  isLoading,
  setApiKey,
  apiKey,
  id,
}) => {
  const { Text } = Typography;
  const [isToggled, setIsToggled] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleSubmit = () => {
    setLoading(true);

    if (isUpdate) {
      axiosClient()
        .patch(`pipedrive-key/${id}`, {
          key: apiKey,
        })
        .then((res) => {
          setLoading(false);
          toast.success("Api key updated");
          setIsHit(true);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error("Something went wrong!");
        });
    } else {
      axiosClient()
        .post("pipedrive-key", {
          key: apiKey,
        })
        .then((res) => {
          setLoading(false);
          toast.success("Api key added");
          setIsHit(true);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }
  };

  return (
    <div>
      <div className="w-full pb-4   ">
        <Text className="text-[#475467] text-[14px] font-normal font-sans gap-4">
          Integration <span className="pl-2">&gt;</span>{" "}
          <span className="font-semibold pl-2">Pipedrive</span>
        </Text>

        <Row className="w-full  mt-6 ">
          <Col span={6} className="grid h-[60px]">
            <Text className="text-[rgb(16,24,40)] text-[18px] font-semibold font-sans">
              Authentication
            </Text>
            <Text className="text-[#475467] text-[14px]">
              Update your Pipedrive settings
            </Text>
          </Col>

          <Col span={18} className="w-full ">
            <div className="w-full p-4 pb-6 bg-white rounded-xl shadow-md overflow-hidden ">
              <div>
                <div className="flex items-center justify-between">
                  <div className=" flex items-center">
                    <img
                      className="w-[40px] h-[40px]"
                      src="/images/pipedrive.svg"
                      alt="Pipedrive"
                    />

                    <div className="ml-2">
                      <Text className="text-[#101828] text-[16px] font-semibold">
                        Pipedrive
                      </Text>
                    </div>
                  </div>

                  <div className=" flex items-center">
                    <label className="flex items-center cursor-pointer">
                      {(isToggled || isHit) && (
                        <div>
                          <Text
                            className={` text-[14px] mr-3 font-semibold w-auto rounded-[16px] p-1 px-3 ${
                              isToggled || isHit
                                ? "text-[#027A48] bg-[#ECFDF3]  "
                                : "text-[#344054] bg-[#F2F4F7]"
                            }`}
                          >
                            Enabled
                          </Text>
                        </div>
                      )}
                      <div className="relative">
                        <input
                          type="checkbox"
                          className="sr-only"
                          checked={isToggled || isHit}
                          onChange={handleToggle}
                          disabled={isHit}
                        />
                        <div
                          className={`block  w-10 h-6 rounded-full ${
                            isToggled || isHit ? "bg-[#155EEF]" : "bg-[#F2F4F7]"
                          }`}
                        ></div>
                        <div
                          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${
                            isToggled || isHit ? "transform translate-x-4 " : ""
                          }`}
                        ></div>
                      </div>
                    </label>
                  </div>
                </div>
                {isLoading ? (
                  <div className="flex justify-center py-8">
                    <Spin />
                  </div>
                ) : (
                  <div className="py-4 ">
                    <p className="mt-1 text-gray-500">Enter your API key</p>
                    <Input
                      type="text"
                      className="w-full mt-4 h-[44px] placeholder:text-[#667085] rounded-[8px]"
                      placeholder="API key"
                      disabled={isHit}
                      value={isHit ? "*".repeat(apiKey?.length) : apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      style={{
                        boxShadow: "0px 1px 2px 0px #1018280D",
                      }}
                    />
                  </div>
                )}
                {isHit ? (
                  <>
                    <div className="flex justify-end gap-4 py-4">
                      <button
                        onClick={() => {
                          setIsHit(false);
                          setIsUpdate(true);
                        }}
                        className="text-[#404040] font-semibold text-[14px]"
                      >
                        Update API Key
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {apiKey && (
                      <div className="flex justify-end gap-4 py-4">
                        <button
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            isHit
                              ? (setIsHit(false), setApiKey(""))
                              : setIsHit(true);
                          }}
                          className="text-[#404040] font-semibold text-[14px]"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleSubmit}
                          className="text-[#155EEF] font-semibold text-[14px]"
                        >
                          {isLoading ? <Spin /> : "Save"}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PipedriveSection;
