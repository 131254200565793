import { createSlice } from "@reduxjs/toolkit";

const initialState: { isLoading: boolean } = {
  isLoading: false,
};

export const counterSlice = createSlice({
  name: "isLoading",
  initialState,
  reducers: {
    setReduxLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReduxLoading } = counterSlice.actions;

export default counterSlice.reducer;
