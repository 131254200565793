import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Header from "../../layout/header/index.tsx";
import EmployeeApproval from "./component/employee-approval/index.tsx";
import AffiliateApproval from "./component/affiliate-approval/index.tsx";
import Payouts from "../payouts/main.tsx";
import axiosClient from "../../utils/axios.ts";
import { useSelector } from "react-redux";
import MiniPartnerApproval from "./component/mini-partner/index.tsx";
import SuperPartnerApproval from "./component/super-partner/index.tsx";
import Archived from "./component/archived/main.tsx";
import Reviewed from "./component/reviewed/main.tsx";

const Commision = () => {
  const [activeTab, setActiveTab] = useState("employee");
  // const [eventsData, setEventsData] = useState(dummyEvents); //holding all events coming from webhook, hardcoded for now, change it to empty array and update through api call later
  const [eventsData, setEventsData] = useState([]);
  const [eventsData1, setEventsData1] = useState([]);
  const [employeeEvents, setEmployeeEvents] = useState([]);
  const [affiliateEvents, setAffiliateEvents] = useState([]);
  const [reviewedEvents, setReviewedEvents] = useState([]);
  const [archivedEvents, setArchivedEvents] = useState([]);

  const [reviewedFilters, setReviewedFilters] = useState({});
  const [archivedFilters, setArchivedFilters] = useState({});

  const affiliates = useSelector((state: RootState) => state.teams.affilliate);
  const employees = useSelector((state: RootState) => state.teams.employee);

  const [isLoading,setIsLoading] = useState(true);

  // const getEvents = async () => {
  //   //Transactions pending for Approval
  //   axiosClient()
  //     .get("transactions/pending")
  //     .then((res) => {
  //       // console.log("All Events: ", res.data);
  //       setEventsData(res.data.transactions);
  //     })
  //     .catch((error) => console.error(error.message));
  // };

  const getReviewedEvents = async (filters = {}) => {
    const queryParams = new URLSearchParams(filters).toString();
    //Approved Transactions
    axiosClient()
      .get(`users-commissions/reviewed-formatted?${queryParams}`)
      .then((res) => {
        // console.log("All Approved: ", res.data);
        setReviewedEvents(res.data);
      })
      .catch((err) => console.error(err));
  };

  const updateArchivedFilters = (newFilters) => {
    setArchivedFilters(newFilters);
    // console.log("Coming Filters in Main: ",newFilters);
  };

  const updateReviewedFilters = (newFilters) => {
    setReviewedFilters(newFilters);
    // console.log("Coming Filters in Main: ",newFilters);
  };

  //Archived Transactions
  const getArchivedEvents = async (filters = {}) => {
    const queryParams = new URLSearchParams(filters).toString();
    axiosClient()
      .get(`transactions/archived-formatted?${queryParams}`)
      .then((res) => {
        setArchivedEvents(res.data.transactions);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    // getEvents();
    getReviewedEvents();
    getArchivedEvents();
  }, []);

  useEffect(() => {
    if (activeTab === "archive") {
      getArchivedEvents(archivedFilters);
    }
  }, [activeTab, archivedFilters]);

  useEffect(() => {
    if (activeTab === "reviewed") {
      getReviewedEvents(reviewedFilters);
    }
  }, [activeTab, reviewedFilters]);

  // function createEmployeeFormatArray(transactions, employees) {
  //   const ownerEvents = transactions.filter(
  //     (event) =>
  //       event.owner_email &&
  //       !event.is_approved_for_owner &&
  //       !event.is_archived_for_owner &&
  //       employees.some((e) => e.email === event.owner_email)
  //   );
  //   const sdrEvents = transactions.filter(
  //     (event) =>
  //       event.sdr_owner_email &&
  //       !event.is_approved_for_sdr_owner &&
  //       !event.is_archived_for_sdr_owner &&
  //       employees.some((e) => e.email === event.sdr_owner_email)
  //   );

  //   const ownerObjects = ownerEvents.map((event) => {
  //     const employee = employees.find((e) => e.email === event.owner_email);
  //     return {
  //       fee: event.fee,
  //       feeType: event.fee_type,
  //       createdAt: event.created_at,
  //       dealName: event.deal_name,
  //       transactionId: event.transaction_id,
  //       organizationId: event.organization_id,
  //       organizationName: event.organization_name,
  //       date: event.date,
  //       employeeId: employee ? employee.user_id : null,
  //       employeeName: event.owner_name,
  //       isApproved: event.is_approved_for_owner,
  //       isArchived: event.is_archived_for_owner,
  //       percentage: employee ? employee?.commission_model_id?.percentage : 0,
  //       role: "Owner",
  //     };
  //   });

  //   const sdrObjects = sdrEvents.map((event) => {
  //     const employee = employees.find((e) => e.email === event.sdr_owner_email);
  //     return {
  //       fee: event.fee,
  //       feeType: event.fee_type,
  //       createdAt: event.created_at,
  //       dealName: event.deal_name,
  //       transactionId: event.transaction_id,
  //       organizationId: event.organization_id,
  //       organizationName: event.organization_name,
  //       date: event.date,
  //       employeeId: employee ? employee.user_id : null,
  //       employeeName: employee ? employee.name : "",
  //       isApproved: event.is_approved_for_sdr_owner,
  //       isArchived: event.is_archived_for_sdr_owner,
  //       percentage: employee ? employee?.commission_model_id?.percentage : 0,
  //       role: "SDR",
  //     };
  //   });

  //   return [...ownerObjects, ...sdrObjects].sort(
  //     (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //   );
  // }

  // function createAffiliateFormatArray(events, affiliates) {
  //   return events
  //     .filter(
  //       (event) =>
  //         event.affiliate_id !== null &&
  //         !event.is_approved &&
  //         !event.is_archived
  //     )
  //     .map((event) => {
  //       const affiliate = affiliates.find(
  //         (a) => a.user_id == event.affiliate_id
  //       );
  //       if (affiliate && affiliate.is_active) {
  //         return {
  //           fee: event.fee,
  //           feeType: event.fee_type,
  //           createdAt: event.created_at,
  //           dealName: event.deal_name,
  //           transactionId: event.transaction_id,
  //           organizationId: event.organization_id,
  //           organizationName: event.organization_name,
  //           date: event.date,
  //           affiliateId: event.affiliate_id,
  //           affiliateName: event.affiliate_name,
  //           isApproved: event.is_approved,
  //           isArchived: event.is_archived,
  //           percentage: affiliate
  //             ? affiliate?.commission_model_id?.percentage
  //             : 0,
  //           role: "Partner",
  //         };
  //       }
  //       return null;
  //     })
  //     .filter(Boolean);
  // }

  // useEffect(() => {
  //   if (eventsData != null && affiliates != null && employees != null) {
  //     setAffiliateEvents(createAffiliateFormatArray(eventsData, affiliates));
  //     setEmployeeEvents(createEmployeeFormatArray(eventsData, employees));
  //   }
  // }, [eventsData, affiliates, employees]);

  /////

  const getEvents1 = async () => {
    //Transactions pending for Approval
    setIsLoading(true);
    axiosClient()
      .get("transactions/pending-formatted")
      .then((res) => {
        // console.log("All Events: ", res.data);
        setEventsData1(res.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error.message));
  };

  useEffect(() => {
    getEvents1();
  }, []);

  // console.log("New Data: ",eventsData1);

  const renderContent = () => {
    switch (activeTab) {
      case "employee":
        return (
          <Box>
            {/* <EmployeeApproval data={employeeEvents || []} refresh={getEvents} /> */}
            <EmployeeApproval
              data={eventsData1?.employeeEvents || []}
              refresh={getEvents1}
              isLoading={isLoading}
            />
          </Box>
        );
      case "affiliate":
        return (
          <Box>
            {/* <AffiliateApproval
              data={affiliateEvents || []}
              refresh={getEvents}
            /> */}
            <AffiliateApproval
              data={eventsData1?.affiliateEvents || []}
              refresh={getEvents1}
              isLoading={isLoading}
            />
            {/*Currently Passing all Data but need to do filtering in future*/}
          </Box>
        );
      case "partner":
        return (
          <Box>
            {/* <MiniPartnerApproval data={employeeEvents || []} refresh={getEvents}/> */}
            {/* <MiniPartnerApproval data={[]} refresh={getEvents} /> */}
            <MiniPartnerApproval
              data={eventsData1?.miniPartnerEvents || []}
              refresh={getEvents1}
              isLoading={isLoading}
            />
          </Box>
        );
      case "superpartner":
        return (
          <Box>
            {/* <SuperPartnerApproval data={employeeEvents || []} refresh={getEvents}/> */}
            {/* <SuperPartnerApproval data={[]} refresh={getEvents} /> */}
            <SuperPartnerApproval
              data={eventsData1?.superPartnerEvents || []}
              refresh={getEvents1}
              isLoading={isLoading}
            />
          </Box>
        );
      case "archive":
        return (
          <Box>
            <Archived
              data={archivedEvents || []}
              refresh={() => {
                getArchivedEvents(archivedFilters);
                getEvents1();
              }}
              updateFilters={updateArchivedFilters}
            />
          </Box>
        );
      case "reviewed":
        return (
          <Box>
            <Reviewed
              data={reviewedEvents || []}
              refresh={getReviewedEvents}
              updateFilters={updateReviewedFilters}
            />
          </Box>
        );

      case "payments":
        // return <h1>Payments</h1>;
        return <Payouts />;
      default:
        return null;
    }
  };

  return (
    <>
      <Header />

      <div className="container head">
        <Box sx={{ margin: "32px 0" }}>
          <h3
            style={{
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            Commission Management
          </h3>
        </Box>
        <Box className="tabs" sx={{ display: "flex", cursor: "pointer" }}>
          <span
            onClick={() => setActiveTab("employee")}
            style={
              activeTab === "employee"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Employee Approvals
          </span>
          <span
            onClick={() => setActiveTab("affiliate")}
            style={
              activeTab === "affiliate"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Partner Approvals
          </span>
          <span
            onClick={() => setActiveTab("partner")}
            style={
              activeTab === "partner"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Mini Partner Approvals
          </span>
          <span
            onClick={() => setActiveTab("superpartner")}
            style={
              activeTab === "superpartner"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Super Partner Approvals
          </span>
          {/* <span
            onClick={() => setActiveTab("payments")}
            style={
              activeTab === "payments"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Payments
          </span> */}
          <span
            onClick={() => setActiveTab("reviewed")}
            style={
              activeTab === "reviewed"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Reviewed
          </span>
          <span
            onClick={() => setActiveTab("archive")}
            style={
              activeTab === "archive"
                ? { color: "#004EEB", borderBottom: "2px solid #004EEB" }
                : {}
            }
          >
            Archived
          </span>
        </Box>

        <Box sx={{ marginTop: "20px" }}>{renderContent()}</Box>
      </div>
    </>
  );
};

export default Commision;
