import * as React from 'react';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface DatePickerI {
 onChange: (dateValue: any) => void;
}

export default function StaticDatePickerLandscape({onChange}: DatePickerI) {
  return (
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <StaticDatePicker orientation="landscape" onChange={(date) => onChange(date?.toDate())} />
    // </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs} className="static">
      <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} >
        <DatePicker
          views={['year', 'month', 'day']}
          format='DD/MM/YYYY'
          onChange={(date) => onChange(date?.toDate())}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

// import * as React from 'react';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
// import dayjs from 'dayjs';

// interface DatePickerI {
//  onChange: (dateValue: Date | null) => void;
// }

// export default function StaticDatePickerLandscape({onChange}: DatePickerI) {
//   const [date, setDate] = React.useState<dayjs.Dayjs | null>(dayjs());

//   const handleDateChange = (newDate: dayjs.Dayjs | null) => {
//     setDate(newDate);
//     onChange(newDate?.toDate() || null);
//   };

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <StaticDatePicker 
//         orientation="landscape"
//         openTo="year"
//         views={['year', 'month', 'day']}
//         value={date}
//         onChange={handleDateChange}
//         displayStaticWrapperAs="desktop"
//       />
//     </LocalizationProvider>
//   );
// }