import React, { useState } from "react";
import { Modal, Typography, Button, Spin } from "antd";
import axiosClient from "../../utils/axios.ts";
import useOpenCommission from "../../hooks/use-commission.tsx";
import { toast } from "react-toastify";

const RemoveModal: React.FC = ({
  isRemoveOpen,
  setIsRemoveOpen,
  commissionId,
  commissionData,
  setCommissionData,
  refresh,
}: any) => {
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const { getCommission } = useOpenCommission();

  const handleRemove = () => {
    setIsLoading(true);
    axiosClient()
      .delete(`commission-model/${commissionId}`)
      .then((res) => {
        toast.success("Commission deleted successfully");
        getCommission();
        setIsRemoveOpen(false);
        setIsLoading(false);
        setCommissionData();
        refresh();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        centered
        open={isRemoveOpen}
        width={550}
        style={{
          boxShadow: "0px 32px 64px -12px #10182824",
        }}
        footer={null}
        closable={false}
        wrapClassName="custom-modal"
      >
        <div className="flex justify-between gap-2 items-start py-6  px-6 ">
          <img
            src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(3).svg?updatedAt=1709729512337"
            alt=""
          />
          <div className="grid">
            <Text className="text-[#101828] text-[18px] font-semibold">
              Remove {`"${commissionData?.name}"`} ?
            </Text>
            <Text className="text-[#475467] text-[14px] font-normal">
              Are you sure you want to remove? This action cannot be undone.
              Affected users will be moved to default commission model.
            </Text>
          </div>
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
            alt=""
            className="cursor-pointer"
            onClick={() => setIsRemoveOpen(false)}
          />
        </div>

        <div className="px-6 py-6 flex gap-3 items-center justify-end ">
          <Button
            onClick={() => {
              setIsRemoveOpen(false);
              setCommissionData();
            }}
            className="w-[120px] h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            icon=""
            disabled={isLoading}
            onClick={handleRemove}
            className="w-[120px] h-[44px] bg-[#D92D20] rounded-[8px] font-semibold text-[16px] text-[#fff]"
          >
            {isLoading ? <Spin /> : "Remove"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RemoveModal;
