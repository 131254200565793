import axiosClient from "../utils/axios.ts";

class Commission {
  async getAllCommissions() {
    try {
      const commission = await axiosClient().get("commission-model");

      return commission.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const commissionApi = new Commission();
