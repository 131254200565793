import axiosClient from "../utils/axios.ts";

class Stats {
  async getAllStats(values) {
    const today = new Date();
    const currentDate = new Date(today);
    currentDate.setDate(currentDate.getDate() + 1);
    const todayISOString = currentDate.toISOString().split("T")[0];

    const legacyDate = new Date("01/01/2022");
    const legacyDateISOString = legacyDate.toISOString().split("T")[0];
    
    const currentMonth = today.getMonth();
    
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(currentMonth - 1);
    const oneMonthAgoISOString = oneMonthAgo.toISOString().split("T")[0];

    // Get the start of the current year
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const startOfYearISOString = startOfYear.toISOString().split("T")[0];

    try {
      const stats = await axiosClient().get(
        `pipedrive-organisations/stats?Status=In%20Pipeline&StartDate=${
          // values?.from ? values?.from : startOfYearISOString
          values?.from ? values?.from : legacyDateISOString
        }&EndDate=${values?.to ? values?.to : todayISOString}`
      );

      return stats.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const statsApi = new Stats();
