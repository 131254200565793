import { useState } from "react";
import { organizationApi } from "../services/organization.ts";
import { useDispatch } from "react-redux";
import { addOpenOrganizations } from "../redux/organization.ts";
import { setReduxLoading } from "../redux/loading.ts";

type InitialValues = {
  values?: any;
};

const useOpenOrganization = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const getOrganizations = async (values: InitialValues) => {
    dispatch(setReduxLoading(true));
    try {
      const jobs = await organizationApi.getAllOrganizations(values);
      dispatch(addOpenOrganizations(jobs));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getOrganizations,
  };
};

export default useOpenOrganization;
