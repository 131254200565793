import { Input } from 'antd';
import React from 'react';

// Extend the LabelInputProps interface to include onClick
interface LabelInputProps {
  title?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>; // Add the onClick handler type
  disabled?: boolean;
}

const LabelInput: React.FC<LabelInputProps> = ({ title, placeholder, value, className, onClick, disabled, ...rest }) => {
  return (
    <>
      <label>{title}</label>
      <Input 
      value={value}
        placeholder={placeholder} 
        // disabled
        {...rest} 
        className={className} 
        onClick={onClick} // Pass the onClick handler
      />
    </>
  );
}

export default LabelInput;
