import React, { useState } from "react";
import { Table, Typography } from "antd";
import axiosClient from "../../utils/axios.ts";
import moment from "moment";

interface DataType {
  key: React.Key;
  name: string;
  Type: string;
  Amount: string;
  Company: string;
  Date: string;
  edit: any;
  Status: any;
}

const App: React.FC = ({
  setIsViewOpen,
  activeTab,
  column,
  data,
  pyoutData,
  setPayoutData,
  setViewData,
}: any) => {
  const { Text } = Typography;

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  window.localStorage.setItem("payout_counts", pyoutData?.length);

  const handleGetDataById = (id) => {
    setIsViewOpen(true);
    axiosClient()
      .get(`pipedrive-organisations/${id}`)
      .then((res) => {
        setViewData(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const ButtonComponent = (item) => {
    return (
      <div className="flex items-center gap-4 justify-end ">
        <button
          onClick={() => handleGetDataById(item?.item?.ID)}
          className="viewss text-[#475467] hover:text-[#004EEB]"
        >
          View
        </button>
      </div>
    );
  };
  const StatusBtn = ({ item }) => {
    return (
      <Text
        style={
          item === "Churned" || item === "Disqualified"
            ? { backgroundColor: "#FEF3F2", color: "#B42318" }
            : item === "Lead"
            ? { backgroundColor: "#EFF4FF", color: "#0439a3" }
            : item === "In Pipeline"
            ? { backgroundColor: "#FFFAEB", color: "#B54708" }
            : item === "Customer"
            ? { backgroundColor: "#ECFDF3", color: "#027A48" }
            : { backgroundColor: "#FFFAEB", color: "#B54708" }
        }
        className="text-[14px] fontss capitalize w-auto rounded-[16px] p-1 px-3"

      >
        {item ? item : "No status"}
      </Text>
    );
  };

  const mapDataToTableFormat = (pyoutData: any[]) => {
    return pyoutData?.map((item, index) => {
      const commissionPaidToOwner = parseFloat(item.CommissionPaidToOwner) || 0;
      const commissionPaidToSDROwner =
        parseFloat(item.CommissionPaidToSDROwner) || 0;
      const commissionPaidToAffiliate =
        parseFloat(item.CommissionPaidToAffiliate) || 0;
      const totalCommission =
        commissionPaidToOwner +
        commissionPaidToSDROwner +
        commissionPaidToAffiliate;

      return {
        key: index,
        name: item?.Name,
        // referred: item.Owner?.name ? item.Owner?.name : "---",
        referred: item?.AffiliateName || item?.SDROwner?.name || "---",
        // closeDate: item.SDROwner?.name ? item.SDROwner?.name : "No one",
        closedBy: item?.Owner?.name ? item?.Owner?.name : "No one",
        createdOn: item?.add_time
          ? moment(item?.add_time).format("DD/MM/YYYY")
          : "---",
        status: <StatusBtn item={item?.Status} />,
        commission:
          totalCommission > 0 ? `$${totalCommission?.toFixed(2)}` : "$0",
        total: item?.AggregateRevenue ? `$${item?.AggregateRevenue}` : "---",
        edit: <ButtonComponent item={item} />,
      };
    });
  };

  // @ts-ignore
  const tabledata: DataType[] = mapDataToTableFormat(pyoutData);

  const totalItems = tabledata?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = tabledata?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
      }}
      className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden"
    >
      <Table
        columns={column}
        className="companiesModal"
        dataSource={paginatedData}
        style={{
          borderTop: "1px solid #EAECF0",
        }}
        pagination={false}
      />

      <div className="w-full bg-white  items-center  px-4  flex justify-between gap-4" style={{height:"64px"}}>
        <Text className="text-[#344054] text-[14px] font-medium ">
          Page {currentPage} of {totalPages}
        </Text>

        <div className="flex items-center gap-3 paginationBtn">
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
