// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Drawer, Typography, Input, Button, Spin } from "antd";
import { Checkbox, Slider } from "../../components/index.ts";
import {
  FILTER_NAME,
  FILTER_TYPE,
  FILTER_STATUS,
  FILTER_COMPANY_STATUS,
} from "../../constants/data.ts";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";

const SidebarFilter = ({
  isViewOpen,
  setIsViewOpen,
  setPayoutData,
  pyoutData,
  activeTab,
  isCompany,
  organization,
  sidebarData,
  setSelectedFFilters,
  setCheckboxValues,
  checkboxValues,
  setIsCheck,
}) => {
  const { Text } = Typography;

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };
  const [itemsToShow, setItemsToShow] = useState(10);
  const [searchName, setSearchName] = useState(sidebarData);
  const [searchValue, setSearchValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleCheckboxChange = (name, value) => {
    setCheckboxValues((prevValues) => {
      const currentValues = prevValues[name] || [];
      if (currentValues.includes(value)) {
        // Remove value if it is already present
        return {
          ...prevValues,
          [name]: currentValues.filter((v) => v !== value),
        };
      } else {
        // Add value if it is not present
        return {
          ...prevValues,
          [name]: [...currentValues, value],
        };
      }
    });

    setSelectedFFilters((prevValues) => {
      const currentValues = prevValues[name] || [];
      if (currentValues.includes(value)) {
        // Remove value if it is already present
        return {
          ...prevValues,
          [name]: currentValues.filter((v) => v !== value),
        };
      } else {
        // Add value if it is not present
        return {
          ...prevValues,
          [name]: [...currentValues, value],
        };
      }
    });
  };

  const handleShowMore = () => {
    setItemsToShow((prevItemsToShow) => prevItemsToShow + 10);
  };

  const handleShowLess = () => {
    setItemsToShow((prevItemsToShow) => Math.max(10, prevItemsToShow - 10));
  };

  const handleApply = () => {
    setIsLoading(true);
    const names = checkboxValues.company || [];
    const namesJson = JSON.stringify(names);
    const encodedNames = encodeURIComponent(namesJson);

    const status = checkboxValues?.status || [];
    const statusJson = JSON.stringify(status);
    const encodedStatus = encodeURIComponent(statusJson);

    const owner = checkboxValues?.referred || [];
    const ownerJson = JSON.stringify(owner);
    const encodedOwner = encodeURIComponent(ownerJson);

    const sdrOwner = checkboxValues?.closed || [];
    const sdrOwnerJson = JSON.stringify(sdrOwner);
    const encodedSdrOwner = encodeURIComponent(sdrOwnerJson);

    const queryParams = [];

    // Add parameters to the array only if they are not empty
    if (names.length) queryParams.push(`Name=${encodedNames}`);
    if (status.length) queryParams.push(`Status=${encodedStatus}`);
    if (owner.length) queryParams.push(`Owner=${encodedOwner}`);
    if (sdrOwner.length) queryParams.push(`SDROwner=${encodedSdrOwner}`);

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
    axiosClient()
      // .get(`/pipedrive-organisations/${queryString}`)
      .get(`/pipedrive-organisations/all-referred/${queryString}`)
      .then((res) => {
        setPayoutData(res?.data);
        setIsViewOpen(false);
        setIsLoading(false);
        setIsCheck(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        setIsLoading(false);
        setIsCheck(false);
      });
  };

  const HandleNameSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = FILTER_NAME;
    } else {
      filteredData = searchName?.filter((x) => {
        return (
          x?.label?.toLowerCase().charAt(0) === value?.toLowerCase().charAt(0)
        );
      });
    }
    setSearchName(filteredData);
  };

  const handleSearch = (value) => {
    let updatedData; // Initialize with the original data

    setSearchValue(value);
    if (!value) {
      setSearchName(sidebarData);
    } else {
      updatedData = sidebarData?.filter((sidebarData) =>
        sidebarData?.Name.toLowerCase()?.includes(value.toLowerCase().charAt(0))
      );

      setSearchName(updatedData);
    }
  };

  const getUniqueSDROwners = () => {
    const uniqueSDROwnerMap = {};

    organization?.forEach((item) => {
      if (item.SDROwner && item.SDROwner.name) {
        uniqueSDROwnerMap[item.SDROwner.name] = item;
      }
    });

    return Object.values(uniqueSDROwnerMap);
  };

  const getUniqueOwners = () => {
    const uniqueSDROwnerMap = {};

    organization?.forEach((item) => {
      if (item.Owner && item.Owner.name) {
        uniqueSDROwnerMap[item.Owner.name] = item;
      }
    });

    return Object.values(uniqueSDROwnerMap);
  };

  const uniqueSDROwners = getUniqueSDROwners();
  const uniqueOwners = getUniqueOwners();

  useEffect(() => {
    setSearchName(sidebarData);
  }, [sidebarData]);

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setIsViewOpen(false)}
        visible={isViewOpen}
        bodyStyle={{ padding: "20px", marginBottom: "80px" }}
        className="custom-drawer"
      >
        <div className="w-full h-full relative ">
          <div className="flex justify-between items-center">
            <Text className="text-[#101828] text-[20px] font-semibold">
              Filters
            </Text>

            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
              alt=""
              className="w-[15px] cursor-pointer"
              onClick={() => setIsViewOpen(false)}
            />
          </div>
          <Text className="text-[#475467] text-[14px] font-normal">
            Apply filters to your payouts
          </Text>

          {/* Status Filter */}
          {(activeTab === 1 || isCompany) && (
            <div>
              <div className="flex items-center gap-3 mt-4">
                <Text className="text-[#344054] text-[16px] font-medium">
                  Status
                </Text>
              </div>

              <div className="grid gap-y-4 mt-4 pl-2">
                {isCompany ? (
                  <>
                    {FILTER_COMPANY_STATUS?.map((item, i) => (
                      <Checkbox
                        key={i}
                        data={item}
                        onChange={(value) =>
                          handleCheckboxChange(`status`, value)
                        }
                        checked={checkboxValues?.status?.includes(item?.Name)}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {FILTER_STATUS?.map((item, i) => (
                      <Checkbox
                        key={i}
                        data={item}
                        onChange={(value) =>
                          handleCheckboxChange(`status`, value)
                        }
                        checked={checkboxValues?.status?.includes(item?.Name)}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          )}

          {/* Company Filter */}
          <div>
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px] font-medium">
                Company
              </Text>
            </div>

            <div className="w-full">
              <Input
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                prefix={<SearchIcon />}
                className="w-full h-[44px] mt-3 placeholder:text-black rounded-[8px]"
              />
            </div>
            <div className="grid gap-y-3 mt-4 mb-2 pl-2">
              {searchName?.slice(0, itemsToShow)?.map((item, i) => (
                <Checkbox
                  key={i}
                  data={item}
                  onChange={(value) => handleCheckboxChange("company", value)}
                  checked={checkboxValues?.company?.includes(item?.Name)}
                />
              ))}
              <div>
                {itemsToShow < sidebarData?.length && (
                  <button
                    onClick={handleShowMore}
                    className="text-[#004EEB] font-semibold"
                  >
                    Show More
                  </button>
                )}
                {itemsToShow > 10 && (
                  <button
                    onClick={handleShowLess}
                    className="text-[#004EEB] font-semibold"
                  >
                    Show Less
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Name Filter */}
          {!isCompany ? (
            <>
              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Name
                  </Text>
                </div>

                <div className="w-full">
                  <Input
                    style={{
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                    placeholder="Search"
                    onChange={(e) => HandleNameSearch(e.target.value)}
                    prefix={<SearchIcon />}
                    className="w-full h-[44px] placeholder:text-black rounded-[8px] mt-3"
                  />
                </div>
                <div className="grid gap-y-3 mt-4 pl-2">
                  {searchName?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item}
                      onChange={(value) => handleCheckboxChange(`name`, value)}
                    />
                  ))}
                </div>
              </div>

              {/* Type Filter */}
              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Type
                  </Text>
                </div>

                <div className="grid gap-y-3 mt-4 pl-2">
                  {FILTER_TYPE?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item}
                      onChange={(value) => handleCheckboxChange(`type`, value)}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Referred by:
                  </Text>
                </div>

                <div className="grid gap-y-3 mt-4 pl-2">
                  {uniqueOwners?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item?.Owner}
                      onChange={(value) =>
                        handleCheckboxChange(`referred`, value)
                      }
                      checked={checkboxValues?.referred?.includes(
                        item?.Owner?.name
                      )}
                    />
                  ))}
                </div>
              </div>

              <div>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px] font-medium">
                    Closed by:
                  </Text>
                </div>

                <div className="grid gap-y-3 mt-4 pl-2">
                  {uniqueSDROwners?.map((item, i) => (
                    <Checkbox
                      key={i}
                      data={item?.SDROwner}
                      onChange={(value) =>
                        handleCheckboxChange(`closed`, value)
                      }
                      checked={checkboxValues?.closed?.includes(
                        item?.SDROwner?.name
                      )}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          {/* Payout Amount Slider */}

          <div className="pb-6">
            <div className="flex items-center gap-3 mt-4">
              <Text className="text-[#344054] text-[16px]  font-medium">
                {isCompany ? "Commission:" : "  Payout Amount"}
              </Text>
            </div>
            <div>
              <Slider
                onChange={(value) => handleCheckboxChange(`Commission`, value)}
              />
            </div>
            {isCompany && (
              <>
                <div className="flex items-center gap-3 mt-4">
                  <Text className="text-[#344054] text-[16px]  font-medium">
                    Revenue:
                  </Text>
                </div>
                <Slider
                  onChange={(value) => handleCheckboxChange(`Revenue`, value)}
                />
              </>
            )}
          </div>

          {/* Apply and Cancel Buttons */}

          <div className="flex justify-end items-cente gap-4 py-4 border-t border-[#EAECF0] fixed w-[362px] z-[1200] right-4 bottom-0 mt-7">
            <div className="px-4 flex gap-4">
              <Button
                onClick={() => {
                  setIsViewOpen(false);
                  setCheckboxValues({});
                  setSelectedFFilters({});
                }}
                icon=""
                className="w-[100px] h-[44px] bg-[#fff] border border-[#D0D5DD] rounded-[8px] font-semibold text-[14px] text-[#344054]"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                icon=""
                disabled={isLoading}
                onClick={handleApply}
                className="w-[90px] h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[14px] text-[#fff]"
              >
                {isLoading ? <Spin /> : "Apply"}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarFilter;
