// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal, Typography, Button, Input, Select, Spin, Col, Row } from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axiosClient from "../../utils/axios.ts";
import { toast } from "react-toastify";
import { useAuthContext } from "../../context/auth-context.tsx";
import useOpenOrganization from "../../hooks/use-organization.tsx";
import useOpenTeams from "../../hooks/use-teams.tsx";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import { DeleteOutlined } from "@ant-design/icons";
import Avatar from "../../assets/images/avatar.png";
import Close from "../../assets/images/close.png";
import LabelInput from "../../components/labelInput.tsx";
import NoPhoto from "../../assets/profile.svg";
import moment from "moment";

const ModalSection = ({
  isAdminOpen,
  setIsAdminOpen,
  label,
  isEmploy,
  isEdit,
  userType,
  setUserType,
  selectedValue,
  employeeData,
  refresh,
}: any) => {
  const { Text } = Typography;
  const { isAuthenticated } = useAuthContext();
  const [organization, setOrganization] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { getOrganizations } = useOpenOrganization();
  const { getTeams } = useOpenTeams();

  const [selectValues, setSelectValues] = useState({
    select1: selectedValue?.commission_model_id?.commission_model_id
      ? selectedValue?.commission_model_id?.commission_model_id
      : "",
  });
  // console.log("Selected Values: ",selectedValue);
  // console.log("Employee Data: ", employeeData);

  const validationSchema = Yup.object().shape({
    email1: Yup.string(),
  });

  const selectOptions = organization
    ?.filter((item: any) => item?.user_type_id?.type_name === "EMPLOYEE")
    .map((item: any) => ({
      value: item.commission_model_id,
      label: item.name,
    }));

  const [fields, setFields] = useState([
    {
      email: employeeData?.email || "",
      commission_model_id: "",
    },
  ]);

  const [employeeFields, setEmployeeFields] = useState([
    { email: "", commission_model_id: "" },
  ]);

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
  };

  const handleEmployeeFieldChange = (index, field, value) => {
    const updatedFields = [...employeeFields];
    updatedFields[index][field] = value;
    setEmployeeFields(updatedFields);
  };

  const handleAddField = () => {
    setFields([...fields, { email: "", commission_model_id: "" }]);
  };

  const handleAddEmployeeField = () => {
    setEmployeeFields([
      ...employeeFields,
      { email: "", commission_model_id: "" },
    ]);
  };

  const handleRemoveField = (index) => {
    setFields(fields.filter((_, i) => i !== index));
  };

  const handleRemoveEmployeeField = (index) => {
    setEmployeeFields(employeeFields.filter((_, i) => i !== index));
  };

  // @ts-ignore
  const organizations = useSelector(
    (state: RootState) => state.organization.organization
  );

  const handleSelectChange = (event, selectName) => {
    const newValue = event;
    setSelectValues((prevState) => ({
      ...prevState,
      [selectName]: newValue,
    }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      axiosClient()
        .get("commission-model")
        .then((res) => {
          setOrganization(res?.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [isAuthenticated, isEmploy]);

  useEffect(() => {
    if (isAuthenticated) {
      if (organizations?.length === 0) {
        getOrganizations();
      }

      getTeams("ADMIN");
      getTeams("AFFILIATES");
      getTeams("EMPLOYEE");

      setIsActive(false);
    }
  }, [isActive]);

  useEffect(() => {
    setSelectValues({
      select1: selectedValue?.commission_model_id?.commission_model_id
        ? selectedValue?.commission_model_id?.commission_model_id
        : "",
    });
  }, [selectedValue]);

  return (
    <div>
      <Modal
        className={isEmploy ? "viewModal employ employed" : "viewModal admins"}
        centered
        open={isAdminOpen}
        width={isEmploy ? 800 : 400}
        style={{
          boxShadow: "0px 32px 64px -12px #10182824",
        }}
        footer={null}
        closable={false}
        wrapClassName="custom-modal"
      >
        {isEdit && (
          <div className="viewHeader">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  selectedValue?.profile_image
                    ? selectedValue?.profile_image
                    : NoPhoto
                }
                alt=""
                className="profilePic"
              />
              <h3>{selectedValue?.name}</h3>
            </div>
            <img
              src={Close}
              alt=""
              onClick={() => {
                setIsAdminOpen(false);
                setUserType("EMPLOYEE");
                setSelectValues({
                  select1: selectedValue?.commission_model_id?.commission_model_id
                    ? selectedValue?.commission_model_id?.commission_model_id
                    : "",
                });
                setFields([{ email: "", commission_model_id: "" }]);
                setEmployeeFields([{ email: "", commission_model_id: "" }]);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
        <Formik
          enableReinitialize={true}
          initialValues={{
            email1: employeeData?.email ? employeeData?.email : "",
            email2: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            if (isEmploy) {
              const emails = employeeFields.map((field) => field.email);
              const data = {
                inviting: "EMPLOYEE",
                email: [emails],
                commissionModels: employeeFields.map((field) => ({
                  email: field.email,
                  commission_model_id: parseInt(field.commission_model_id),
                })),
              };

              if (
                employeeFields.some(
                  (field) => !field.email || !field.commission_model_id
                )
              ) {
                toast.error(
                  "Please fill in all email and commission model fields for employees."
                );
                return;
              }

              setIsLoading(true);

              axiosClient()
                .post("users/send-invitation", data)
                .then((res) => {
                  if (res?.data?.success) {
                    res.data.successfulInvitationEmails.forEach(({ email }) => {
                      toast.success(`${email} invited successfully!`, {
                        autoClose: 3000,
                      });
                    });
                    res.data.alreadyExistingEmails.forEach(
                      ({ email, role }) => {
                        toast.warning(`${email} already present as ${role}!`, {
                          autoClose: 3000,
                        });
                      }
                    );
                    res.data.alreadyInvitedEmails.forEach(({ email, role }) => {
                      toast.warning(`${email} already invited as ${role}!`, {
                        autoClose: 3000,
                      });
                    });
                    res.data.failedInvitationEmails.forEach(({ email }) => {
                      toast.error(`Failed to send invitation to ${email}`, {
                        autoClose: 3000,
                      });
                    });
                    setIsAdminOpen(false);
                    resetForm();
                    setEmployeeFields([{ email: "", commission_model_id: "" }]);
                    refresh();
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                  toast.error(err.response?.data?.message);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else if (label === "Edit Employee") {
              setIsLoading(true);
              // console.log("SelectValue@: ",selectValues?.select1);
              const data = {
                email: values.email1,
                commission_model_id: parseInt(selectValues?.select1),
              };
              // console.log("Data to Edit: ",data);
              axiosClient()
                .patch(`users/${selectedValue?.user_id}`, data)
                .then((res) => {
                  if (res?.data) {
                    setIsActive(true);
                    toast.success("User updated successfully");
                    setIsAdminOpen(false);
                    resetForm();
                    getTeams("EMPLOYEE");
                    refresh();
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                  toast.error(err.response?.data?.message);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              const emails = fields.map((field) => field.email);
              const data = {
                inviting: "ADMIN",
                email: [emails],
              };

              if (emails.some((email) => email.trim() === "")) {
                toast.error(
                  "Please fill in all email fields before submitting."
                );
                return;
              }

              setIsLoading(true);

              axiosClient()
                .post("users/send-invitation", data)
                .then((res) => {
                  if (res?.data?.success) {
                    res.data.successfulInvitationEmails.forEach(({ email }) => {
                      toast.success(`${email} invited successfully!`, {
                        autoClose: 3000,
                      });
                    });
                    res.data.alreadyExistingEmails.forEach(
                      ({ email, role }) => {
                        toast.warning(`${email} already present as ${role}!`, {
                          autoClose: 3000,
                        });
                      }
                    );
                    res.data.alreadyInvitedEmails.forEach(({ email, role }) => {
                      toast.warning(`${email} already invited as ${role}!`, {
                        autoClose: 3000,
                      });
                    });
                    res.data.failedInvitationEmails.forEach(({ email }) => {
                      toast.error(`Failed to send invitation to ${email}`, {
                        autoClose: 3000,
                      });
                    });
                    setIsAdminOpen(false);
                    setSelectValues({
                      select1: selectedValue?.commission_model_id?.commission_model_id
                        ? selectedValue?.commission_model_id?.commission_model_id
                        : "",
                    });
                    resetForm();
                    setFields([{ email: "", commission_model_id: "" }]);
                    refresh();
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                  toast.error(err.response?.data?.message);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              {!isEdit && (
                <div className="flex justify-between items-center py-4  px-6 border-b">
                  <Text className="text-[#101828] text-[18px] font-semibold">
                    {label}
                  </Text>
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
                    alt=""
                    className="cursor-pointer"
                    onClick={() => {
                      setIsAdminOpen(false);
                      setUserType("EMPLOYEE");
                      setSelectValues({
                        select1: selectedValue?.commission_model_id?.commission_model_id
                          ? selectedValue?.commission_model_id?.commission_model_id
                          : "",
                      });
                      setFields([{ email: "", commission_model_id: "" }]);
                      setEmployeeFields([
                        { email: "", commission_model_id: "" },
                      ]);
                    }}
                  />
                </div>
              )}

              <div className="  flex gap-4 rowBox " style={{padding:"17px"}}>
                <div className={`w-full ${isEmploy && "w-[65%]"}`}>
                  <Col className="flex">
                    <Text className={`text-[rgb(52,64,84)] text-[14px] font-medium ${isEmploy ? "mr-[520px]" : ""}`}>
                      Email
                    </Text>
                    {isEmploy && (
                      <Text className="text-[#344054] text-[14px] font-medium" style={{position:"relative", right:"4px"}}>
                        Commission Models
                      </Text>
                    )}
                  </Col>
                  {isEdit && (
                    <>
                      <Field
                        type="text"
                        name="email1"
                        as={Input}
                        style={{
                          boxShadow: "0px 1px 2px 0px #1018280D",
                        }}
                        disabled={employeeData?.email ? true : false}
                        defaultValue={
                          employeeData?.email ? employeeData?.email : ""
                        }
                        placeholder=""
                        className={`w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-0 ${errors.email1 && touched.email1
                            ? "border-red-500"
                            : ""
                          }`}
                      />
                      {errors.email1 && touched.email1 && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.email1}
                        </div>
                      )}
                    </>
                  )}
                  {isEdit && (
                    <Row>
                      <Col md={12}>
                        <Col className="rowInput" style={{ marginLeft: "0 " }}>
                          <LabelInput
                            title="Created At"
                            value={
                              selectedValue?.created_at
                                ? moment(selectedValue?.created_at).format(
                                  "MMMM Do YYYY"
                                )
                                : "NA"
                            }
                            disabled
                          />
                        </Col>
                      </Col>

                      <Col md={12}>
                        <Col className="rowInput" style={{ marginRight: "0 " }}>
                          <LabelInput
                            title="Last Login"
                            value={
                              selectedValue?.token_created_at
                                ? moment(
                                  selectedValue?.token_created_at
                                ).format("MMMM Do YYYY")
                                : "NA"
                            }
                            disabled
                          />
                        </Col>
                      </Col>
                    </Row>
                  )}
                  {/* Invite Modals */}
                  {!isEdit && (
                    <>
                      {isEmploy
                        ? employeeFields.map((field, index) => (
                          <div
                            key={index}
                            className="mt-0 flex items-start employeBox"
                          >
                            <Input
                              type="email"
                              value={field.email}
                              onChange={(e) =>
                                handleEmployeeFieldChange(
                                  index,
                                  "email",
                                  e.target.value
                                )
                              }
                              style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                                width: "540px",
                              }}
                              // placeholder="Email"
                              className="w-full h-[44px] placeholder:text-[#667085] text-[#000000] rounded-[8px] mr-2"
                            />
                            <Select
                              value={field.commission_model_id}
                              onChange={(value) =>
                                handleEmployeeFieldChange(
                                  index,
                                  "commission_model_id",
                                  value
                                )
                              }
                              className="w-[200px] h-[44px] placeholder:text-[#101828] placeholder:font-medium text-[#667085] rounded-[8px]"
                              options={selectOptions}
                              placeholder="Commission Model"
                            />
                            {index > 0 && (
                              <DeleteOutlined
                                className="ml-2 cursor-pointer deletePoint"
                                onClick={() =>
                                  handleRemoveEmployeeField(index)
                                }
                              />
                            )}
                          </div>
                        ))
                        : fields.map((field, index) => (
                          <div key={index} className="mt-0 flex items-center">
                            <Input
                              type="email"
                              value={field.email}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "email",
                                  e.target.value
                                )
                              }
                              style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                              }}
                              placeholder=""
                              className="w-full h-[44px] placeholder:text-[#667085] text-[#000000] rounded-[8px]"
                            />
                            {index > 0 && (
                              <DeleteOutlined
                                className="ml-2 cursor-pointer"
                                onClick={() => handleRemoveField(index)}
                              />
                            )}
                          </div>
                        ))}
                    </>
                  )}

                  {isEdit && (
                    <>
                      <div className="mt-0">
                        <Text className="text-[#344054] text-[14px] pt-2 font-medium">
                          Commission Model
                        </Text>
                        <Select
                          value={selectValues.select1}
                          className="w-full h-[44px] mt-1 font-semibold placeholder:text-[#101828] placeholder:font-semibold text-[#667085] rounded-[8px]"
                          onChange={(e) => handleSelectChange(e, "select1")}
                          style={{ fontWeight: "bold" }}
                          options={selectOptions}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!isEdit && (
                <>
                  <div className="flex items-center gap-2 mt-0 pl-6 cursor-pointer" style={{
                    // borderBottom: "1px solid #EAECF0",
                    paddingBottom: "0px",
                  }}>
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/Icon%20(24).svg?updatedAt=1709724937099"
                    alt=""
                  />
                  <Text
                    onClick={
                      isEmploy ? handleAddEmployeeField : handleAddField
                    }
                    className="text-[#2970FF] text-[14px] font-medium"
                  >
                    Add more
                  </Text>
                </div>
            </>
          )}
          <div className="px-4 py-6 flex gap-3 items-center justify-between" style={{ marginTop:"0px"}}>
            <Button
              onClick={() => {
                setIsAdminOpen(false);
                setUserType("EMPLOYEE");
                setSelectValues({
                  select1: selectedValue?.commission_model_id?.commission_model_id
                    ? selectedValue?.commission_model_id?.commission_model_id
                    : "",
                });
                setFields([{ email: "", commission_model_id: "" }]);
                setEmployeeFields([{ email: "", commission_model_id: "" }]);
              }}
              className="w-full h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              icon=""
              
              disabled={isLoading}
              htmlType="submit"
              className="w-full h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
            >
              {isLoading ? (
                <Spin />
              ) : isEdit ? (
                "Save Changes"
              ) : (
                "Send invites"
              )}
            </Button>
          </div>
        </Form>
          )}
      </Formik>
    </Modal>
    </div >
  );
};

export default ModalSection;