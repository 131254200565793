import axiosClient from "../utils/axios.ts";

class Organization {
  async getAllOrganizations(values) {
    // const today = new Date();
    // const todayISOString = today.toISOString().split("T")[0];

    // const currentMonth = today.getMonth();

    // const oneMonthAgo = new Date(today);
    // oneMonthAgo.setMonth(currentMonth - 1);
    // const oneMonthAgoISOString = oneMonthAgo.toISOString().split("T")[0];
    try {
      const organization = await axiosClient().get(`pipedrive-organisations/all-referred`);

      return organization.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const organizationApi = new Organization();
