// @ts-nocheck

import React from "react";
import { Button } from "antd";
import { TEAM_TABS } from "../../constants/data.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
const Tabs = ({ itemIndex, setItemIndex }: any) => {
  const teams = useSelector((state: RootState) => state.teams);

  return (
    <div className=" border-b bg-white">
      <div className="container flex items-center h-[65px]">
        {TEAM_TABS?.map((item: any, index: number) => (
          <Button
            onClick={() => setItemIndex(index)}
            className={` h-[44px] shadow-none border-none p-[8px, 12px, 8px, 12px] rounded-[6px] font-semibold text-[16px] text-[#101828] flex items-center gap-2  ${
              itemIndex === index && "bg-[#EFF4FF]"
            }`}
          >
            {item?.label}
            {item?.isCount && item?.label === "Partners" && (
              <div className="rounded-[16px] bg-[#F2F4F7] px-2 lengths ">
                {teams?.affilliate?.length}
              </div>
            )}
            {item?.isCount && item?.label === "Super Partners" && (
              <div className="rounded-[16px] bg-[#F2F4F7] px-2 lengths">
                {teams?.superPartners?.length}
              </div>
            )}
            {item?.isCount && item?.label === "Mini Partners" && (
              <div className="rounded-[16px] bg-[#F2F4F7] px-2 lengths">
                {teams?.miniPartners?.length}
              </div>
            )}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
