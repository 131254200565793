/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Col, Row, Typography } from "antd";
import Form from "./form.tsx";
const Main = () => {
  const { Text } = Typography;
  return (
    <>
      <Row style={{background:"#fff"}}>
        <Col span={12}>
          <div className="h-full grid items-center w-full  py-4" style={{position:"relative"}}>
            <div className="">
              <Form />
              <div className="flex justify-between items-end px-16 pt-48 bottomText">
                <Text className="text-[#475467] font-sans">© Glovendor</Text>
                <Text className="text-[#475467] font-sans">
                info@glovendor.io
                </Text>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <img
            src="https://ik.imagekit.io/8extk8sjo/login%20(2).png?updatedAt=1709125439240"
            width={`100%`}
            className="2xl:h-[100vh] xxl:h-[100vh] h-full object-cover"
          />
        </Col>
      </Row>
    </>
  );
};

export default Main;
