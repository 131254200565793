


import React from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';
import FilledButton from '../../../../../../components/filledButton.tsx';

interface DateRangePickerProps {
    dateRange: { startDate: Date, endDate: Date }[];
    setDateRange: (range: { startDate: Date, endDate: Date }[]) => void;
    onApply: (range: { startDate: Date, endDate: Date }) => void;
}

const DateRangePickerComponent: React.FC<DateRangePickerProps> = ({ dateRange, setDateRange, onApply }) => {

    const handleSelect = (ranges: any) => {
        setDateRange([ranges.selection]);
    };
    
    const cancelDateRange = () => {
        setDateRange([{ startDate: new Date(), endDate: new Date() }]);
    };

    const applyDateRange = () => {
        onApply(dateRange[0]); // Pass the selected range back to the parent component
    };

    return (
        <>
            <DateRangePicker
                onChange={handleSelect}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={dateRange}
                direction="horizontal"
                locale={enGB}
            />
            <div className='flex flex-row-reverse'>
            
            <FilledButton onClick={applyDateRange} className="calendarApply">Apply</FilledButton>
            <FilledButton onClick={cancelDateRange} className="calendarCancel">Cancel</FilledButton>
               
            </div>
        </>
    );
};

export default DateRangePickerComponent;
