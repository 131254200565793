// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal, Typography, Input, Button, Spin } from "antd";

const EditModal: React.FC<{
  setIsEditOpen: (isOpen: boolean) => void;
  isEditOpen: boolean;
  selectedRowKeys: any;
  handleEditAmount: (keys: any, amount: string) => void;
  selectedObject: any;
}> = ({
  setIsEditOpen,
  isEditOpen,
  selectedRowKeys,
  handleEditAmount,
  selectedObject,
  isLoading,
}) => {
  const { Text } = Typography;

  const [amountNumber, setAmountNumber] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const amountNumberCleanedString = selectedObject?.commission_paid;
    const amountNumberParsed = parseFloat(amountNumberCleanedString);
    setAmountNumber(amountNumberParsed);
  }, [selectedObject]);

  const SearchIcon = () => {
    return <Text className="text-[#667085] text-[16px] font-normal">$</Text>;
  };

  const handleSaveAndApprove = () => {
    handleEditAmount(selectedRowKeys, `${amountNumber}`);
  };

  return (
    <Modal
      centered
      visible={isEditOpen}
      width={400}
      style={{
        boxShadow: "0px 32px 64px -12px #10182824",
      }}
      footer={null}
      closable={false}
      wrapClassName="custom-modal"
    >
      <div className="flex justify-between items-center py-4  px-6 border-b">
        <Text className="text-[#101828] text-[18px] font-semibold">
          Edit Amount
        </Text>
        <img
          src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
          alt=""
          className="cursor-pointer"
          onClick={() => setIsEditOpen(false)}
        />
      </div>
      <div className="py-5 px-6 border-b">
        <Text className="text-[#344054] text-[14px] font-medium">Amount</Text>
        <Input
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          type="number"
          placeholder="5"
          value={isNaN(amountNumber) ? "" : amountNumber}
          onChange={(e) => setAmountNumber(parseFloat(e.target.value))}
          prefix={<SearchIcon />}
          className="w-full h-[44px] placeholder:text-black text-[#667085] rounded-[8px] mt-2"
        />
      </div>
      <div className="px-4 py-6 flex gap-3 items-center justify-between">
        <Button
          onClick={() => setIsEditOpen(false)}
          size="large"
          className="w-full h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={handleSaveAndApprove}
          disabled={isLoading}
          className="w-full h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
        >
          {isLoading ? <Spin /> : "Save & Approve"}
        </Button>
      </div>
    </Modal>
  );
};

export default EditModal;
