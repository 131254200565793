import React from "react";
import { Typography } from "antd";

type itemProps = {
  label: string;
  count: number;
};

const Card = ({ label, count }: itemProps) => {
  const { Text } = Typography;
  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
      }}
      className="p-7 w-full rounded-[12px] bg-[#ffffff] border border-[#EAECF0] grid"
    >
      <Text className="text-[#475467] text-[16px] font-medium font-sans head">
        {label}{" "}
      </Text>
      <Text className="text-[#101828] text-[30px] font-semibold font-sans title">
        {count}
      </Text>
    </div>
  );
};

export default Card;
