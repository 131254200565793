import React, { useEffect, useState } from "react";
import { Modal, Typography, Button, Spin } from "antd";
import { toast } from "react-toastify";
import axiosClient from "../../utils/axios.ts";
import { useAuthContext } from "../../context/auth-context.tsx";
import useOpenOrganization from "../../hooks/use-organization.tsx";
import useOpenTeams from "../../hooks/use-teams.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
const RemoveModal: React.FC = ({
  isRemoveOpen,
  setIsRemoveOpen,
  selectedValue,
  employeeData,
  refresh,
}: any) => {
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const user_id = selectedValue?.user_id;
  const invite_id = selectedValue?.id;
  const { isAuthenticated } = useAuthContext();
  const { getOrganizations } = useOpenOrganization();
  const { getTeams } = useOpenTeams();
  const [isActive, setIsActive] = useState(false);

  // @ts-ignore
  const organization = useSelector(
    (state: RootState) => state.organization.organization
  );

  const handleRemove = () => {
    setIsLoading(true);
    axiosClient()
      .delete(`users/${user_id}`)
      .then((res) => {
        toast.success("User deleted successfully");
        setIsRemoveOpen(false);
        setIsActive(true);
        getOrganizations();
        refresh();
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRemoveInvite = () => {
    setIsLoading(true);
    axiosClient()
      .delete(`invites/${invite_id}`)
      .then((res) => {
        toast.success("Invite cancled successfully");
        setIsRemoveOpen(false);
        setIsActive(true);
        getOrganizations();
        refresh();
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (organization?.length === 0) {
        getOrganizations();
      }

      getTeams("ADMIN");
      getTeams("AFFILIATES");
      getTeams("EMPLOYEE");

      setIsActive(false);
    }
  }, [isAuthenticated, isActive]);

  return (
    <>
      <Modal
        centered
        open={isRemoveOpen}
        width={550}
        style={{
          boxShadow: "0px 32px 64px -12px #10182824",
        }}
        footer={null}
        closable={false}
        wrapClassName="custom-modal"
      >
        <div className="flex justify-between gap-2 items-start py-6  px-6 ">
          <img
            src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(3).svg?updatedAt=1709729512337"
            alt=""
          />
          <div className="grid">
            <Text className="text-[#101828] text-[18px] font-semibold">
              {user_id ? `Remove ${selectedValue?.name}?` : `Cancel invite for ${selectedValue?.email}`}
              {/* Remove {selectedValue?.name}? */}
            </Text>
            <Text className="text-[#475467] text-[14px] font-normal">
              {user_id ? "Are you sure you want to remove them? This action cannot be undone." : "Are you sure you want to cancel their invite? This action cannot be undone."}
            </Text>
          </div>
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
            alt=""
            className="cursor-pointer"
            onClick={() => setIsRemoveOpen(false)}
          />
        </div>

        <div className="px-6 py-6 flex gap-3 items-center justify-end ">
          <Button
            onClick={() => setIsRemoveOpen(false)}
            className="w-[120px] h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
          >
            Cancel
          </Button>
          <Button
            onClick={user_id ? handleRemove : handleRemoveInvite}
            type="none"
            icon=""
            disabled={isLoading}
            className={`w-[120px] h-[44px]  rounded-[8px] font-semibold text-[16px] text-[#fff] ${
              isLoading ? "bg-[#cacacad8]" : "bg-[#D92D20]"
            }`}
          >
            {isLoading ? <Spin wrapperClassName="text-white" /> : "Remove"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RemoveModal;
