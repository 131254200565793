//@ts-ignore
import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import Profile from "./profile.tsx";
import PasswordSection from "./password-section.tsx";
import Tabs from "./tabs.tsx";
import CommissionSection from "./commission-section.tsx";
import TableSection from "./table-section.tsx";
import RemoveModal from "./remove-modal.tsx";
import EditModal from "./edit-modal.tsx";
import IntegrationsSection from "./integrations-section.tsx";
import { SETTINGS_INTEGRATION } from "../../constants/data.ts";
import PipedriveSection from "./pipedrive-section.tsx";
import MercurySection from "./mercury-section.tsx";
import axiosClient from "../../utils/axios.ts";
const Main = () => {
  const { Text } = Typography;
  const [itemIndex, setItemIndex] = useState(0);
  const [isRemoveOpen, setIsRemoveOpen] = useState<any>(false);
  const [isEditOpen, setIsEditOpen] = useState<any>(false);
  const [commissions, setCommissions] = useState([]);
  const [commissionId, setCommissionId] = useState();
  const [commissionData, setCommissionData] = useState();
  const [isPipedrive, setIsPipedrive] = useState(false);
  const [isMercury, setIsMercury] = useState(false);
  const [isHit, setIsHit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [id, setId] = useState("");

  const getAllCommissionModels = () => {
    axiosClient()
      .get("commission-model")
      .then((res) => {
        setCommissions(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    setLoading(true);
    axiosClient()
      .get("pipedrive-key")
      .then((res) => {
        if (res?.data?.length > 0) {
          setIsHit(true);
          setApiKey(res?.data[0]?.key);
          setId(res?.data[0]?.pipedrive_key_id);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });

    getAllCommissionModels();
  }, []);

  useEffect(() => {
    if (itemIndex === 0 || itemIndex === 1 || itemIndex === 3) {
      setIsPipedrive(false);
      setIsMercury(false);
    }
  }, [itemIndex]);

  return (
    <div className=" pb-20" style={{ paddingTop: "70px" }}>
      <Tabs setItemIndex={setItemIndex} itemIndex={itemIndex} />

      <div className="container py-12">
        {!isPipedrive && (
          <>
            <Row>
              <Col span={6}>
                <div className="grid">
                  <Text className="text-[#101828] text-[18px] font-semibold font-sans">
                    {(() => {
                      switch (itemIndex) {
                        case 0:
                          return "Profile";
                        case 1:
                          return "Password";
                        case 2:
                          return "Integrations";
                        // case 3:
                        // return "Program Settings";
                        default:
                          return "";
                      }
                    })()}
                  </Text>
                  <Text className="text-[#475467] text-[14px]">
                    {(() => {
                      switch (itemIndex) {
                        case 0:
                          return " Update your photo and personal details here.";
                        case 1:
                          return "Change your password by filling the details";
                        case 2:
                          return "Update your affiliate settings";
                        // case 3:
                        // return "Update your affiliate settings";
                        default:
                          return "";
                      }
                    })()}
                  </Text>
                </div>
              </Col>
              <Col span={18}>
                {itemIndex === 3 ? (
                  ""
                ) : (
                  <div
                    style={
                      itemIndex === 2 || itemIndex === 3
                        ? null
                        : {
                            boxShadow:
                              "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
                            background: "white",
                          }
                    }
                    className={`w-full p-5 rounded-[12px] ${
                      itemIndex === 2 &&
                      "bg-transparent border-none shadow-none rounded-none"
                    }`}
                  >
                    {(() => {
                      switch (itemIndex) {
                        case 0:
                          return <Profile />;
                        case 1:
                          return <PasswordSection />;
                        case 2:
                          return (
                            <div style={{
                              boxShadow:
                                "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
                            }}
                            className="w-full p-5 bg-white rounded-[12px] text-center align-middle text-[18px]">Coming Soon!</div>
                            // <IntegrationsSection
                            //   data={SETTINGS_INTEGRATION}
                            //   setIsPipedrive={setIsPipedrive}
                            //   isPipedrive={isPipedrive}
                            //   isHit={isHit}
                            //   setIsMercury={setIsMercury}
                            //   isMercury={isMercury}
                            //   setApiKey={setApiKey}
                            //   apiKey={apiKey}
                            // />
                          );
                        case 3:
                          // return <CommissionSection />;
                          return null;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                )}
              </Col>
            </Row>
          </>
        )}

        {isPipedrive && (
          <>
            <PipedriveSection
              setIsHit={setIsHit}
              isHit={isHit}
              setLoading={setLoading}
              isLoading={isLoading}
              setApiKey={setApiKey}
              apiKey={apiKey}
              id={id}
            />
          </>
        )}

        {/* {isMercury && (
          <>
            <MercurySection />
          </>
        )} */}

        {itemIndex === 3 && (
          <>
            {/* Changed classname from mt-7 to mt-0 in order to reduce the size when removing application settings */}
            <Row className="mt-0">
              <Col span={6}>
                <div className="grid">
                  <Text className="text-[#101828] text-[18px] font-semibold">
                    Commission Models
                  </Text>
                  <Text className="text-[#475467] text-[14px]">
                    Update your affiliate settings
                  </Text>
                </div>
              </Col>
              <Col span={18}>
                <div
                  style={{
                    boxShadow:
                      "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
                  }}
                  className="w-full p-5 bg-white rounded-[12px]"
                >
                  <TableSection
                    commissions={commissions}
                    refresh={getAllCommissionModels}
                    setIsRemoveOpen={setIsRemoveOpen}
                    setIsEditOpen={setIsEditOpen}
                    setCommissionId={setCommissionId}
                    setCommissionData={setCommissionData}
                  />
                </div>
              </Col>
            </Row>
            <RemoveModal
              isRemoveOpen={isRemoveOpen}
              setIsRemoveOpen={setIsRemoveOpen}
              commissionData={commissionData}
              commissionId={commissionId}
              setCommissionData={setCommissionData}
              refresh={getAllCommissionModels}
            />
            <EditModal
              setIsEditOpen={setIsEditOpen}
              isEditOpen={isEditOpen}
              commissionData={commissionData}
              setCommissionData={setCommissionData}
              refresh={getAllCommissionModels}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Main;
