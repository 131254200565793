import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";

import { Spin, Modal, Table as AntTable } from "antd"; // Import Ant Design Table
import TableApp from "./payoutTable.tsx";
import TableFilterComponent from "./filters/index.tsx";



const Reviewed = ({data,refresh, updateFilters}) => {
  // console.log("Data in Reviewed: ",data);
  const loading = useSelector((state: RootState) => state.isLoading.isLoading);

  return (
    <>
      <div>
        <h1 className="eventHeading">{data?.length ? data?.length : 0} Events</h1>
      <TableFilterComponent updateFilters={updateFilters} />
        {loading ? (
          <div className="flex items-center justify-center my-10">
            <Spin fullscreen size="large" />
          </div>
        ) : (
          <div className="">
            <TableApp data={data}/>
          </div>
        )}
      </div>
    </>
  );
};

export default Reviewed;
