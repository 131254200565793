import React from "react";
import { Form, Input, Typography } from "antd";

type inputProps = {
  label?: string;
  name?: string;
  required?: boolean;
  placeholder: string;
  type?: string;
};

const InputField = ({
  label,
  name,
  required,
  placeholder,
  type,
}: inputProps) => {
  const { Title } = Typography;
  return (
    <>
      <Title
        level={5}
        style={{
          color: "#344054",
        }}
      >
        {label}
      </Title>
      <Form.Item
        name={name}
        rules={[{ required: required }]}
        style={{
          boxShadow: "0px 1px 2px 0px #1018280D",
        }}
      >
        <Input
          type={type}
          placeholder={placeholder}
          style={{
            border: "1.5px solid #D0D5DD",
            height: "44px",
          }}
          className="placeholder:text-[#667085] bg-red-700 bg-transparent placeholder:text-[16px] rounded-[8px]"
        />
      </Form.Item>
    </>
  );
};

export default InputField;
