// @ts-nocheck

import { createSlice } from "@reduxjs/toolkit";

const initialState: CounterState = {
  payouts: [],
};

export const counterSlice = createSlice({
  name: "payouts",
  initialState,
  reducers: {
    addOpenPayouts: (state, action) => {
      state.payouts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addOpenPayouts } = counterSlice.actions;

export default counterSlice.reducer;
