"use client";
import React, { useState, useEffect } from "react";
import { ProfileSection } from "../../components/index.ts";
import { Col, Row, DatePicker, Button, Typography, Spin } from "antd";
import Card from "./card.tsx";
import { DASHBOARD_BTNS } from "../../constants/data.ts";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import type { TimeRangePickerProps } from "antd";
import { useAuthContext } from "../../context/auth-context.tsx";
import { useSelector } from "react-redux";
import axiosClient from "../../utils/axios.ts";
import { CloseOutlined } from "@ant-design/icons";
import useOpenStats from "../../hooks/use-stats.tsx";
import { RootState } from "../../redux/store.ts";
import LineChart from "./lineChart.tsx";
import BarChart from "./areaGraph.tsx";

const Main = () => {
  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const [activeTab, setActiveTab] = useState(0);
  const [dateShow, setDateShow] = useState(false);
  const [graphData, setGraphData] = useState([]); //For Company Graph
  const [graph1Data, setGraph1Data] = useState<any>({}); //For Super and Mini Partner Graph
  const [isGraphLoading, setisGraphLoading] = useState(true);
  const [isGraph1Loading, setisGraph1Loading] = useState(true);
  const [year, setYear] = useState(dayjs(new Date())); //For Company Graph
  const [year1, setYear1] = useState(dayjs(new Date())); //For Super and Mini Partner Graph

  interface Values {
    from: any;
    to: any;
  }
  const { user } = useAuthContext();
  const loading = useSelector((state: RootState) => state.isLoading.isLoading);

  const initialState: Values = { from: "", to: "" };

  const [values, setValues] = useState<Values | undefined>(initialState);
  const Customimage = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(18).svg?updatedAt=1709195817408"
        alt=""
      />
    );
  };

  const activity = useSelector((state: RootState) => state.activity.activity);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setValues({
        from: dateStrings[0],
        to: dateStrings[1],
      });
    } else {
      console.log("Clear");
    }
  };
  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
    {
      label: "Yesterday",
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    { label: "All Time", value: [dayjs(0), dayjs()] }, // Assuming "sAll Time" means from the Unix epoch (January 1, 1970)
  ];

  const stats = useSelector((state: RootState) => state.stats.stats);
  const { getStats } = useOpenStats();

  const TOTAL_CARDS = [
    {
      label: "Total Partners",
      counts: stats?.totalAffiliatedUsers ? stats?.totalAffiliatedUsers : 0,
    },
    {
      label: "Companies Referred",
      counts: stats?.totalCompaniesReferred ? stats?.totalCompaniesReferred : 0,
    },
    {
      label: "Mini Partners",
      counts: stats?.totalMiniPartners ? stats?.totalMiniPartners : 0,
    },
    {
      label: "Super Partners",
      counts: stats?.totalSuperPartners ? stats?.totalSuperPartners : 0,
    },
  ];

  const TOTAL_CARDS2 = [
    {
      label: "Total Revenue",
      counts: stats?.totalAggregateRevenue
        ? stats?.totalAggregateRevenue / 1000 == 0
          ? "0"
          : `${(stats?.totalAggregateRevenue / 1000).toFixed(3)}K`
        : "0",
    },
    {
      label: "Comission Paid",
      counts: stats?.totalCommission
        ? stats.totalCommission / 1000 == 0
          ? "0"
          : `${(stats.totalCommission / 1000).toFixed(3)}K` //.toFixed(2)
        : "0",
    },
    {
      label: "Companies Converted",
      counts: stats?.totalCompaniesConverted
        ? stats?.totalCompaniesConverted
        : 0,
    },
    {
      label: "Unpaid Commission",
      counts: stats?.totalUnpaid
        ? stats.totalUnpaid / 1000 == 0
          ? "0"
          : `${(stats.totalUnpaid / 1000).toFixed(3)}K` //.toFixed(2)
        : "0",
    },
    {
      label: "Commission/Revenue",
      counts: `${
        stats?.commissionPercentage ? stats?.commissionPercentage.toFixed(2) : 0
      }%`,
    },
  ];

  useEffect(() => {
    setisGraphLoading(true);
    axiosClient()
      .get(
        `/pipedrive-organisations/admin-company-graph?${
          year ? "Year=" + year["$y"] : ""
        }`
      )
      .then((res) => {
        setGraphData(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setisGraphLoading(false));
  }, [year]);

  useEffect(() => {
    setisGraph1Loading(true);
    axiosClient()
      .get(
        `/users-commissions/admin/super-mini-commissions?${
          year1 ? "year=" + year1["$y"] : ""
        }`
      )
      .then((res) => {
        setGraph1Data(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setisGraph1Loading(false));
  }, [year1]);

  return (
    <>
      <div className="container pt-10 " style={{ paddingTop: "100px" }}>
        <div className="grid">
          <Text className="text-[#101828] text-[30px] font-semibold font-sans">
            Welcome back,{" "}
            <span className="capitalize">{user ? user?.name : <Spin />}</span>
          </Text>
          <Text className="text-[#475467] text-[16px] font-sans">
            Your current summary and activity.
          </Text>
        </div>

        {loading ? (
          <div className="flex items-center justify-center my-10">
            <Spin fullscreen size="large" />
          </div>
        ) : (
          <>
            <Row gutter={30}>
              {TOTAL_CARDS?.map((item: any, i) => (
                <Col key={i} span={6} className="mt-10 mb-6 dashBoardCard">
                  <Card label={item?.label} count={item?.counts} />
                </Col>
              ))}
            </Row>

            <Row>
              <Col span={24} className="mt-8 mb-6 overflow-hidden">
                <div className="lineGraph">
                  <Col className="generateTitle">
                    <div>
                      <h3>Revenue generated</h3>
                      <h1>{`$${
                        stats?.yearlyRevenue
                          ? stats.yearlyRevenue / 1000 == 0
                            ? "0"
                            : `${(stats.yearlyRevenue / 1000).toFixed(3)}K`
                          : "0"
                      }`}</h1>
                      {/* <h1>{`${stats?.totalAggregateRevenue
                      ? stats.totalAggregateRevenue / 1000 == 0
                        ? "0"
                        : `${(stats.totalAggregateRevenue / 1000).toFixed(3)}K`
                      : "0"}`}</h1> */}
                    </div>
                    <div className="w-[5.4rem]">
                      <DatePicker
                        className="dateYear"
                        picker="year"
                        minDate={dayjs(new Date(2022, 0, 1))}
                        maxDate={dayjs(new Date())}
                        // value={dayjs(new Date())}
                        value={year}
                        onChange={(e) => setYear(e)}
                        allowClear={false}
                        disabled={isGraphLoading}
                      />
                    </div>
                  </Col>
                  {!isGraphLoading ? (
                    <LineChart stats={stats} data={graphData} />
                  ) : (
                    <div className="flex items-center justify-center py-[10.8rem]">
                      <Spin />
                    </div>
                  )}
                  {/* <LineChart stats={stats} data={graphData} /> */}
                </div>
              </Col>
            </Row>

            <Row gutter={30}>
              <Col className=" cardLabels">
                {TOTAL_CARDS2?.map((item: any, i) => (
                  <Col key={i} className="mt-8 mb-6 innerLabel">
                    <Card label={item?.label} count={item?.counts} />
                  </Col>
                ))}
              </Col>
            </Row>
            <Row>
              <Col className="barGraph">
                <Row>
                  <Col md={22}>
                    <Col className="barHeading">
                      <Col style={{ marginRight: "90px" }}>
                        <h4>Super Partner Commission</h4>
                        <h6>
                          {graph1Data?.totalSuperCommission
                            ? `$${graph1Data?.totalSuperCommission?.toFixed(3)}K`
                            : 0}
                        </h6>
                      </Col>
                      <Col>
                        <h4>Mini Partner Commission</h4>
                        <h6>
                          {graph1Data?.totalMiniCommission
                            ? `$${graph1Data?.totalMiniCommission?.toFixed(3)}K`
                            : 0}
                        </h6>
                      </Col>
                    </Col>
                  </Col>
                  <Col className="w-[5.4rem]">
                    <Col>
                      <DatePicker
                        picker="year"
                        minDate={dayjs("2022-01-01")}
                        maxDate={dayjs().endOf("year")}
                        value={year1}
                        onChange={(e) => setYear1(e)}
                        allowClear={false}
                        disabled={isGraph1Loading}
                      />
                    </Col>
                  </Col>
                </Row>
                {/* <BarChart /> */}
                {!isGraph1Loading ? (
                  <BarChart data={graph1Data?.graphData} />
                ) : (
                  <div className="flex items-center justify-center py-[11.5rem]">
                    <Spin />
                  </div>
                )}
              </Col>
            </Row>
            <Row className="my-8 px-6">
              <Col span={24} className="border-b border-[#EAECF0] pb-4">
                <Text className="text-[#101828] text-[18px] font-semibold">
                  Recent Activity
                </Text>
              </Col>
            </Row>
            <Row className="my-8 px-6">
              {activity?.map((item: any, i) => (
                <Col key={i} span={12} className="mb-6">
                  <ProfileSection
                    icon={item?.user?.profile_image}
                    heading={item?.AffiliateName}
                    name={item?.Name}
                    date={item?.add_time}
                  />
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Main;
