// @ts-nocheck
import { Box, Grid } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import User from "../../../../assets/images/user.png";
import BigUser from "../../../../assets/images/biglogo.png";
import Pencil from "../../../../assets/images/pencil.png";
import Touch from "../../../../assets/images/touch.png";
import Cards from "../../../../assets/images/cards.png";
import Leave from "../../../../assets/images/leaves.png";
import Coin from "../../../../assets/images/coins.png";
import Office from "../../../../assets/images/office.png";
import Bank from "../../../../assets/images/bank.png";
import Discount from "../../../../assets/images/discount.png";
import Dollar from "../../../../assets/images/dollar.png";
import UserAdd from "../../../../assets/images/useradd.png";
// import LabelInput from "../../../../components/labelInput.tsx";
import LabelInput from "../../../../components/commissionLabelInput.tsx";
import FilledButton from "../../../../components/filledButton.tsx";
import FilterComponent from "../filters/index.tsx";
import { toast } from "react-toastify";
import axiosClient from "../../../../utils/axios.ts";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Spin } from "antd";

// export default function SuperPartnerApproval({ data, refresh, isLoading }) {
export default function SuperPartnerApproval({ data, refresh }) {
  const [events, setEvents] = useState(data || []);
  const [filters, setFilters] = useState({
    minAmount: null,
    maxAmount: null,
    companyName: null,
    affiliate: null,
    revenueType: null,
    dateRange: null,
  });

  const [openEvent, setOpenEvent] = useState(events[0] || null);
  const [commission, setCommission] = useState("0.00");
  const [revenueAmount, setRevenueAmount] = useState("0.00");
  const [commissionAmount, setCommissionAmount] = useState("0.00");
  const [commissionPercentage, setCommissionPercentage] = useState(() => {
    return openEvent ? openEvent.percentage : 0;
  });
  const [isExceeding, setIsExceeding] = useState(false);
  const [isExceeding1, setIsExceeding1] = useState(false);
  const [processingTransactions, setProcessingTransactions] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Load processing state from localStorage on component mount
    const storedState = localStorage.getItem("processingTransactions");
    if (storedState) {
      setProcessingTransactions(JSON.parse(storedState));
    }
  }, []);

  useEffect(() => {
    // Save processing state to localStorage whenever it changes
    localStorage.setItem(
      "processingTransactions",
      JSON.stringify(processingTransactions)
    );
  }, [processingTransactions]);

  const isTransactionProcessing = (transactionId) => {
    return (
      processingTransactions[transactionId] &&
      Date.now() - processingTransactions[transactionId] < 60000
    ); // 1 minute timeout
  };

  const setTransactionProcessing = (transactionId) => {
    setProcessingTransactions((prev) => ({
      ...prev,
      [transactionId]: Date.now(),
    }));
  };

  const clearTransactionProcessing = (transactionId) => {
    setProcessingTransactions((prev) => {
      const newState = { ...prev };
      delete newState[transactionId];
      return newState;
    });
  };

  useEffect(() => {
    const filteredEvents = data.filter((event) => {
      return (
        (!filters.minAmount || event.fee >= filters.minAmount) &&
        (!filters.maxAmount || event.fee <= filters.maxAmount) &&
        (!filters.companyName ||
          event.organizationName === filters.companyName) &&
        (!filters.affiliate || event.superPartnerName === filters.affiliate) &&
        (!filters.revenueType || event.feeType === filters.revenueType) &&
        (!filters.dateRange ||
          (new Date(event.date) >= filters.dateRange.startDate &&
            new Date(event.date) <= filters.dateRange.endDate))
      );
    });
    setEvents(filteredEvents);

    // Update openEvent and related states when filters change
    if (filteredEvents.length > 0) {
      const newOpenEvent = filteredEvents[0];
      handleItemClick(filteredEvents[0]);
      setOpenEvent(newOpenEvent);
      setRevenueAmount(newOpenEvent?.fee); //?.toFixed(2));
      const newCommission =
        (newOpenEvent?.fee * newOpenEvent?.percentage) / 100;
      setCommission(newCommission?.toFixed(2));
      setCommissionAmount(newCommission?.toFixed(2));
    } else {
      setOpenEvent(null);
      setRevenueAmount("0.00");
      setCommission("0.00");
      setCommissionAmount("0.00");
    }
  }, [data, filters]);

  //Commenting to fix Data Race and Filter Loss
  // useEffect(() => {
  //   setEvents(data);

  //   if (data.length > events.length) {
  //     const newEvent = data.find(
  //       (newItem) =>
  //         !events.some(
  //           (oldItem) => oldItem.transactionId === newItem.transactionId
  //         )
  //     );
  //     if (newEvent) {
  //       handleItemClick(newEvent);
  //     }
  //   }
  // }, [data]);

  const handleItemClick = (item) => {
    setOpenEvent(item);
    setRevenueAmount(item.fee); //.toFixed(2));
    setCommissionPercentage(item.percentage);
    const newCommission = (item.fee * item.percentage) / 100;
    setCommission(newCommission.toFixed(2));
    setCommissionAmount(newCommission.toFixed(2));
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleRevenueAmountChange = (e) => {
    const newRevenueAmount = e.target.value;
    setRevenueAmount(newRevenueAmount);

    if (openEvent) {
      const newCommissionAmount =
        (newRevenueAmount * openEvent.percentage) / 100;
      setCommissionAmount(newCommissionAmount.toFixed(2));
      setCommission(newCommissionAmount.toFixed(2));
    }
  };

  const handleCommissionPercentageChange = (e) => {
    // const newPercentage = parseFloat(e.target.value);
    const newPercentage = e.target.value;
    setCommissionPercentage(newPercentage);

    if (openEvent) {
      const newCommissionAmount = (revenueAmount * newPercentage) / 100;
      setCommissionAmount(newCommissionAmount.toFixed(2));
      setCommission(newCommissionAmount.toFixed(2));
    }
  };

  useEffect(() => {
    if (parseFloat(revenueAmount) > parseFloat(openEvent?.fee)) {
      setIsExceeding(true);
    } else {
      setIsExceeding(false);
    }
  }, [commissionAmount, revenueAmount]);

  useEffect(() => {
    if (parseFloat(commissionPercentage) > parseFloat(openEvent?.percentage)) {
      setIsExceeding1(true);
    } else {
      setIsExceeding1(false);
    }
  }, [commissionAmount, commissionPercentage]);

  const cardDetails = openEvent
    ? [
        {
          img: Touch,
          title: "Partner ID",
          value: `${openEvent?.superPartnerId}`,
        },
        {
          img: Leave,
          title: "Mini Partner Name",
          value: `${
            openEvent?.miniPartnerName ? openEvent?.miniPartnerName : ""
          }`,
        },
        {
          img: Cards,
          title: "Commision Type",
          value: (openEvent?.isDirectEvent ? "Partner" : "Mini Partner") || "",
        },
        { img: Coin, title: "Revenue Event", value: `${openEvent?.feeType}` },
        {
          img: Office,
          title: "Company Name",
          value: openEvent.organizationName,
        },
        { img: Bank, title: "Commission Amount", value: `${commission} USD` },
        {
          img: Discount,
          title: "Commission %",
          value: `${openEvent?.percentage}%`,
        },
      ]
    : [];

  const resetForm = () => {
    if (events.length > 1) {
      const nextEvent = events.find(
        (event) => event.transactionId !== openEvent.transactionId
      );
      if (nextEvent) {
        handleItemClick(nextEvent);
      }
    } else {
      setOpenEvent(null);
      setRevenueAmount("0.00");
      setCommission("0.00");
      setCommissionAmount("0.00");
    }
  };

  const updateTransaction = (ID, body) => {
    return axiosClient()
      .patch(`transactions/${ID}`, body)
      .then((res) => {
        toast.success(res?.data.message);
        refresh();
        return res.data;
      })
      .catch((error) => {
        console.error(error.message);
        toast.error(error.message);
        throw error;
      });
  };

  const updateSuperTransaction = (body) => {
    // console.log("Body in Super: ", body);
    return axiosClient()
      .patch(`transactions/super-partner`, body)
      .then((res) => {
        toast.success(res?.data.message);
        refresh();
        return res.data;
      })
      .catch((error) => {
        console.error(error.message);
        toast.error(error.message);
        throw error;
      });
  };
  // console.log("Open Event: ", openEvent);
  const archiveHandler = () => {
    if (!openEvent || isTransactionProcessing(openEvent.transactionId)) return;

    setTransactionProcessing(openEvent.transactionId);
    setIsLoading(true);
    const ID = +openEvent?.transactionId;
    const isDirectEvent = +openEvent?.isDirectEvent;
    if (isDirectEvent) {
      updateTransaction(ID, {
        field: "is_archived",
        value: true,
      })
        .then(() => {
          resetForm();
        })
        .catch((err) => {
          console.error(err.message);
          toast.error(err.message);
        })
        .finally(() => {
          console.log("#2")
          clearTransactionProcessing(openEvent.transactionId);
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        });
    } else {
      const superPartnerId = +openEvent?.superPartnerId;
      const field = "is_archived";
      const value = true;

      updateSuperTransaction({
        transactionId: ID,
        superPartnerId,
        field,
        value,
      })
        .then(() => {
          resetForm();
        })
        .catch((err) => {
          console.error(err.message);
          toast.error(err.message);
        })
        .finally(() => {
          console.log("#1");
          clearTransactionProcessing(openEvent.transactionId);
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        });
    }
  };

  const selectOrganization = useMemo(() => {
    return createSelector(
      [(state) => state.organization.organization, (_, orgId) => orgId],
      (organizations, orgId) => {
        return organizations.find((org) => org.ID === orgId);
      }
    );
  }, []);

  const selectedOrganization = useSelector((state) =>
    selectOrganization(state, openEvent ? +openEvent?.organizationId : null)
  );

  const getOrganizationTableId = async () => {
    try {
      if (selectedOrganization) {
        return {
          tableId: selectedOrganization?.table_id,
          status: selectedOrganization?.Status,
        };
      } else {
        const response = await axiosClient().get(
          `pipedrive-organisations/find-by-id/${+openEvent?.organizationId}`
        );
        const org = response.data;
        // console.log("Org: ", org);
        return {
          tableId: org?.table_id,
          status: org?.Status,
        };
      }
    } catch (error) {
      console.error("Error fetching organization data:", error);
      throw error;
    }
  };

  const submitHandler = async () => {
    if (!openEvent || isTransactionProcessing(openEvent.transactionId)) return;

    setTransactionProcessing(openEvent.transactionId);
    setIsLoading(true);

    try {
      const { tableId, status } = await getOrganizationTableId();

      const payload = {
        commission: +commissionPercentage,
        user_id: +openEvent?.superPartnerId,
        pipedrive_org_id: tableId,
        commission_paid: +commissionAmount,
        total_revenue: +revenueAmount,
        last_revenue: 0,
        revenue_event: openEvent?.feeType,
        is_approved: false,
        status: status,
        payment_status: "Unpaid",
        created_at: new Date(openEvent?.date),
        is_super_commission: openEvent?.isDirectEvent ? false : true,
      };

      // console.log("Payload: ", payload);

      const response = await axiosClient().post("users-commissions", payload);

      const ID = +openEvent?.transactionId;
      const isDirectEvent = +openEvent?.isDirectEvent;
      if (isDirectEvent) {
        await updateTransaction(ID, {
          field: "is_approved",
          value: true,
        });
      } else {
        const superPartnerId = +openEvent?.superPartnerId;
        const field = "is_approved";
        const value = true;

        updateSuperTransaction({
          transactionId: ID,
          superPartnerId,
          field,
          value,
        });
      }

      resetForm();
      // toast.success("Transaction submitted successfully");
    } catch (error) {
      toast.error(error.data.message || "An error occurred during submission");
      console.error("Error in submitHandler:", error);
    } finally {
      clearTransactionProcessing(openEvent.transactionId);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <>
      {/* {isLoading ? (
        <div className="flex items-center justify-center py-[11.5rem]">
          <Spin />
        </div>
      ) : ( */}
      <Box>
        <h3 className="totalEvents">{events.length} Events</h3>
        <FilterComponent
          data={data}
          onFilterChange={handleFilterChange}
          refresh={refresh}
          isSuper={true}
          handleItemClick={handleItemClick}
        />
        <Grid container spacing={1} className="gridSide">
          <Grid item xs={3} paddingRight={"15px"} className="mobileFit">
            {events.map((item, index) => (
              <Box
                className={
                  item.transactionId === openEvent?.transactionId &&
                  item.superPartnerId === openEvent?.superPartnerId
                    ? "sideMenu-active"
                    : "sideMenu"
                }
                onClick={() => handleItemClick(item)}
                key={index}
              >
                <Box
                  className="userName"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img src={Dollar} alt="" />
                  <Box>
                    <h6>{item?.organizationName}</h6>
                    <h5>{item?.superPartnerName}</h5>
                  </Box>
                </Box>
                <Box className="userName last">
                  <h6>
                    {item?.date
                      ? moment(item?.date).format("DD/MM/YYYY")
                      : "0/0/0000"}
                  </h6>
                  {/* <h5 style={{ fontWeight: "400" }}>${item?.fee.toFixed(2)}</h5> */}
                  <h5 style={{ fontWeight: "400" }}>${item?.fee}</h5>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={9} className="mobileFits">
            {openEvent ? (
              <Box className="rightCard">
                <Box className="rightHeader">
                  <Box className="leftSide">
                    <img src={BigUser} alt="" />
                    <Box>
                      <span className="spartnerBadge">Super Partner</span>
                      <h4>{openEvent?.superPartnerName}</h4>
                    </Box>
                  </Box>
                  <Box className="rightSide">
                    <h4>
                      {openEvent?.date
                        ? moment(openEvent?.date).format("DD/MM/YYYY")
                        : "0/0/0000"}
                    </h4>
                    {/* <h6>{openEvent?.fee.toFixed(2)} USD</h6> */}
                    <h6>{openEvent?.fee} USD</h6>
                  </Box>
                </Box>
                <Box className="cardBody">
                  <Box className="gridSection">
                    {cardDetails.map((detail, index) => (
                      <Box className="cardDetail" key={index}>
                        <img src={detail.img} alt="" />
                        <h5>{detail.title}</h5>
                        <h6>{detail.value}</h6>
                      </Box>
                    ))}
                  </Box>
                  <Grid container style={{ borderBottom: "1px solid #EAECF0" }}>
                    <Grid xs={5.9}>
                      <Box
                        className="revenueInput"
                        sx={{ position: "relative", borderBottom: "0" }}
                      >
                        <LabelInput
                          title="Revenue Amount"
                          value={revenueAmount}
                          onChange={handleRevenueAmountChange}
                          type="number"
                        />
                        <img src={Pencil} alt="" />
                        {isExceeding ? (
                          <text className="text-red-400 text-sm">
                            Exceeding the real Revenue Amount
                          </text>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                    <Grid xs={0.2}></Grid>
                    <Grid xs={5.9}>
                      <Box
                        className="revenueInput"
                        sx={{ position: "relative", borderBottom: "0" }}
                      >
                        <LabelInput
                          title="Commission %"
                          type="number"
                          value={commissionPercentage}
                          onChange={handleCommissionPercentageChange}
                        />
                        <img src={Pencil} alt="" />
                      </Box>
                      {isExceeding1 ? (
                        <text className="text-red-400 text-sm">
                          Exceeding the real Commission Percentage
                        </text>
                      ) : (
                        ""
                      )}
                    </Grid>

                    {/* <Grid xs={12}>
                      <Box className="revenueInput" sx={{position:"relative"}}>
                        <LabelInput
                          title="Super Partner %"
                          
                          type="number"
                        />
                        <img src={Pencil} alt="" />
                      </Box>
                    </Grid> */}
                  </Grid>
                  {/* <Box className="submitButton">
                    <FilledButton
                      disabled={isExceeding || isExceeding1}
                      onClick={archiveHandler}
                    >
                      Archive
                    </FilledButton>
                    <FilledButton
                      disabled={isExceeding || isExceeding1}
                      style={{ background: "#000", color: "#fff" }}
                      onClick={submitHandler}
                    >
                      Submit
                    </FilledButton>
                  </Box> */}
                                    <Box className="submitButton">
                    <FilledButton
                      disabled={
                        isExceeding ||
                        isExceeding1 ||
                        !openEvent ||
                        isTransactionProcessing(openEvent?.transactionId)
                      }
                      onClick={archiveHandler}
                    >
                      {isTransactionProcessing(openEvent?.transactionId)
                        ? "Processing..."
                        : "Archive"}
                    </FilledButton>
                    <FilledButton
                      style={{ background: "#000", color: "#fff" }}
                      disabled={
                        isExceeding ||
                        isExceeding1 ||
                        !openEvent ||
                        isTransactionProcessing(openEvent?.transactionId)
                      }
                      onClick={submitHandler}
                    >
                      {isTransactionProcessing(openEvent?.transactionId)
                        ? "Processing..."
                        : "Submit"}
                    </FilledButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="rightCard">
                <p>No events to display</p>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* )} */}
      {isLoading && <Spin fullscreen />}
    </>
  );
}
