import { configureStore } from "@reduxjs/toolkit";
import organization from "./organization.ts";
import teams from "./teams.ts";
import commission from "./commission.ts";
import isLoading from "./loading.ts";
import stats from "./stats.ts";
import payouts from "./payouts.ts";
import activity from "./activity.ts";

export const store = configureStore({
  reducer: {
    organization,
    isLoading,
    teams,
    commission,
    stats,
    payouts,
    activity,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
