import React from "react";
import Header from "../../layout/header/index.tsx";
import Main from "../../views/companies/main.tsx";
const Companies = () => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

export default Companies;
