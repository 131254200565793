"use client";
// @ts-ignore
import React, { useEffect, useState } from "react";
import { Input, DatePicker, Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useOpenOrganization from "../../hooks/use-organization.tsx";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import type { TimeRangePickerProps, Dayjs } from "antd";
import { RootState } from "@reduxjs/toolkit/query";
import moment from "moment";

const SearchSection = ({
  isFilterOpen,
  setIsFfilterOpen,
  setPayoutData,
  pyoutData,
  data,
  setValues,
  setDateShow,
  dateShow,
  values,
  selectedFilters,
  checkboxValues,
  setCheckboxValues,
  isCheck,
  setIsCheck,
  setIsSubmit,
}: any) => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [searchValue, setSearchValue] = useState(null);
  const { RangePicker } = DatePicker;

  const organization = useSelector(
    (state: RootState) => state.organization.organization
  );

  const handleSearch = (value) => {
    let updatedData;

    setSearchValue(value);
    if (value) {
      updatedData = pyoutData?.filter((organization) =>
        organization?.Name.toLowerCase()?.includes(value.toLowerCase())
      );

      setPayoutData(updatedData);
    } else {
      setPayoutData(organization);
    }
  };

  const { getOrganizations } = useOpenOrganization();

  useEffect(() => {
    if (!searchValue) {
      getOrganizations();
    }
  }, [searchValue]);

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const Customimage = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(18).svg?updatedAt=1709195817408"
        alt=""
      />
    );
  };
  const FilterIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/filter-lines.svg?updatedAt=1709279769642"
        alt=""
      />
    );
  };

  const RangePickerFooter = ({
    onOk,
    onCancel,
    selectedDates,
  }: {
    onOk: () => void;
    onCancel: () => void;
    selectedDates: [string, string];
  }) => (
    <div className="w-full py-4">
      <div className="flex justify-between mb-4">
        <Input
          value={
            selectedDates[0]
              ? moment(selectedDates[0]).format("DD/MM/YYYY")
              : ""
          }
          placeholder="Start Date"
          readOnly
          className="w-[45%]"
        />
        <Input
          value={
            selectedDates[1]
              ? moment(selectedDates[1]).format("DD/MM/YYYY")
              : ""
          }
          placeholder="End Date"
          readOnly
          className="w-[45%]"
        />
      </div>
      <div className="w-full flex justify-end gap-3 py-4 items-center">
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="rounded-[8px] h-[40px]"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="bg-[#155EEF] rounded-[8px] h-[40px]"
          type="primary"
          onClick={onOk}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setValues({
        from: dateStrings[0],
        to: dateStrings[1],
      });
    } else {
      console.log("Clear");
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
    {
      label: "Yesterday",
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    { label: "All Time", value: [dayjs(0), dayjs()] }, 
  ];

  const getCheckboxValues = () => {
    const values = [];
    if (checkboxValues?.company) values.push(...checkboxValues.company);
    if (checkboxValues?.status) values.push(...checkboxValues.status);
    if (checkboxValues?.referred) values.push(...checkboxValues.referred);
    if (checkboxValues?.closed) values.push(...checkboxValues.closed);
    return values;
  };

  const checkvalues = getCheckboxValues();
  const displayText =
    checkvalues.length > 1
      ? `${checkvalues[0]}, +${checkvalues.length - 1}`
      : checkvalues[0];

  return (
    <div className="mt-8 w-full flex justify-between items-center searchTegs">
      <Input
        style={{
          boxShadow: "0px 1px 2px 0px #1018280D",
        }}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search"
        prefix={<SearchIcon />}
        className="w-[400px] h-[44px] placeholder:text-black rounded-[8px]"
      />
      <div className="flex justify-end items-center gap-4">
        {isCheck && (
          <>
            {checkboxValues?.status?.length > 0 ||
            checkboxValues?.company?.length > 0 ||
            checkboxValues?.referred?.length > 0 ||
            checkboxValues?.closed?.length > 0 ? (
              <Button
                onClick={() => {
                  setCheckboxValues({});
                  setPayoutData(data);
                  setIsCheck(false);
                }}
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                className="shadow-none flex items-center justify-center bg-[#EFF4FF] border border-[#EFF4FF] w-auto px-2 h-[40px] text-[#004EEB] font-semibold text-[14px]"
              >
                {checkvalues.length > 0 ? displayText : "clear"}{" "}
                <CloseOutlined className="text-[13px] text-[004EEB] font-semibold" />
              </Button>
            ) : null}
          </>
        )}
        <Button
          onClick={() => setIsFfilterOpen(true)}
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
            borderRadius:"8px"
          }}
          icon={<FilterIcon />}
          className="shadow-none flex items-center justify-center bg-white  border border-[#D0D5DD] w-[130px] h-[40px] text-[#344054] font-semibold text-[14px]"
        >
          More filters
        </Button>
        {dateShow && (
          <RangePicker
            open={dateShow}
            popupClassName="custom-calendar-class"
            presets={rangePresets}
            onChange={onRangeChange}
            suffixIcon={<Customimage />}
            className="border border-[#D0D5DD]  h-[40px]"
            renderExtraFooter={() => (
              <RangePickerFooter
                selectedDates={[values.from, values.to]}
                onOk={() => {
                  setDateShow(false);
                  setIsSubmit(true);
                }}
                onCancel={() => {
                  setDateShow(false);
                  setValues({ from: "", to: "" });
                }}
              />
            )}
          />
        )}
        {!dateShow && (
          <div
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
              padding: "10px, 16px, 10px, 16px",
            }}
            onClick={() => setDateShow(true)}
            className="shadow-none boxeds flex items-center justify-center cursor-pointer bg-white  border border-[#D0D5DD] w-auto px-3 h-[40px] text-[#344054] font-semibold text-[14px]"
          >
            <Customimage />
            {values?.from ? (
              <Text className="pl-2 text-[#344054]">
                {moment(values?.from).format("MMM DD")} -{" "}
                {moment(values?.to).format("MMM DD")}
              </Text>
            ) : (
              <Text className="pl-2 text-[#344054]">Select Dates</Text>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchSection;
