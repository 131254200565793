// @ts-nocheck

import React from "react";
import { Slider } from "antd";

const Sliderr = ({ onChange, value }: any) => {
  const trackStyle = {
    backgroundColor: "#155EEF",
  };

  return (
    <div className=" w-full ">
      <Slider
      className="sliding"
        onChange={(e) => onChange(e)}
        range
        value={value}
        defaultValue={[0, 10000]}
        min={0}
        max={10000}
        trackStyle={[trackStyle]}
      />
    </div>
  );
};

export default Sliderr;
