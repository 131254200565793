/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { Box, Grid } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import User from "../../../../assets/images/user.png";
import Pencil from "../../../../assets/images/pencil.png";
import BigUser from "../../../../assets/images/biglogo.png";
import Touch from "../../../../assets/images/touch.png";
import Coin from "../../../../assets/images/coins.png";
import Office from "../../../../assets/images/office.png";
import Bank from "../../../../assets/images/bank.png";
import Discount from "../../../../assets/images/discount.png";
import Dollar from "../../../../assets/images/dollar.png";
import UserAdd from "../../../../assets/images/useradd.png";
// import LabelInput from "../../../../components/labelInput.tsx";
import FilledButton from "../../../../components/filledButton.tsx";
import FilterComponent from "../filters/index.tsx";
import { toast } from "react-toastify";
import axiosClient from "../../../../utils/axios.ts";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import LabelInput from "../../../../components/commissionLabelInput.tsx";
import { Spin } from "antd";

// export default function AffiliateApproval({ data, refresh, isLoading }) {
export default function AffiliateApproval({ data, refresh }) {
  // console.log("Data in Affiliate: ",data);
  //
  const [events, setEvents] = useState(data || []);
  const [filters, setFilters] = useState({
    minAmount: null,
    maxAmount: null,
    companyName: null,
    affiliate: null,
    revenueType: null,
    dateRange: null,
  });
  const [openEvent, setOpenEvent] = useState(events[0] || null);
  const [commission, setCommission] = useState(() => {
    return openEvent
      ? ((openEvent.fee * openEvent.percentage) / 100).toFixed(2)
      : "0.00";
  });
  const [revenueAmount, setRevenueAmount] = useState(() => {
    // return openEvent ? openEvent.fee.toFixed(2) : "0.00";
    return openEvent ? openEvent.fee : "0.00";
  });
  const [commissionAmount, setCommissionAmount] = useState(() => {
    return openEvent
      ? ((revenueAmount * openEvent.percentage) / 100).toFixed(2)
      : "0.00";
  });
  const [commissionPercentage, setCommissionPercentage] = useState(() => {
    return openEvent ? openEvent.percentage : 0;
  });
  const [isExceeding, setIsExceeding] = useState(false);
  const [isExceeding1, setIsExceeding1] = useState(false);
  const [processingTransactions, setProcessingTransactions] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Load processing state from localStorage on component mount
    const storedState = localStorage.getItem("processingTransactions");
    if (storedState) {
      setProcessingTransactions(JSON.parse(storedState));
    }
  }, []);

  useEffect(() => {
    // Save processing state to localStorage whenever it changes
    localStorage.setItem(
      "processingTransactions",
      JSON.stringify(processingTransactions)
    );
  }, [processingTransactions]);

  const isTransactionProcessing = (transactionId) => {
    return (
      processingTransactions[transactionId] &&
      Date.now() - processingTransactions[transactionId] < 60000
    ); // 1 minute timeout
  };

  const setTransactionProcessing = (transactionId) => {
    setProcessingTransactions((prev) => ({
      ...prev,
      [transactionId]: Date.now(),
    }));
  };

  const clearTransactionProcessing = (transactionId) => {
    setProcessingTransactions((prev) => {
      const newState = { ...prev };
      delete newState[transactionId];
      return newState;
    });
  };

  //Commenting to fix Data Race and Filter Loss
  // useEffect(() => {
  //   setEvents(data);

  //   // Check if there's a new transaction (i.e., the number of events has increased)
  //   if (data.length > events.length) {
  //     const newEvent = data.find(
  //       (newItem) =>
  //         !events.some(
  //           (oldItem) => oldItem.transactionId === newItem.transactionId
  //         )
  //     );
  //     if (newEvent) {
  //       handleItemClick(newEvent);
  //     }
  //   }
  // }, [data]);

  useEffect(() => {
    const filteredEvents = data.filter((event) => {
      return (
        (!filters.minAmount || event.fee >= filters.minAmount) &&
        (!filters.maxAmount || event.fee <= filters.maxAmount) &&
        (!filters.companyName ||
          event.organizationName === filters.companyName) &&
        (!filters.affiliate || event.affiliateName === filters.affiliate) &&
        (!filters.revenueType || event.feeType === filters.revenueType) &&
        (!filters.dateRange ||
          (new Date(event.date) >= filters.dateRange.startDate &&
            new Date(event.date) <= filters.dateRange.endDate))
      );
    });
    setEvents(filteredEvents);

    // Update openEvent and related states when filters change
    if (filteredEvents.length > 0) {
      const newOpenEvent = filteredEvents[0];
      handleItemClick(filteredEvents[0]);
      setOpenEvent(newOpenEvent);
      setRevenueAmount(newOpenEvent?.fee); //?.toFixed(2));
      const newCommission =
        (newOpenEvent?.fee * newOpenEvent?.percentage) / 100;
      setCommission(newCommission?.toFixed(2));
      setCommissionAmount(newCommission?.toFixed(2));
    } else {
      setOpenEvent(null);
      setRevenueAmount("0.00");
      setCommission("0.00");
      setCommissionAmount("0.00");
    }
  }, [data, filters]);

  // const handleItemClick = (item) => {
  //   setOpenEvent(item);
  //   setRevenueAmount(item.fee);
  //   const newCommission = (item.fee * item.percentage) / 100;
  //   setCommission(newCommission.toFixed(2));
  //   setCommissionAmount(newCommission.toFixed(2));
  // };
  const handleItemClick = (item) => {
    setOpenEvent(item);
    setRevenueAmount(item.fee);
    setCommissionPercentage(item.percentage);
    const newCommission = (item.fee * item.percentage) / 100;
    setCommission(newCommission.toFixed(2));
    setCommissionAmount(newCommission.toFixed(2));
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleRevenueAmountChange = (e) => {
    const newRevenueAmount = e.target.value;
    setRevenueAmount(newRevenueAmount);

    if (openEvent) {
      const newCommissionAmount =
        (newRevenueAmount * openEvent.percentage) / 100;
      setCommissionAmount(newCommissionAmount.toFixed(2));
      setCommission(newCommissionAmount.toFixed(2));
    }
  };

  const handleCommissionPercentageChange = (e) => {
    // const newPercentage = parseFloat(e.target.value);
    const newPercentage = e.target.value;
    setCommissionPercentage(newPercentage);

    if (openEvent) {
      const newCommissionAmount = (revenueAmount * newPercentage) / 100;
      setCommissionAmount(newCommissionAmount.toFixed(2));
      setCommission(newCommissionAmount.toFixed(2));
    }
  };

  useEffect(() => {
    if (parseFloat(revenueAmount) > parseFloat(openEvent?.fee)) {
      setIsExceeding(true);
    } else {
      setIsExceeding(false);
    }
  }, [revenueAmount]);

  useEffect(() => {
    if (parseFloat(commissionPercentage) > parseFloat(openEvent?.percentage)) {
      setIsExceeding1(true);
    } else {
      setIsExceeding1(false);
    }
  }, [commissionAmount, commissionPercentage]);

  const cardDetails = openEvent
    ? [
        { img: Touch, title: "Affiliate ID", value: openEvent?.affiliateId },
        { img: Coin, title: "Revenue Event", value: openEvent?.feeType },
        {
          img: Office,
          title: "Company Name",
          value: openEvent.organizationName,
        },
        { img: Bank, title: "Commission Amount", value: `${commission} USD` },
        {
          img: Discount,
          title: "Commission %",
          value: `${openEvent?.percentage}%`,
        },
        // {
        //   img: Discount,
        //   title: "Commission %",
        //   value: `${commissionPercentage}%`,
        // },
      ]
    : [];

  const updateTransaction = (
    ID: number,
    body: { field: string; value: boolean }
  ) => {
    axiosClient()
      .patch(`transactions/${ID}`, body)
      .then((res) => {
        toast.success(res?.data.message);
        refresh();
      })
      .catch((error) => {
        console.error(error.message);
        toast.error(error.message);
      });
  };

  const resetForm = () => {
    if (events.length > 1) {
      const nextEvent = events.find(
        (event) => event.transactionId !== openEvent.transactionId
      );
      handleItemClick(nextEvent);
    } else {
      setOpenEvent(null);
      setRevenueAmount("0.00");
      setCommission("0.00");
      setCommissionAmount("0.00");
    }
  };

  const archiveHandler = () => {
    if (!openEvent || isTransactionProcessing(openEvent.transactionId)) return;

    setTransactionProcessing(openEvent.transactionId);
    setIsLoading(true);
    try {
      const ID = openEvent.transactionId;
      updateTransaction(ID, {
        field: "is_archived",
        value: true,
      });
      resetForm();
    } catch (err) {
      toast.error(err.message);
    } finally {
      clearTransactionProcessing(openEvent.transactionId);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  const selectOrganization = useMemo(() => {
    return createSelector(
      [(state) => state.organization.organization, (_, orgId) => orgId],
      (organizations, orgId) => {
        return organizations.find((org) => org.ID === orgId);
      }
    );
  }, []);

  const selectedOrganization = useSelector((state) =>
    selectOrganization(state, openEvent ? +openEvent?.organizationId : null)
  );

  const getOrganizationTableId = () => {
    return {
      tableId: selectedOrganization?.table_id,
      status: selectedOrganization?.Status,
    };
  };

  const submitHandler = () => {
    if (!openEvent || isTransactionProcessing(openEvent.transactionId)) return;

    setTransactionProcessing(openEvent.transactionId);
    setIsLoading(true);

    const { tableId, status } = getOrganizationTableId(
      +openEvent?.organizationId
    );

    const payload = {
      // commission: +openEvent?.percentage,
      commission: +commissionPercentage,
      user_id: +openEvent?.affiliateId,
      pipedrive_org_id: tableId,
      commission_paid: +commissionAmount,
      total_revenue: +revenueAmount,
      last_revenue: 0,
      revenue_event: openEvent?.feeType,
      is_approved: false,
      status: status,
      payment_status: "Unpaid",
      created_at: new Date(openEvent?.date),
    };

    // console.log("Payload: ", payload);

    axiosClient()
      .post("users-commissions", payload)
      .then((res) => {
        const ID = openEvent.transactionId;
        updateTransaction(ID, {
          field: "is_approved",
          value: true,
        });
        // toast.success("Transaction submitted successfully!");
        refresh();
        resetForm();
      })
      .catch((error) => {
        toast.error(error.message);
        console.error(error.message);
      })
      .finally(() => {
        clearTransactionProcessing(openEvent.transactionId);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      });
  };

  const handleFilterChange = (newFilters) => {
    const filteredEvents = data.filter((event) => {
      return (
        (!newFilters.minAmount ||
          event.fee >= parseFloat(newFilters.minAmount)) &&
        (!newFilters.maxAmount ||
          event.fee <= parseFloat(newFilters.maxAmount)) &&
        (!newFilters.companyName ||
          event.organizationName === newFilters.companyName) &&
        (!newFilters.affiliate ||
          event.affiliateName === newFilters.affiliate) &&
        (!newFilters.revenueType || event.feeType === newFilters.revenueType) &&
        (!newFilters.dateRange ||
          (new Date(event.date) >= new Date(newFilters.dateRange.startDate) &&
            new Date(event.date) <= new Date(newFilters.dateRange.endDate)))
      );
    });
    setEvents(filteredEvents);
    setFilters(newFilters);
  };

  useEffect(() => {
    if (
      Object.values(filters).every((value) => value === "" || value === null)
    ) {
      setEvents(data);
    }
  }, [filters, data]);

  useEffect(() => {
    if (events.length > 0) {
      setOpenEvent(events[0]);
      const newCommission = (events[0].fee * events[0].percentage) / 100;
      setCommission(newCommission.toFixed(2));
      setCommissionAmount(newCommission.toFixed(2));
    } else {
      setOpenEvent(null);
      setCommission("0.00");
      setCommissionAmount("0.00");
    }
  }, [events]);

  return (
    <>
      <Box>
        <h3 className="totalEvents">{events.length} Events</h3>
        <FilterComponent
          data={data}
          onFilterChange={handleFilterChange}
          handleItemClick={handleItemClick}
          refresh={refresh}
          isAffiliate={true}
        />
        <Grid container spacing={1} className="gridSide">
          <Grid item xs={3} paddingRight={"15px"} className="mobileFit">
            {events.map((item, index) => (
              <Box
                className={
                  item.transactionId === openEvent?.transactionId
                    ? "sideMenu-active"
                    : "sideMenu"
                }
                onClick={() => handleItemClick(item)}
                key={index}
              >
                <Box className="userName" style={{display:"flex", alignItems:"center"}}>
                  <img src={Dollar} alt="" />
                  <Box>
                    <h6>{item?.organizationName}</h6>
                    <h5>{item?.affiliateName}</h5>
                  </Box>
                </Box>
                <Box className="userName last" style={{ width: "114px" }}>
                  <h6>
                    {item?.date
                      ? moment(item?.date).format("DD/MM/YYYY")
                      : "0/0/0000"}
                  </h6>
                  {/* <h5 style={{ fontWeight: "400" }}>${item?.fee.toFixed(2)}</h5> */}
                  <h5 style={{ fontWeight: "400" }}>${item?.fee}</h5>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={9} className="mobileFits">
            {openEvent ? (
              <Box className="rightCard">
                <Box className="rightHeader">
                  <Box className="leftSide">
                    <img src={BigUser} alt="" />
                    <h4>{openEvent?.affiliateName}</h4>
                  </Box>
                  <Box className="rightSide">
                    <h4>
                      {openEvent?.date
                        ? moment(openEvent?.date).format("DD/MM/YYYY")
                        : "0/0/0000"}
                    </h4>
                    {/* <h6>{openEvent?.fee.toFixed(2)} USD</h6> */}
                    <h6>{openEvent?.fee} USD</h6>
                  </Box>
                </Box>
                <Box className="cardBody">
                  <Box className="gridSection">
                    {cardDetails.map((detail, index) => (
                      <Box className="cardDetail" key={index}>
                        <img src={detail.img} alt="" />
                        <h5>{detail.title}</h5>
                        <h6>{detail.value}</h6>
                      </Box>
                    ))}
                  </Box>
                  <Grid container>
                    <Grid xs={5.9}>
                      <Box
                        className="revenueInput"
                        style={{ position: "relative" }}
                      >
                        <LabelInput
                          title="Revenue Amount"
                          value={revenueAmount}
                          onChange={handleRevenueAmountChange}
                          type="number"
                        />
                        <img src={Pencil} alt="" />
                        {isExceeding ? (
                          <text className="text-red-400 text-sm">
                            Exceeding the real Revenue Amount
                          </text>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                    <Grid xs={0.2}></Grid>
                    <Grid xs={5.9}>
                      <Box
                        className="revenueInput"
                        style={{ position: "relative" }}
                      >
                        <LabelInput
                          title="Commission %"
                          type="number"
                          value={commissionPercentage}
                          onChange={handleCommissionPercentageChange}
                        />
                        <img src={Pencil} alt="" />
                        {isExceeding1 ? (
                          <text className="text-red-400 text-sm">
                            Exceeding the real Commission Percentage
                          </text>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className="submitButton">
                    <FilledButton
                      disabled={
                        isExceeding ||
                        isExceeding1 ||
                        !openEvent ||
                        isTransactionProcessing(openEvent?.transactionId)
                      }
                      onClick={archiveHandler}
                    >
                      {isTransactionProcessing(openEvent?.transactionId)
                        ? "Processing..."
                        : "Archive"}
                    </FilledButton>
                    <FilledButton
                      style={{ background: "#000", color: "#fff" }}
                      disabled={
                        isExceeding ||
                        isExceeding1 ||
                        !openEvent ||
                        isTransactionProcessing(openEvent?.transactionId)
                      }
                      onClick={submitHandler}
                    >
                      {isTransactionProcessing(openEvent?.transactionId)
                        ? "Processing..."
                        : "Submit"}
                    </FilledButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="rightCard">
                <p>No events to display</p>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {isLoading && <Spin fullscreen />}
    </>
  );
}
{
  /*{isLoading ? (
        <div className="flex items-center justify-center py-[11.5rem]">
          <Spin />
        </div>
      ) : (
        <Box>
          <h3 className="totalEvents">{events.length} Events</h3>
          <FilterComponent
            data={data}
            onFilterChange={handleFilterChange}
            handleItemClick={handleItemClick}
            refresh={refresh}
            isAffiliate={true}
          />
          <Grid container spacing={1} className="gridSide">
            <Grid item xs={3} paddingRight={"15px"} className="mobileFit">
              {events.map((item, index) => (
                <Box
                  className={
                    item.transactionId === openEvent?.transactionId
                      ? "sideMenu-active"
                      : "sideMenu"
                  }
                  onClick={() => handleItemClick(item)}
                  key={index}
                >

                  <Box className="userName" style={{display:"flex", alignItems:"center"}}>
                    <img src={Dollar} alt="" />
                    <Box>
                      <h6>{item?.organizationName}</h6>
                      <h5>{item?.affiliateName}</h5>
                    </Box>
                  </Box>
                  <Box className="userName last">
                    <h6>
                      {item?.date
                        ? moment(item?.date).format("DD/MM/YYYY")
                        : "0/0/0000"}
                    </h6>
                    
                    <h5 style={{ fontWeight: "400" }}>${item?.fee}</h5>
                  </Box>
                </Box>
              ))}
            </Grid>
            <Grid item xs={9} className="mobileFits">
              {openEvent ? (
                <Box className="rightCard">
                  <Box className="rightHeader">
                    <Box className="leftSide">
                      <img src={BigUser} alt="" />
                      <h4>{openEvent?.affiliateName}</h4>
                    </Box>
                    <Box className="rightSide">
                      <h4>
                        {openEvent?.date
                          ? moment(openEvent?.date).format("DD/MM/YYYY")
                          : "0/0/0000"}
                      </h4>
                      
                      <h6>{openEvent?.fee} USD</h6>
                    </Box>
                  </Box>
                  <Box className="cardBody">
                    <Box className="gridSection">
                      {cardDetails.map((detail, index) => (
                        <Box className="cardDetail" key={index}>
                          <img src={detail.img} alt="" />
                          <h5>{detail.title}</h5>
                          <h6>{detail.value}</h6>
                        </Box>
                      ))}
                    </Box>
                    <Grid container style={{ borderBottom: "1px solid #EAECF0" }}>
                      <Grid xs={5.9}>
                        <Box
                          className="revenueInput"
                          sx={{ position: "relative", borderBottom: "0" }}
                        >
                          <LabelInput
                            title="Revenue Amount"
                            value={revenueAmount}
                            onChange={handleRevenueAmountChange}
                            type="number"
                          />
                          <img src={Pencil} alt="" />
                          {isExceeding ? (
                            <text className="text-red-400 text-sm">
                              Exceeding the real Revenue Amount
                            </text>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                      <Grid xs={0.2}></Grid>
                      <Grid xs={5.9}>
                        <Box
                          className="revenueInput"
                          sx={{ position: "relative", borderBottom: "0" }}
                        >
                          <LabelInput
                            title="Commission %"
                            type="number"
                            value={commissionPercentage}
                            onChange={handleCommissionPercentageChange}
                          />
                          <img src={Pencil} alt="" />
                          {isExceeding1 ? (
                            <text className="text-red-400 text-sm">
                              Exceeding the real Commission Percentage
                            </text>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="submitButton">
                      <FilledButton
                        disabled={isExceeding || isExceeding1}
                        onClick={archiveHandler}
                      >
                        Archive
                      </FilledButton>
                      <FilledButton
                        style={{ background: "#000", color: "#fff" }}
                        disabled={isExceeding || isExceeding1}
                        onClick={submitHandler}
                      >
                        Submit
                      </FilledButton>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box className="rightCard">
                  <p>No events to display</p>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      )} */
}
