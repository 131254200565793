/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
"use client";
import React, { useState, useEffect } from "react";
import { Heading } from "../../components/index.ts";
import SearchSection from "./search-section.tsx";
import Sidebar from "../../layout/sidebar/view.tsx";
import SidebarFilter from "../../layout/sidebar/filter.tsx";
import { COMPANIES_DATA_COLUMN, COMPANIES_DATA } from "../../constants/data.ts";
import Table from "./table.tsx";
import useOpenOrganization from "../../hooks/use-organization.tsx";
import { RootState } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import axiosClient from "../../utils/axios.ts";

const Main = () => {
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isFilterOpen, setIsFfilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedObject, setSelectedObject] = useState();
  const [pyoutData, setPayoutData] = useState();
  const [sidebarData, setSidebarData] = useState();
  const [viewData, setViewData] = useState();
  const [selectedFilters, setSelectedFFilters] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const organization = useSelector(
    (state: RootState) => state.organization.organization
  );

  const loading = useSelector((state: RootState) => state.isLoading.isLoading);

  const initialState: Values = { from: "", to: "" };

  const [values, setValues] = useState<Values | undefined>(initialState);
  const [dateShow, setDateShow] = useState(false);

  const { getOrganizations } = useOpenOrganization();

  useEffect(() => {
    getOrganizations(values);
  }, [values]);

  useEffect(() => {
    setPayoutData(organization);
    setSidebarData(organization);
  }, [organization]);

  const today = new Date();
  const todayISOString = today.toISOString().split("T")[0];

  // Get the current month
  const currentMonth = today.getMonth();

  // Calculate the month one month ago
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(currentMonth - 1);
  const oneMonthAgoISOString = oneMonthAgo.toISOString().split("T")[0];

  // Calculate the month three months ago
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(currentMonth - 3);
  const threeMonthsAgoISOString = threeMonthsAgo.toISOString().split("T")[0];

  useEffect(() => {
    if (isSubmit) {
      axiosClient()
        .get(
          // `/pipedrive-organisations?StartDate=${
          `/pipedrive-organisations/all-referred/?StartDate=${
            values?.from ? values?.from : oneMonthAgoISOString
          }&EndDate=${values?.to ? values?.to : todayISOString}`
        )
        .then((res) => {
          setPayoutData(res?.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [isSubmit]);

  return (
    <>
      <div className="container pt-10 text-[30px]" style={{paddingTop:"100px"}}>
        <Heading heading="Companies" subHeading="List of referred companies" />

        <SearchSection
         
          isFilterOpen={isFilterOpen}
          setIsFfilterOpen={setIsFfilterOpen}
          setPayoutData={setPayoutData}
          pyoutData={organization}
          data={organization}
          setValues={setValues}
          setDateShow={setDateShow}
          dateShow={dateShow}
          values={values}
          selectedFilters={selectedFilters}
          checkboxValues={checkboxValues}
          setCheckboxValues={setCheckboxValues}
          isCheck={isCheck}
          setIsCheck={setIsCheck}
          setIsSubmit={setIsSubmit}
        />
        {/* {organization?.length === 0 ? ( */}
        { loading ? (
          <>
            <div className="flex items-center justify-center my-10">
              <Spin fullscreen size="large" />
            </div>
          </>
        ) : (
          <>
            <div className="my-8">
              <Table
                setIsViewOpen={setIsViewOpen}
                activeTab={activeTab}
                setIsEditOpen={setIsEditOpen}
                column={COMPANIES_DATA_COLUMN}
                data={organization}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
                setPayoutData={setPayoutData}
                pyoutData={pyoutData}
                setSelectedObject={setSelectedObject}
                selectedObject={selectedObject}
                setViewData={setViewData}
              />
            </div>
            <div>
              <Sidebar
                setIsViewOpen={setIsViewOpen}
                isViewOpen={isViewOpen}
                isCompany
                data={viewData}
                setPayoutData={setPayoutData}
                setViewData={setViewData}

              />
              <SidebarFilter
                setIsViewOpen={setIsFfilterOpen}
                isViewOpen={isFilterOpen}
                setPayoutData={setPayoutData}
                pyoutData={pyoutData}
                sidebarData={sidebarData}
                isCompany
                organization={organization}
                setSelectedFFilters={setSelectedFFilters}
                setCheckboxValues={setCheckboxValues}
                checkboxValues={checkboxValues}
                setIsCheck={setIsCheck}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Main;
