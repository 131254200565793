import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FilledButton from "../../../../../components/filledButton.tsx";
// import LabelInput from "../../../../../components/labelInput.tsx";
import LabelInput from "../../../../../components/commissionLabelInput.tsx";
import FilterIcon from "../../../../../assets/images/filter.png";
import DateIcon from "../../../../../assets/images/date.png";
import CloseIcon from "../../../../../assets/images/close.png";
import SelectDropdown from "../../filters/component/select-dropdown/index.tsx";
import DateRangePickerComponent from "../../filters/component/custom-rangepicker/index.tsx";
import axiosClient from "../../../../../utils/axios.ts";
import moment from "moment";

interface Filters {
  companyName: string;
  userType: string;
  userName: string;
  revenueType: string;
  minAmount: string;
  maxAmount: string;
  startDate: string;
  endDate: string;
}

export default function ArchivedFilterComponent({
  updateFilters
}: {
  updateFilters: (filters: Filters) => void;
}) {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const ref = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState<Filters>({
    companyName: "",
    userType: "",
    userName: "",
    revenueType: "",
    minAmount: "",
    maxAmount: "",
    startDate: "",
    endDate: "",
  });

  const [appliedFilters, setAppliedFilters] = useState<Filters>({
    companyName: "",
    userType: "",
    userName: "",
    revenueType: "",
    minAmount: "",
    maxAmount: "",
    startDate: "",
    endDate: "",
  });

  const initialDateRange = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ];

  const [dateRange, setDateRange] = useState(initialDateRange);

// console.log("Date Range: ",dateRange);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
      setDate(false);
    }
  };

  useEffect(() => {
    axiosClient()
      .get("pipedrive-organisations")
      .then((res) => setAllCompanies(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    axiosClient()
      .get("users")
      .then((res) =>
        setAllUsers(res.data.filter((user) => user.role !== "ADMIN"))
      )
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const companyOptions = [
    ...new Set(allCompanies?.map((org: any) => org.Name)),
  ].map((name) => ({ label: name, value: name }));

  // console.log("Company Options: ",companyOptions);

  const userTypeOptions = [
    { value: "EMPLOYEE", label: "Employee" },
    { value: "AFFILIATES", label: "Partner" },
    { value: "SUPER_PARTNER", label: "Super Partner" },
    { value: "MINI_PARTNER", label: "Mini Partner" },
  ];

  const getUserOptions = () => {
    if (!filters.userType) {
      return allUsers.map((user) => ({ value: user.name, label: user.name }));
    }
    return allUsers
      .filter((user) => user.role === filters.userType)
      .map((user) => ({ value: user.name, label: user.name }));
  };

  // console.log("Users options: ", getUserOptions());

  const revenueTypeOptions = [
    { value: "markup_fee", label: "Markup Fee" },
    { value: "research_fee", label: "Research Fee" },
    { value: "service_fee", label: "Service Fee" },
    { value: "subscription_fee", label: "Subscription Fee" },
  ];

  const handleFilterChange = (filterType: keyof Filters, value: string) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [filterType]: value };
      if (filterType === "userType") {
        newFilters.userName = "";
      }
      return newFilters;
    });
  };

  const handleReset = () => {
    setFilters({
      companyName: "",
      userType: "",
      userName: "",
      revenueType: "",
      minAmount: "",
      maxAmount: "",
      startDate: "",
      endDate: "",
    });
    setAppliedFilters({
      companyName: "",
      userType: "",
      userName: "",
      revenueType: "",
      minAmount: "",
      maxAmount: "",
      startDate: "",
      endDate: "",
    });
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    updateFilters({});
  };

  const handleApply = () => {
    setAppliedFilters(filters);
    updateFilters(filters);
    setShow(false);
  };

  const removeFilter = (key: keyof Filters) => {
    // console.log("Removing...",key);
    setFilters((prev) => ({ ...prev, [key]: "" }));
    setAppliedFilters((prev) => ({ ...prev, [key]: "" }));
    updateFilters({ ...appliedFilters, [key]: "" });
  };

  const removeDate = () => {
    setDateRange(initialDateRange);
    setFilters((prev) => ({ ...prev, startDate: "", endDate: "" }));
    setAppliedFilters((prev) => ({ ...prev, startDate: "", endDate: "" }));
    updateFilters({ ...appliedFilters, startDate: "", endDate: "" });
  }

  // useEffect(() => {
  //   console.log("Current filters:", filters);
  // }, [filters]);

  const getLabelForValue = (options, value) => {
    const option = options.find((opt) => opt.value === value);
    return option ? option.label : "";
  };

  return (
    <>
      <Box className="filterBox" style={{ position: "relative" }}>
        
        <Box className="filterOption">
          <FilledButton className="btnDate" onClick={() => setShow(true)}>
            <img src={FilterIcon} alt="" /> Filters
          </FilledButton>
          {show && (
            <Box className="filterSection" ref={ref}>
              <Typography variant="h1">Filters</Typography>
              <Box className="filterMenu">
                <Grid
                  container
                  sx={{ width: "352px !important", margin: "0 auto" }}
                >
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Min amount"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={filters.minAmount}
                        onChange={(e) =>
                          handleFilterChange("minAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={0.6}></Grid>
                  <Grid xs={5.7}>
                    <Box className="gridInput">
                      <LabelInput
                        title="Max amount"
                        className="valueInput"
                        placeholder="600.00 USD"
                        value={filters.maxAmount}
                        onChange={(e) =>
                          handleFilterChange("maxAmount", e.target.value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Company name"
                        options={companyOptions}
                        value={filters.companyName}
                        onChange={(value) =>
                          handleFilterChange("companyName", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="User Type"
                        options={userTypeOptions}
                        value={filters.userType}
                        onChange={(value) =>
                          handleFilterChange("userType", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="User Name"
                        options={getUserOptions()}
                        value={filters.userName}
                        onChange={(value) =>
                          handleFilterChange("userName", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Box className="gridInput">
                      <SelectDropdown
                        title="Revenue Type"
                        options={revenueTypeOptions}
                        value={filters.revenueType}
                        onChange={(value) =>
                          handleFilterChange("revenueType", value)
                        }
                      />
                    </Box>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                      width: "100%",
                    }}
                  >
                    <FilledButton className="cancelBtn" onClick={handleReset}>
                      Reset
                    </FilledButton>
                    <FilledButton style={{background:"#155EEF", color:"#fff"}} className="cancelBtn" onClick={handleApply}>
                      Apply
                    </FilledButton>
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="filterOption" sx={{ position: "relative" }}>
          <FilledButton className="btnDate sz" onClick={() => setDate(true)}>
            <img src={DateIcon} alt="" /> Select Dates
          </FilledButton>
          {date && (
            <Box className="dateBox" ref={ref}>
              <DateRangePickerComponent
                dateRange={dateRange}
                setDateRange={(newRange) => {
                  setDateRange(newRange);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    startDate: newRange[0].startDate.toISOString(),
                    endDate: newRange[0].endDate.toISOString(),
                  }));
                }}
                onApply={() => {
                  setDate(false);
                  setAppliedFilters((prev) => ({
                    ...prev,
                    startDate: filters.startDate,
                    endDate: filters.endDate,
                  }));
                  updateFilters(filters);
                  // console.log("Filters: ", filters);
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {Object.entries(appliedFilters).map(([key, value]) => {
            if (!value) return null;
            let displayValue = value;
            if (key === "userType") {
              displayValue = getLabelForValue(userTypeOptions, value);
            } else if (key === "companyName") {
              displayValue = getLabelForValue(companyOptions, value);
            } else if (key === "userName") {
              displayValue = getLabelForValue(getUserOptions(), value);
            } else if (key === "revenueType") {
              displayValue = getLabelForValue(revenueTypeOptions, value);
            } else if (key === "startDate" || key === "endDate") {
              // Skip individual date badges as we'll create a combined one
              return null;
            }
            return (
              <FilledButton
                key={key}
                className="tagBtn"
                onClick={() => removeFilter(key as keyof Filters)}
              >
                <img src={CloseIcon} alt="" />
                {`${key}: ${displayValue}`}
              </FilledButton>
            );
          })}
          {appliedFilters.startDate && appliedFilters.endDate && (
            <FilledButton
              className="tagBtn"
              onClick={() => {
                // removeFilter("startDate");
                // removeFilter("endDate");
                removeDate()
              }}
            >
              <img src={CloseIcon} alt="" />
              {`Date: ${moment(appliedFilters.startDate).format(
                "DD/MM/YYYY"
              )} - ${moment(appliedFilters.endDate).format("DD/MM/YYYY")}`}
            </FilledButton>
          )}
        </Box>
      </Box>
    </>
  );
}
