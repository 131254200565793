import React from "react";
import { Col, Row, Typography } from "antd";

const VerifyEmail = () => {
  const { Title, Text } = Typography;

  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28">
          <div>
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon.svg?updatedAt=1709120630375"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Check your email
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2">
                We sent an email verfication link to your inbox.
              </Text>
              <Text
                style={{
                  color: "#475467",
                }}
                className=" text-[14px] pt-6"
              >
                Didn’t receive the email? {""}
                <span className="text-[#004EEB] cursor-pointer font-semibold text-[14px]">
                  Click to resend
                </span>
              </Text>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default VerifyEmail;
