// @ts-nocheck
"use client";
import React, { useState, useEffect } from "react";
import { Input, DatePicker, Button, Typography } from "antd";
import dayjs from "dayjs";
import type { TimeRangePickerProps, Dayjs } from "antd";
import { useSelector } from "react-redux";
import useOpenPayouts from "../../hooks/use-payouts.tsx";
import { CloseOutlined } from "@ant-design/icons";
import { RootState } from "@reduxjs/toolkit/query";
import moment from "moment";

const SearchSection = ({
  setIsFfilterOpen,
  setPayoutData,
  pyoutData,
  data,
  setIsCheck,
  checkboxValues,
  isCheck,
  setCheckboxValues,
  setIsSubmit,
  values,
  setValues,
  refresh,
  // handleApply,
}: any) => {
  interface Values {
    from: string;
    to: string;
  }

  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const [searchValue, setSearchValue] = useState(null);
  const [dateShow, setDateShow] = useState(false);

  const initialState: Values = { from: "", to: "" };
  const [tempValues, setTempValues] = useState<Values | undefined>(
    initialState
  );
  // console.log("Payout Data in Search: ", pyoutData);
  const handleSearch = (value) => {
    let updatedData; // Initialize with the original data

    setSearchValue(value);

    if (value) {
      updatedData = data?.filter((payout) =>
        payout?.affiliateName?.toLowerCase()?.includes(value?.toLowerCase())
      );

      setPayoutData(updatedData);
    } else {
      setPayoutData(data);
    }

    // dispatch(addOpenPayouts(updatedData));
  };
  // const payouts = useSelector((state: RootState) => state.payouts.payouts);

  // const { getPayouts } = useOpenPayouts();

  // useEffect(() => {
  //   if (!searchValue) {
  //     getPayouts();
  //     // dispatch(addOpenOrganizations(data));
  //   }
  // }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
  }, [data]);

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const Customimage = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(18).svg?updatedAt=1709195817408"
        alt=""
      />
    );
  };
  const FilterIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/filter-lines.svg?updatedAt=1709279769642"
        alt=""
      />
    );
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setTempValues({
        from: dateStrings[0],
        to: dateStrings[1],
      });
    } else {
      setTempValues(initialState);
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
    {
      label: "Yesterday",
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    { label: "All Time", value: [dayjs("2021-12-31"), dayjs()] },
  ];

  const RangePickerFooter = ({
    onOk,
    onCancel,
    selectedDates,
  }: {
    onOk: () => void;
    onCancel: () => void;
    selectedDates: [string, string];
  }) => (
    <div className="w-full py-4">
      <div className="flex justify-between mb-4">
        <Input
          value={
            selectedDates[0]
              ? moment(selectedDates[0]).format("DD/MM/YYYY")
              : ""
          }
          placeholder="Start Date"
          readOnly
          className="w-[45%]"
        />
        <Input
          value={
            selectedDates[1]
              ? moment(selectedDates[1]).format("DD/MM/YYYY")
              : ""
          }
          placeholder="End Date"
          readOnly
          className="w-[45%]"
        />
      </div>
      <div className="w-full flex justify-end gap-3 py-4 items-center">
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="rounded-[8px] h-[40px]"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="bg-[#155EEF] rounded-[8px] h-[40px]"
          type="primary"
          onClick={onOk}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  //@ts-ignore
  const getCheckboxValues = () => {
    const values = [];
    if (checkboxValues?.Status) values.push(...checkboxValues.Status);
    if (checkboxValues?.Role) values.push(...checkboxValues.Role);
    if (checkboxValues?.Commission) values.push(`Min: ${checkboxValues.Commission[0]}`);
    if (checkboxValues?.Commission) values.push(`Max: ${checkboxValues.Commission[1]}`);
    return values;
  };

  const checkvalues = getCheckboxValues();
  // console.log("Checkbox values: ",checkvalues);
  // console.log("Checkbox at 0: ",checkvalues[0]);
  const displayText =
    checkvalues.length > 1
      ? `${checkvalues[0]}, +${checkvalues.length - 1}`
      : checkvalues[0];
      // console.log("display text: ",displayText);

  return (
    <div className="mt-8 w-full flex justify-between items-center searchTegs">
      <Input
        style={{
          boxShadow: "0px 1px 2px 0px #1018280D",
        }}
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search"
        prefix={<SearchIcon />}
        className="w-[400px] h-[44px] placeholder:text-black rounded-[8px]"
      />
      <div className="flex justify-end items-center gap-4">
        {isCheck && (
          <>
            {checkboxValues?.Role?.length > 0 ||
            checkboxValues?.Status?.length > 0 ||
            checkboxValues?.Commission?.length > 0 ? (
              <Button
                onClick={() => {
                  // setPayoutData(data);
                  refresh();
                  setCheckboxValues({});
                  setIsCheck(false);
                }}
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
                className="shadow-none flex items-center justify-center bg-[#EFF4FF] border border-[#EFF4FF] w-auto px-2 h-[40px] text-[#004EEB] font-semibold text-[14px]"
              >
                {checkvalues.length > 0 ? displayText : "clear"}{" "}
                <CloseOutlined className="text-[13px] text-[004EEB] font-semibold" />
              </Button>
            ) : null}
          </>
        )}
        <Button
          onClick={() => setIsFfilterOpen(true)}
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
            borderRadius:"8px"
          }}
          icon={<FilterIcon />}
          className="shadow-none flex items-center justify-center bg-white  border border-[#D0D5DD] w-[130px] h-[40px] text-[#344054] font-semibold text-[14px]"
        >
          More filters
        </Button>

        {dateShow && (
          <RangePicker
            open={dateShow}
            popupClassName="custom-calendar-class" 
            presets={rangePresets}
            onChange={onRangeChange}
            suffixIcon={<Customimage />}
            className="border border-[#D0D5DD]  h-[40px] "
            renderExtraFooter={() => (
              <RangePickerFooter
                selectedDates={[tempValues.from, tempValues.to]}
                onOk={() => {
                  // setCheckboxValues((prev) => ({...prev,
                  //   StartDate: tempValues?.from,
                  //   EndDate: tempValues?.to,
                  // }));
                  setValues(tempValues);
                  // handleApply();
                  setDateShow(false);
                  setIsSubmit(true);
                }}
                onCancel={() => {
                  setTempValues(initialState);
                  setValues(initialState);
                  setDateShow(false);
                }}
              />
            )}
          />
        )}

        {!dateShow && (
          <div
            onClick={() => setDateShow(true)}
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
              padding: "10px, 16px, 10px, 16px",
            }}
            className={`border cursor-pointer boxeds z-40 border-[#D0D5DD] rounded-[8px] bg-white  flex items-center gap-3 h-[44px] justify-center ${
              values?.from && values?.to ? "w-[290px]" : "w-[140px]"
            }`}
          >
            <Customimage />
            <Text className="text-[#344054] font-semibold text-sm">
              {/* {values?.from && values?.to
                ? `${values?.from} - ${values?.to}`
                : "Select dates"} */}
              {values?.from && values?.to
                ? `${moment(values.from).format("DD/MM/YYYY")} - ${moment(
                    values.to
                  ).format("DD/MM/YYYY")}`
                : "Select dates"}
            </Text>
            {values?.to && (
              <CloseOutlined
                className="text-sm"
                onClick={() => {
                  setDateShow(false);
                  // setValues({ from: "", to: "" });
                  setValues(initialState);
                  setIsSubmit(false);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchSection;
