import { useState } from "react";
import { teamsApi } from "../services/teams.ts";
import { useDispatch } from "react-redux";
import { addAffilliates, addEmployees, addMiniPartners, addSuperPartners, getAdmins } from "../redux/teams.ts";
import { setReduxLoading } from "../redux/loading.ts";

const useOpenTeams = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const getTeams = async (role) => {
    dispatch(setReduxLoading(true));
    try {
      const teams = await teamsApi.getAllTeams(role);
      if (role === "ADMIN") {
        dispatch(getAdmins(teams));
      } else if (role === "AFFILIATES") {
        dispatch(addAffilliates(teams));
      } else if (role === "EMPLOYEE") {
        dispatch(addEmployees(teams));
      } else if (role === "SUPER_PARTNER") {
        dispatch(addSuperPartners(teams));
      } else if (role === "MINI_PARTNER"){
        dispatch(addMiniPartners(teams));
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getTeams,
  };
};

export default useOpenTeams;
