import React, { useEffect, useState } from "react";
import { Heading, Anchor, Table } from "../../components/index.ts";
import { ANCHOR } from "../../constants/data.ts";
import SearchSection from "./search-section.tsx";
import Close from "../../../src/assets/images/closed.png";
import SidebarFilter from "./filter.tsx";
import EditModal from "./edit-modal.tsx";
import { PAYOUTS_DATA_COLUMN } from "../../constants/data.ts";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import axiosClient from "../../utils/axios.ts";
import useOpenPayouts from "../../hooks/use-payouts.tsx";
import { Spin, Modal, Table as AntTable } from "antd"; // Import Ant Design Table
import PayoutTable from "./payoutTable.tsx";

const Main = () => {
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isFilterOpen, setIsFfilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedObject, setSelectedObject] = useState();
  const [isLoad, setIsLoad] = useState(false);
  const [selectedFilters, setSelectedFFilters] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({});
// console.log("Checkbox Values: ",checkboxValues);
  const [sidebarData, setSidebarData] = useState();
  const [isCheck, setIsCheck] = useState(false);
  const [viewData, setViewData] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  // const payouts = useSelector((state: RootState) => state.payouts.payouts);
  const [payouts, setPayouts] = useState([]);
  const [pyoutData, setPayoutData] = useState([]); //useState(payouts);
  const { getPayouts } = useOpenPayouts();

  // console.log("Payout data in Main : ",pyoutData);

  const initialState: Values = { from: "", to: "" };

  const [values, setValues] = useState<Values | undefined>(initialState);
  // console.log("Values in Main: ",values);
  const loading = useSelector((state: RootState) => state.isLoading.isLoading);

  const handleEditAmount = (selectedKeys, newAmount) => {
    const numericAmount = parseFloat(newAmount);

    setIsLoad(true);
    axiosClient()
      .patch(`users-commissions/${selectedObject?.user_commission_id}`, {
        commission_paid: numericAmount,
        is_approved: true,
        payment_status: activeTab === 0 ? "unpaid" : "paid manual",
      })
      .then((res) => {
        getPayouts();
        setIsLoad(false);
        setIsEditOpen(false);
        toast.success("Approved and updated successfully");
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoad(false);
      });
  };

  const handleApply = () => {
    setIsLoad(true);
    // if (values.from !== ""){
      // setValues(initialState);
    // }
    
    // setIsCheck(true);

    const status = checkboxValues.Status || [];
    const statusJson = JSON.stringify(status);
    const encodedStatuses = encodeURIComponent(statusJson);

    const role = checkboxValues.Role?.toString()?.toUpperCase() || [];
    const roleJson = JSON.stringify(role);
    const encodedRoles = encodeURIComponent(roleJson);

    const commission = checkboxValues.Commission || [];

    const minCommission = checkboxValues.Commission
      ? checkboxValues.Commission[0]
      : "";
    const minCommissionJson = JSON.stringify(minCommission);
    const encodedMinCommission = encodeURIComponent(minCommissionJson);

    const maxCommission = checkboxValues.Commission
      ? checkboxValues.Commission[1]
      : "";
    const maxCommissionJson = JSON.stringify(maxCommission);
    const encodedMaxCommission = encodeURIComponent(maxCommissionJson);

    const queryParams = [];
    // Add parameters to the array only if they are not empty
    if (status.length) queryParams.push(`payment_status=${encodedStatuses}`);
    if (role.length) queryParams.push(`Role=${encodedRoles}`);
    if (commission.length)
      queryParams.push(`MinCommission=${encodedMinCommission}`);
    if (commission.length)
      queryParams.push(`MaxCommission=${encodedMaxCommission}`);
    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
    axiosClient()
      .get(`users-commissions/formatted-data/${queryString}`)
      .then((res) => {
        // setPayoutData(res?.data);
        setPayouts(res?.data);
        setIsViewOpen(false);
        setIsFfilterOpen(false);
        setIsLoad(false);
        setIsCheck(true);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong!");
        setIsLoad(false);
        setIsCheck(false);
      });
  };

  const getDataFromCommissionTable = () => {
    axiosClient()
      .get("users-commissions/formatted-data")
      .then((res) => {
        // console.log("Data from Table: ", res.data);
        setPayouts(res.data);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => getDataFromCommissionTable(), []);
  useEffect(() => {
    setCheckboxValues({});
    getDataFromCommissionTable();
  }, [values]);
  
  // useEffect(() => {
  //   if (values?.from!=="")
  //   setCheckboxValues({});
  // }, [values]);

  useEffect(() => {
    setPayoutData(payouts);
  }, []);

  useEffect(() => {
    setPayoutData(payouts);
    setSidebarData(payouts);
  }, [payouts]);

  useEffect(() => {
    if (isSubmit) {
      axiosClient()
        .get(
          `users-commissions/formatted-data/?StartDate=${
            values?.from ? values?.from : ""
          }&EndDate=${values?.to ? values?.to : ""}`
        )
        .then((res) => {
          setPayouts(res?.data)
          setPayoutData(res?.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [isSubmit]);

  return (
    <>
      <div>
        <SearchSection
          isFilterOpen={isFilterOpen}
          setIsFfilterOpen={setIsFfilterOpen}
          setPayoutData={setPayoutData}
          pyoutData={pyoutData}
          setCheckboxValues={setCheckboxValues}
          selectedFilters={selectedFilters}
          setIsCheck={setIsCheck}
          checkboxValues={checkboxValues}
          isCheck={isCheck}
          data={payouts}
          setIsSubmit={setIsSubmit}
          setValues={setValues}
          values={values}
          refresh={getDataFromCommissionTable}
          handleApply={handleApply}
        />
        {loading ? (
          <div className="flex items-center justify-center my-10">
            <Spin fullscreen size="large" />
          </div>
        ) : (
          <div style={{marginTop:"24px"}}>
            <Table
              setIsViewOpen={setIsViewOpen}
              activeTab={activeTab}
              setIsEditOpen={setIsEditOpen}
              column={PAYOUTS_DATA_COLUMN}
              data={payouts}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedRowKeys={selectedRowKeys}
              setPayoutData={setPayoutData}
              pyoutData={pyoutData}
              setSelectedObject={setSelectedObject}
              selectedObject={selectedObject}
              setActiveTab={setActiveTab}
              setViewData={setViewData}
              refresh={getDataFromCommissionTable}
            />
          </div>
        )}

        {isViewOpen && (
          <Modal
            className="tableModal modalclass"
            width={1080}
            open={isViewOpen}
            onCancel={() => setIsViewOpen(false)}
            footer={null}
          >
            <div>
              <img
                src={Close}
                alt=""
                onClick={() => setIsViewOpen(false)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "20px",
                  top: "31px",
                }}
              />
              <PayoutTable data={selectedObject} />
            </div>
          </Modal>
        )}

        <SidebarFilter
          setIsViewOpen={setIsFfilterOpen}
          isViewOpen={isFilterOpen}
          setPayoutData={setPayoutData}
          pyoutData={pyoutData}
          activeTab={activeTab}
          payouts={payouts}
          setPayouts={setPayouts}
          setSelectedFFilters={setSelectedFFilters}
          setCheckboxValues={setCheckboxValues}
          sidebarData={sidebarData}
          checkboxValues={checkboxValues}
          setIsCheck={setIsCheck}
          refresh={getDataFromCommissionTable}
          handleApply={handleApply}
        />

        <EditModal
          setIsEditOpen={setIsEditOpen}
          isEditOpen={isEditOpen}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          pyoutData={pyoutData}
          handleEditAmount={handleEditAmount}
          selectedObject={selectedObject}
          isLoading={isLoad}
        />
      </div>
    </>
  );
};

export default Main;
